<template>
  <div class="container">
    <div class="w-100 content title text-center">
      <ul style="list-style-type: none;">
        <li>
          <span class="main-title"><span class="color-primary">State</span>MC</span>
        </li>
        <li>
          <span class="sub-title">Minecraft Roleplay & Minigames</span>
        </li>
        <li class="sub-btn">
          <v-btn size="x-large" class="rounded-pill" color="primary" @click="$router.push({ name: 'Join', hash: '#join' })" prepend-icon="mdi-controller-classic-outline" elevation="6">{{ $t('join.button') }}</v-btn>
        </li>
      </ul>
    </div>
    <v-carousel
        cycle
        class="carousel"
        :show-arrows="false"
        hide-delimiters
    >
      <v-carousel-item
          v-for="(img, i) in utils.getBannerImages()"
          :key="i"
      >
        <v-img :src="img" />
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script setup>

</script>

<style scoped>
.content {
  z-index: 100;
  position: absolute;
  top: 70px;
}

.carousel {
  max-height: 250px;
}

.title {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-shadow:
    5px 5px 5px black;
}

.main-title {
  font-size: 56px;
}

.sub-title {
  font-size: 32px;
}

.sub-btn {
  margin-top: 30px;
}

.v-window-x-transition-enter-active,
.v-window-x-transition-leave-active,
.v-window-x-reverse-transition-enter-active,
.v-window-x-reverse-transition-leave-active,
.v-window-y-transition-enter-active,
.v-window-y-transition-leave-active,
.v-window-y-reverse-transition-enter-active,
.v-window-y-reverse-transition-leave-active {
  transition: 1.5s cubic-bezier(0.25, 0.8, 0.5, 1) !important;
}

@media screen and (max-width: 1400px) {
  .carousel {
    max-height: 20vw;
  }
}

@media screen and (max-width: 1100px) {
  .main-title {
    font-size: 42px;
  }
  .sub-title {
    font-size: 24px;
  }
  .sub-btn {
    margin-top: 20px;
  }
}

@media screen and (max-width: 800px) {
  .main-title {
    font-size: 24px;
  }
  .sub-title {
    font-size: 18px;
  }
  .sub-btn {
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .main-title {
    display: none;
  }
  .content {
    left: 0;
    transform: translate(0, 0);
  }
  .sub-title {
    font-size: 16px;
  }
  .sub-btn {
    margin-top: 5px;
  }
}
</style>
