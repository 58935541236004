import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import auth from '@/router/auth';
import games from '@/router/games';
import admin from '@/router/admin';
import stats from '@/router/stats';
import wiki from '@/router/wiki';

const Chat = () => import('@/views/Chat');

const Home = () => import('@/views/Home');
const Join = () => import('@/views/Join');
const Stats = () => import('@/views/Stats');
const Account = () => import('@/views/Account');
const Rules = () => import('@/views/Rules');
const Downloads = () => import('@/views/Downloads');
const Changelog = () => import('@/views/Changelog');
const Wiki = () => import('@/views/Wiki');
const Imprint = () => import('@/views/Imprint');
const DataPolicy = () => import('@/views/DataPolicy');
const Terms = () => import('@/views/Terms');
const CustomMainMenu = () => import('@/views/CustomMainMenu');
const Livestream = () => import('@/views/Livestream');

const PlayerView = () => import('@/views/PlayerView');
const Shop = () => import('@/views/Shop');

const NotFound = () => import('@/views/404');

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      requiresNotLoggedIn: true,
    },
    children: auth,
  },
  {
    path: '/join',
    alias: ['/mitspielen', '/anleitung'],
    name: 'Join',
    component: Join,
  },
  {
    path: '/player/:id',
    name: 'Player',
    component: PlayerView,
  },
  {
    path: '/chats',
    name: 'Chats',
    component: () => import('@/views/AllChats'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/chat/:id',
    name: 'Chat',
    component: Chat,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/rules/:category?/:rule?',
    alias: [
      '/regeln/:category?/:rule?',
      '/regelwerk/:category?/:rule?',
      '/regles/:category?/:rule?',
    ],
    name: 'Rules',
    component: Rules,
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: Downloads,
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: Changelog,
  },
  {
    path: '/wiki',
    name: 'Wiki',
    component: Wiki,
    children: wiki,
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
  },
  {
    path: '/policy',
    name: 'DataPolicy',
    component: DataPolicy,
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
  },
  {
    path: '/games',
    name: 'Games',
    children: games,
  },
  {
    path: '/admin',
    name: 'Admin',
    children: admin,
    meta: {
      requiresTeamMember: true,
    },
  },
  {
    path: '/stats',
    redirect: '/stats/community',
    name: 'Stats',
    component: Stats,
    children: stats,
  },
  {
    path: '/custommainmenu',
    name: 'CustomMainMenu',
    component: CustomMainMenu,
  },
  {
    path: '/livestream',
    name: 'Livestream',
    component: Livestream,
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop,
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    }
  },
});

router.beforeEach((to, from, next) => {
  if (store.getters.isLoggedIn) {
    if (!store.getters.isTokenValid()) {
      store.commit('logout', false);
    }
  }
  if (to.matched.some(record => record.meta.requiresTeamMember)) {
    if (!store.getters.isTeamMember) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters.isLoggedIn) {
      store.commit('setTempPath', to.path);
      next({ name: 'AuthLogin' });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresNotLoggedIn)) {
    if (store.getters.isLoggedIn) {
      next({ name: 'Home' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
