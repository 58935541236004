export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rules"])},
  "list": [
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't be an idiot"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play RolePlay and respond to the RolePlay of your fellow players, always stay in character and never go OOC (Out of Character), but always stay IC (In Character) once you are on the server."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your life is the most important asset"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your life on the RolePlay server is the most valuable thing you can own on the whole server, don't just give it up and be valuable with it."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry requirements"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As soon as you enter the server, you will need to create your character, thinking of a first and last name and a date of birth. Please do not use a first and last name that already exists in the world, but be creative and think of your own. However, ambiguous or insulting names such as Fixi Hartmann or Rainer Zufall are forbidden. Make sure that the date of birth is realistic and fits to your RolePlay, just like the name."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Microphone / Headset"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You should have a working microphone/headset to be able to play reasonably on the server."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have the obligation to be in the designated 'ingame' channel as soon as you enter the RolePlay server. You are not allowed to communicate with people in RolePlay on other communication platforms like Teamspeak or Discord."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication platform"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating and joining communication platforms that offer the possibility of meta-gaming are prohibited. This includes, for example, so-called faction discords or Google spreadsheets. If an independent communication platform is needed outside of the RP, it must be approved by a moderator or administrator in the support. Exceptions are state factions that are firmly anchored in the game, as well as certain areas on the official StateMC Discord server."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trolling"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Constantly annoying other players on the server is forbidden. Example: Driving a car permanently past the same players and playing music."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soundboard im RolePlay"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is allowed to use a soundboard in the RolePlay as support for his RolePlay. Copyright protected music and sounds may not be played in the RolePlay. Example: as background noise during an activity, as a music bot in a disco."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masks"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is forbidden to recognize a person by their voice if this person is wearing a full marker.<br><br>Full masking:<br>➥ Balaclava<br>➥ Mask that covers the entire face<br>➥ Special unit helmets<br><br>No full masking:<br>➥ Bandana <br>➥ OP Masks<br>➥ Motorcycle Helmets<br>➥ Racing Helmet<br>➥ Pilot Helmets<br>➥ Sheriff Helmet"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Old friends"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On this server the 'old friends' rule applies, which means that you are allowed to enter this state with a person declared as an 'old friend'."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MultiChar"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Should you be able to create another character you must adhere to the following things:<br/>➥ The character may not use any information ingame that another character you have created has received.<br/>➥ The character may not have the same voice as your other characters.</li></ul>."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shot announcement"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If it comes to the point that an exchange of fire or an exchange of blows with a weapon (or an object that is misused as a weapon) is imminent, then an announcement must be made before this action. The opponent must be given a reaction time of at least 5 seconds before an attack with the weapon takes place."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDM"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stands for Random Deathmatch and means that you can't just shoot or kill players like animals without an attached RolePlay background."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VDM"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle Deathmatch (VDM) is the intentional running over of fellow players and is strictly forbidden."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meta Gaming"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you use information you get outside of the game in RolePlay, it is called metagaming and is strictly forbidden."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Power-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In RolePlay you should give everyone enough time to act, if you don't give your opponent enough time to react this is called 'Power RP' and is forbidden on the server. Example: You threaten someone with a gun, he doesn't raise his arms immediately and you just shoot him because he doesn't react immediately."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fail RP refers to actions that would be impossible to accomplish in real life or are simply unrealistic. Example: When you kill yourself extra to get full life again."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sex-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The reenactment of rape in RolePlay is forbidden. Likewise, the acting out of any 'sex-RP' is prohibited."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RP Escape"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is forbidden to actively flee from RolePlay situations, which means that you should not log out of active RolePlay situations or when unconscious."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RP-Tod"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only you decide when to let your character die and when not to, no one else decides about the death of your character. An RP death must be announced in support and approved by a moderator before it happens. Example: You are in a street gang, get a 'Blood out' by a head shot and become unconscious, decide if you want your character to die.<br/>(Remember the first rule 'Don't be an idiot')."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raids"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robbing other players is only allowed if 2 stars in the tab list are colored yellow, otherwise there will be a penalty. Please note that robberies are only allowed outside the defined 'safety zones'."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depository"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If cars are extorted from the depository with the help of robberies (including hostage-taking) against the police, this is also only allowed if 2 stars in the tab list are colored yellow."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostage situation"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hostage taking should only be done when at least 3 stars in the tab list are colored yellow, otherwise you will face a penalty.<br/>Please remember to only take hostages outside of the defined 'safe zones'."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouping reasons"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to start a gang, you can just do it in RolePlay and you don't have to register anything beforehand, because in our opinion gangs should be created out of RolePlay and not somehow by a written application outside of RolePlay."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouping members"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is currently no membership limit in groupings, make sure you don't accept everyone into your grouping, but choose your members wisely."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouping (hostage taking + robberies)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you should act with a group of civilians (e.g. gang, mafia, etc.) against the police, in terms of hostage taking or robbery, a maximum of 10 participants may be actively involved in this situation. Active means that at the beginning a maximum of 10 people may be involved in the situation. During the situation, no other people are allowed to participate in this situation, no matter if the maximum number of 10 participants is reached or not."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grouping marking"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gang should be uniformly recognizable, either with the same color or the same garment. However, this must only be obviously displayed during gang wars and not when you are just out and about or starting actions."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles realism"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Since we are still in Minecraft and the cars are good but not perfect, you should make sure to drive with the cars as realistically as possible."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emergency Paramedic"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are not resuscitated while unconscious, you will be patched up by a paramedic (respawn) and wake up in the hospital. Once you wake up in the hospital, you can no longer remember the last 30 minutes and let your injuries on occasion by a real doctor (player), examine and treat.<br/><br/>This rule counts, only if you are teleported from the system to the hospital and not if you are reanimated by a player on the spot!"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconsciousness (resuscitation)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you become unconscious, you may only revive yourself if no player is nearby. This rule does not apply if other players clearly indicate that no medic will appear (e.g. no medic online) or if the surrounding players ignore you. <br/>Example: The shooting is still active and no one cares about you or makes signs to contact a medic."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play out unconsciousness"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you pass out and wake up from the system in the hospital, you have the duty to continue your RolePlay, even if you died due to a bug. Just come to the support after completing your RolePlay, so that the matter can be clarified."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safety zones"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below you will see a list of security zones where people are not allowed to be robbed or taken hostage.<br/>Security Zones:<br/>➥ ATM<br/>➥ Bank<br/>➥ Airport."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escape to safety zones"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleeing from a RolePlay situation into a safety zone is strictly forbidden and leads to the temporary suspension of the rule 'Zones' for this situation.<br/>Example: You flee from pursuers into a safety zone who want to rob you."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sending real life pictures via SMS is prohibited. Only Minecraft screenshots which do not contain parts of the Hud or GUIS may be sent, as well as screenshots of text documents. Spamming people in roleplay is strictly forbidden!"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extreme situations"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any kind of racism, rape or excessive torture within the roleplay is prohibited. This includes acting out extreme situations, such as \"racial hate crimes\", \"rape\", \"terrorism\" or \"dismemberment, or cutting off body parts\"."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum age"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We expect every roleplay player to be at least 14 years old and to behave appropriately."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Covid-RP"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing Corona is not desired on our project."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item oriented robbing"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robbing players is forbidden if the action has a pure item-oriented background. This case occurs when there is no RolePlay background or too many items were stolen from this player."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weapons rule"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using a weapon as a means of pressure/power is a legitimate use, but this should only be done after previous RP contexts and should not be considered as the first act. Prior contexts include trying to clarify the situation with words. This applies to every player, regardless of faction."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFK-Farming"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is forbidden to be AFK on the server with profit intentions. For example, waiting for paydays or processing raw materials. You must always be available during these activities."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OOC-Talk"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any OOC talk is strictly prohibited. This includes simply replacing OOC terms, such as \"key\" with \"muscle\". Creative paraphrases should be used instead. Statements such as \"I'm short in the head/thoughts\" are also undesirable. Instead, alternative phrases such as \"I'm quickly looking at my phone to check my messages\" should be used."])}
        }
      ]
    },
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspeak und Discord"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appearance"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any offensive, racist, sexist and extremist statement (names, channel names, avatars, messages, ...) is prohibited."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Social tone"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insulting other people is prohibited. Threatening other people with any activity is not allowed. Care should be taken not to shout in any freely accessible conversation space. There should be no disrespectful talk with any other person because of his/her expression."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noise"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distorting your own voice is prohibited. Making or imitating inappropriate sounds and singing is prohibited. Playing any sounds or music is not allowed. Care should be taken not to make any noise that is too loud or noisy."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Call recording and streaming"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recording conversations as well as streaming without permission of all present is prohibited. (Exception is the '\" ingame' channel on Teamspeak)."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identity Theft"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falsifying others in any way is prohibited."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support utilization"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taking advantage of our support (e.g. using it without needing it or asking unnecessary questions) is prohibited."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scripts"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using scripts to create channels, bots and other is prohibited."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugusing"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploiting or spreading a bug is not allowed."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publishing or sharing other people's personal information without their permission is strictly prohibited. Exceptions are made for police matters, but these must be requested from the owners of this TeamSpeak server."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploit rank"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If one has a server group that is higher than the default server group, he/she is strictly forbidden to use his/her rights for non-specified uses (e.g. kicking or banning other people for no reason)."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attacks"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any attacks on other people and our servers (in the form of DDOS, PokeBot, hacking, ...) is prohibited."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ban bypass"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bypassing a ban from our servers using VPN, for example, is prohibited."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TS³ Name"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is forbidden to choose a TS³ name that contains OOC/IC mixing (e.g. [Greens OG], [Police Chief], Max Mustermann, etc.). Also forbidden are unclear names like ???? and XXXX."])}
        }
      ]
    },
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client modifications (mods)"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any modifications of the client, which give the player an advantage over other players, are generally prohibited. Unauthorized modifications are for example:<br/>➥ Xray<br/>➥ KillAura<br/>➥ Minimap"])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugs"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any form of bugs and their exploitation is strictly prohibited. If you discover a bug, you should report it to the team."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evasion of penalties"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avoiding penalties by having a 2nd account, for example, is prohibited."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Responsibility"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everyone is responsible for their own account. Therefore, a penalty will be imposed even if a second person other than the owner commits a rule violation."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chat behavior"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respectful behavior should be observed in the chat. Spamming of messages is to be refrained from."])}
        }
      ]
    },
    {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
      "rules": [
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RDM"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is forbidden to kill other people without being sure that it is a player from the opposing team."])}
        },
        {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ghosting"])},
          "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No info about the game in progress may be shared via outside communication methods."])}
        }
      ]
    }
  ]
}