export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])},
  "list": [
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.04.2024"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateMC Mod geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DynamX Pack geupdated"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Sternesystem wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden neue Fahrzeuge eingeführt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit etwas Chance bekommt man bei CargoLink nun den Ford Transit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Nummernsystem wurde überarbeitet inkl. neuer Leitstellennummern"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Waffenschule ist eine Firma"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helikopter haben Einzug gehalten für Firmen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Gesetzbuch hat seit dem letzten Changelog Gesetzesänderungen erhalten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere kleinere Fehler behoben, die auf Discord gemeldet wurden"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablet"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Tablet hat viele verschiedene Changes seit dem letzten Changelog erhalten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Navigaionsmenü lädt nach einem Login nun wieder korrekt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs laden schneller"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Tablet beinhaltet nun Terminanfragen, diese können sowohl von Firmen, als auch von Spielererstellten Unternehmen im Rathaus beantragt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bug mit den Rechten für das Sandhill PD verwendet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der linken unteren Ecke liegt ein neues Menü, mit vielen nützlichen Funktionen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann nun der Pfad der aktuellen Seite über das InfoMenü kopiert werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopierte Pfade können nun über einen entsprechenden Knopf in bspw. die eigenen Seiten als Verlinkung eingefügt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Home-Seite ist nun über einen Doppelklick des Info-Menüs erreichbar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seiten können zudem über das Info-Menü an der Home-Seite angepinnt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Flugbuch schlägt Piloten vor und Start- und Landeorte können bearbeitet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Rechtschreibfehler wurden behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27.01.2024"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge können nun wieder Schaden nehmen und müssen repariert werden bei einem Unfall"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Fahrzeug Interkations Menü steht nun bei blockierten Felder den Grund"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Fahrzeuge haben eine funktionierende Kilometeranzeige bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man hört Fahrer in Go-Karts oder Rollatoren nicht mehr gedämpft"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es scheint als hätten ausgewählte Unternehmen nun einen Helikopter"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moritz Suhre ist in Central-Bay angekommen und plant ein profitables Geschäft"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Krankenhaus und die Feuerwehrwache teilen nicht mehr einen Dispatchempfang"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit mehreren Personen an einer Drogenpflanze farmen geht nicht mehr"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Bankautomaten sprengen werden die Gegenstände erst entnommen wenn diese durchgeführt wird"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Fahrzeuge: Mini, Shelby500 und Amvulcan haben keine vertauschte Blinker mehr"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regina Piccio hat sich nun einen besseren Ort in der Mitarbeiterküche ausgesucht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Türen am Bus kann man nun erstmal testweise öffnen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizei Westen haben nun ein schöneres Design"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediziner haben nun eine größere Auswahl an Medikamente"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitskleidung für Justiz hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nun ein Taxipullover im Taxishop verfügbar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Funkgerät Textur wurde mit einer neuen schöneren ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Kleidungen wurden hinzugefügt: Techfleeces, Westen, Poloshirts und Obdachslosenkleidung"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bärte, Brillen und Haare wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Kleidungen wurden überarbeitet: Taxi, Arztkittel und MD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es sind neue Kleidungen für den Designer Computer rausgekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreisebeamten haben neue Oberteile bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebeleinstellungen wurden angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Mülleimer kann man neue Funde erwischen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere kleinere Fehler behoben, die auf Discord gemeldet wurden"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schnee ist wieder verschmolzen und die Weihnachtsdeko wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toiletten am Schießkeller Baufehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feuerwehrauto passt nun auch im Feuerwehrquartier in Sandhill"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht durchgezogener Straßen Markierung am damaligen Parkplatz behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige Baufehler wurden behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.10.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Baufehler wurden berichtigt"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagringe haben nun Anzeigebild im Waffenladen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigebild von der Horrormaske (Knopfaugen) wird nun angezeigt\n"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwahrstelle hat nun wieder Computer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspirin in den Medicshop hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigationspunkt von dem Jagdshop wurde richtig platziert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigationspunkt der Verwahrstelle in Central-Bay ist nun richtig platziert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der ATM bei der Gokartstrecke hat nun ein Navigationspunkt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im FBI-Shop wurde die normale FBI-Jacke ohne Weste hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Dispatch beim Dönerladenraub hat nun ein Anzeigenamen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CrispyHero hat nun Hähnchen im Sortiment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rangsortierung im Computer ist nun wieder die alte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Parkautomat am Autohaus hat nun einen Namen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lackiergarage im Mechaniker kann nun wieder benutzt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Maps: Among Us, Casino"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.10.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Arbeitsamt wurde umgebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine U-Bahn Station wurde errichtet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Dönerladen wurde umgebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Autohaus in der Innenstadt wurde neugebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Burgerladen wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ItalBao hat einen neuen Anstich erhalten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein neuer Hafen wurde errichtet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Verwahrhof wurde komprimiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Mechaniker wurde umgebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Boxring wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Central Bay Polizei-Rolltor ist eine Wand weiter gewandert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schlüsseldienst wurde neugebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Flughafen wurde modernisiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Autohaus am Mechaniker hat nun einen neuen Platz samt Umbau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere kleinere Veränderungen an Gebäuden der Innenstadt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zoll nach Sandhill wurde erweitert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinter dem Zoll wurde eine Schlucht gegraben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandhill und die Jagdinsel sind nun per Land verbunden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Brücke zwischen Jagdinsel und Zentralbank-Insel ist eingestürtzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue GoKart-Bahn wurde auf Sandhill errichtet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Jagdshop ist umgezogen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der Jagdinsel wurden neue Gebäude errichtet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden neue Fruchtbäume gepflanzt"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der U-Bahn haben neue Läden eröffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An der GoKart-Bahn hat ein neuer Kiosk eröffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CB-PD, FBI, SH-PD, MD, FD, Mechaniker haben neue Gegenstände in ihren Shops"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kleiderladen hat sein Sortiment erweitert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Polizei hat nun Schusswesten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Polizeikleidung wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige MD Kleidungen wurden überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das MD hat ein neues Sortiment an Medikamenten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das MD & FD haben neue Fahrzeuge erhalten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mechanikerkleidung wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fahrschule hat ein neues Fahrzeug"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt neue Früchte zum ernten (Pfirsiche, etc)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Gegenstände wurden durch schönere Model-Objekte ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler um PD Wägen behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post Route gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler in der Dispatchversendung behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.07.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Map: Subway"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleine Mapanpassungen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Spielerspawns wurden neuplatziert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Waffenspawns wurden neuplatziert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handschellen Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUD Fehler nach Wiederbelebung behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.07.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnelleres Laden beim Auswählen eines Charakters"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Abschleppsystem für Polizisten mit Grundangabe und Ingame Einsicht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laptop im Abschlepphof zeigt welche Autos abgeschleppt wurden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle UI's wurden neuprogrammiert und können in Zukunft besser erweitert und angepasst werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Menu für die Garagen hat eine Suche für Kennzeichen bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Charaktererstellung so wie der Friseur haben nun eine 3D-Vorschau des Skins"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Pfeil an welcher Position man sich befindet wird in der Maps App nun immer aktualisiert auch wenn die App geöffnet ist"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Spiel hat ein komplett neues Mainmenu bekommen welches unter anderem den Changelog anzeigt und auch Einzelspieler zulässt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autohaus hat ein neues GUI für die Fahrzeuge"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Fahrzeuge haben neue Vorschaubilder bekommen im Autohaus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden etliche neue Tattoo's hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Rollator Fahrzeug wurde eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Scania Öltanker Fahrzeug wurde zum Modpack hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Performance der Skingenerierung ist gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnellerer Serverwechsel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slider in den GUI's funktionieren jetzt einwandfrei"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CustomMainMenu wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.07.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OneShot-Revolver ist erst 40 Sekunden nach dem Spielstart verfügbar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detective Pass wurde auf 200 Coins runtergesetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coins werden nicht mehr abgezogen für Pässe die nicht erfolgreich eingelöst wurden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.06.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feuerwehr Tor gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mülleimer im East-Cliff PD hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus Loch in der Wand gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlender Computer im Krankenhaus hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwahrhof lässt sich nicht mehr vom falschen PD öffnen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besseres Performance-Handling für den Server"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzsperren können wieder verteilt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound für das Schliessen von Türen hinzugefügt"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 neue Maps hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die alten Maps wurden erstmal entfernt"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapfehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gitter beim Zoll ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Säule im ItalBao entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schilder entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grünes Haus Central-Bay fehlende Säule hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Wand Kreuzgasse 4 wieder hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingang Apotheke Büro gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Immobilien eingespeichert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilienbilder laden nun viel schneller"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann den Waffenschein wieder zeigen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Mediziner Computer eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlender Parkautomat hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mediziner Schränke gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der Karte werden nur noch öffentliche Parkautomaten angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigationspunkte in der Kartenapp behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigationspunkt für den Kleidungsladen in Sandhill hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maps-App hat wieder alle Kategorien zum Unterteilen der Punkte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die PD's haben Polizeicomputer in der Zollstation bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Führerscheine können wieder ausgestellt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix: Türen haben wieder Sound beim auf- und zu-schliessen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleiche Änderungen wie im Roleplay"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securitycraft Mod wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Effekte nach dem Konsumieren von Drogen brechen nicht mehr vorzeitig ab"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tasermodelle wurden ersetzt und man bekommt nun Langsamkeit & fällt hin durch einen Taserschuss"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die standard TileEntity Renderreichweite wurde halbiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die TileEntity Renderreichweite kann nun unter Modoptionen angepasst werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sprachreichweite kann jetzt in Millisekunden angepasst werden um kürzere Zeiten als 1 Sekunde darzustellen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texturenbug mit blauen Schuhen behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse neue Items für das RP hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graue Sturmmaske Fehler mit Augen behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnung beim Betreten des Spieles wenn Optifine nicht installiert ist"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Map wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Krankenhaus wurde komplett neugebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxiunternehmen wurde komplett neugebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiosk & Friseur komplex wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierhandlung wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Kiosk, Growshop, Tierhandlung und Tattoostudio wurden gebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apotheke ist dazu gekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büsche und Seerosen sind nun auf der ganzen Map durchlässig"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Bilder auf der Map wurden durch weniger Rechenintensive ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Insel (Sandhill) ist dazugekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Mapteile wurden performanter gebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele einzelne LittleTiles Konstrukte wurden durch DynamX Blöcke ersetzt da diese performanter sind"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreinerei wurde umgebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse weitere Gebäude angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapfsäulen können nun auch beim oberen Block benutzt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Einreise kann man nun aussuchen ob man in Sandhill oder in Central-Bay landen möchte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tore gehen nun langsam auf und zu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Shop: Apotheke"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue Droge ist dazugekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle alten Taser wurden durch die neuen automatisch ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach der Bewusstlosigkeit spawnt man im nächstgelegenen Krankenhaus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Leitstelle wurde vom Sandhill PD getrennt und je nach dem in welchem Bereich man sich befindet, wird die nähere Leistelle angerufen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte eine PD Leitstelle nicht besetzt sein, wird automatisch die andere angerufen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Polizei in Sandhill & CentralBay haben nun getrennte Nummern"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Farmbare Pflanzen wurden eingefügt: Roggen, Rhabarber, Chili, Tomate"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man eine Axt anfordert und kein Platz hat wird man nicht mehr in den Cooldown kommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man wird selber nicht mehr in der Schlüsselverwaltung angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adressen mit United-Islands Deklarierung gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler behoben, dass man keine Schlüssel weitergeben kann wenn jemand in Spectator in der Nähe ist"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefesselt kann man sein Handy nicht mehr öffnen oder benutzen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man sieht nun vor dem Tanken schon eine Nachricht, falls man zu wenig Geld hat"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler dass sich jeder in Dienst eintragen kann wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatches werden nicht mehr auf dem falschen Charakter angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Cooldown für die Dispatches ist nun Charakter und nicht mehr Spieler gebunden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzsperren im Polizeicomputer lassen sich wieder löschen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kommt nun immer eine Nachricht & ein Error Sound wenn eine Nummer nicht existiert beim Anrufen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man tote Leute anruft erhält man nun auch die Information, dass diese Nummer nicht existent ist"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rentenzahlungen werden nicht mehr als Immobilienabrechnung angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler behoben: Bus kann nicht getankt werden (Minusbeträge)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tattoo's können nun von mehr Charakteren gestochen werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Auto um 15 Uhr eingeparkt wird ist nun immer der richtige Benzinstand und Schliesszustand gespeichert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM's können nur noch aus der Nähe benutzt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM's haben eine Gaserkennung bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um einen ATM zu sprengen müssen nun 2 Sterne aktiv sein"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Propangas wird beim ATM sprengen direkt entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propangas kann man jetzt auch auf andere Weise im RP bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele neue Kleidungen hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item-Übersetzungen gefixt: Rose, Angel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte in der Handy-App wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Arbeitsamt werden nun alle Jobs angezeigt und wie man sie bekommen kann"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilienmenu hat für alle Wohnungen jeweils ein Bild bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun wieder ertrinken"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Ertrinken-Animation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleiche Änderungen wie im Roleplay"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DynamX wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brems-und Rückfahrtlichter sind gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichen sind gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge sollen nicht mehr durch den Boden buggen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weibliche Skins haben haut auf den Schultern Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Erstellung eines Charakters gibt es keine random Skins mehr"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die ausgewählte Haarfarbe wird nun beibehalten in der Charakterstellung"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Frisuren wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele neue Bärte wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über 100 Tattoos wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann sich in der Charaktererstellung nun Schminke auswählen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Gesichtsextras wie zB. Augenringe wurden von den Augen getrennt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Gesichtsextras hinzugefügt wie zB. Narben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Friseur trägt man nun immer seine Kleidung in der Vorschau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snackautomat hat neues Sortiment bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Militär kann nun Eisentüren ohne Schlüsselkarte öffnen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler wurden behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleiche Änderungen wie im Roleplay"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinweis zum Zusammenhang mit Mojang hinzugefügt"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.04.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Neue Challenges"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über 10 neue Waffen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazine sind im Waffenladen jetzt immer an 2. Stelle"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.04.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizeiauto Skins verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Autos hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item Übersetzungen gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapfsäulenübersetzung gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler wurden behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler wurden behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.04.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memory Leak fix NPCs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizeiauto Skins verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Kakteen für Sandhill hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fehler, dass das Handy nicht aktualisiert wird beim Charakter erstellen wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigationspunkt für Logistikcenter wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferanten Job wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drogentest in Polizeishop"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkoholmesgerät Polizeishop"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation überarbeitet (visuelle punkte)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postbotenjob hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feuerwehr wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Trinken von Wasser gibt es jetzt mehr Stufen als nur voll oder leer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Fahrzeuge wurden um 30% Beschleunigung minimiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen Computer Shop sind nun in Kategorien geordnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kleidungsladen hat sein Sortiment erweitert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Snackautomaten wurden leckere neue Snacks hinzugefügt"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gleiche Änderungen wie für Roleplay"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.03.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shelby500 Beifahrersitz gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hupe für Mini & Shelby gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventuell den Bug mit dem fliegenden Mini gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialforce Kleidung wurde mit ein wenig Schutz ausgetattet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Militär hat wieder eine Kleidung bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zigarren Textur wurde gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kakteen für Sandhill wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrschule hat ein Hemd bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physikperformance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wein hilft nun auch gegen Durst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man verliert keine Ausdauer mehr beim Autofahren"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zigarettenpack Fehler wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Waffenladen können nun mehrere Magazine und Waffen auf einmal gekauft werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personenbeförderungsschein wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man reanimiert wird steht man nicht mehr auf, sondern sitzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus Leitstelle wird in Kontakten wieder als verfügbar angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrschulhemd im Fahrschulshop erhältlich"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.02.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Mute-Icon über dem Kopf hat nun eine bessere Auflösung"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Mute-Icon wird beim sneaken nicht mehr angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DynamX wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Fahrzeugmodelle wurden erneuert: Mini, Aston Martin, Shelby 500 und der 6x6"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seerosen und diverse Büsche durch die man durchgehen kann wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Polizei hat für jeden Rang eine neue Uniform bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei dem Kiosk gibt es nun Zigarren zu kaufen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man Bewusstlos ist bekommt man keine Anrufe mehr"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue RP Items hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strickjacken sind im Kleidungsladen dazugekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi Language Support wurde eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es können nicht mehr mehr Spieler beitreten, als die größte Map zulässt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tabliste ist nun in TTT deaktiviert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man sieht nun den Namen über den Kopf von jedem Spieler in Radius von 5 Blöcken"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getötete Traitor werden nach der Durchsuchung mit dem richtigen Skin angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze Gun gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spectator sehen nun alle Skins richtig"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.02.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeder Kill wird in einer Nachricht für alle angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausdauer wurde komplett entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durst und Hunger wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Respawnzeit wurde auf 3 Sekunden verkürzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann jetzt Leben regenerieren in dem man isst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeder neue Spieler spawnt im Passivmodus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen aus offenen Autos können nicht mehr kaputt gehen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man bewusstlos wird, wird die Durchsuchung abgebrochen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nun möglich beim Mechaniker sein Fahrzeug zu färben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.01.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man respawnt an einem Ort in der Nähe des Todesortes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Item in der Hand fällt bei einem Tod nicht mehr auf den Boden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passivmodus wird nicht mehr aktiviert nach dem Respawnen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Levelsystem wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der kompletten Map können nun random Fahrzeuge spawnen, welche mit Glück nicht abgeschlossen sind"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In den nicht abgeschlossenen Fahrzeugen kann man nützliche Items finden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die stündliche Challenge startet jetzt bereits ab 8 Spielern"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sterne sind nun abhängig davon, wie viele Spieler im Aktivmodus online sind"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tankstellen können nun ausgeraubt werden und Rivalen können den Raub verhindern"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.12.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Verkauf von Fischen ist nun möglich"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fehler beim Einsammeln von Gegenständen bei vollen Inventaren wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das HUD wurde vollständig überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Tankstellen wird jetzt angezeigt, ob sie bereits ausgeraubt wurden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Große Waffenschein wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Jagdwaffe ist nun erhältlich"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der Jagdinsel spawnen jetzt Tiere"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Drops von Tieren der Jagdinsel können verkauft werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winterjacken sind im Kleidungsladen erhältlich"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sprachreichweite wird jetzt visuell angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Raub abgebrochen wurde, kann er wieder gestartet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Shop-Raub wird nur noch mit Rechtsklick gestartet und kann somit nicht ausversehen ausgelöst werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Lebensmittel können nun egal ob man hunger hat oder nicht konsumiert werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arztkittel für Mediziner wurde dem Shop hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realistischere Bewegungen für NPC's"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC's schauen nun den Spielern in die Augen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC's können sich nun bewegen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei einem Raub nehmen NPC's nun ihre Hände hoch"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skinbug bei NPC's behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC's verschwinden nicht mehr"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun mit Waffen auf NPC's zielen ohne die Shops zu öffnen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatches haben einen neuen passenderen Sound bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler wurden behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankündigung von Herausforderungen werden 5 Minuten vor der Challenge angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Level-HUD wurde eingeführt um einen optisch schöneren Überblick zu geben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Level-Menu indem man sehen kann wann man welche Dinge freischaltet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues HUD für Herausforderungen, das die aktuelle Herausforderung mit einer Rangliste visualisiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passivmodus wird nun auch im HUD angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann Freeroam nun auch ohne Voice spielen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features aus dem RP-Changelog wurden auch in Freeroam implementiert"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Globales Level wird angezeigt"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewusstlosenscreen wird mit Überblende angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F10 Browser funktioniert besser bspw. die Suchleiste"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglichkeit hinzugefügt, Text zu kopieren"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann mit der Maus zwischen Zeichen navigieren"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschiedene Mauszeiger bspw. beim hovern von klickbaren Elementen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehrere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack ist wieder Linux kompatibel"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levelbar für GunGame während Spielen hinzugefügt"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levelbar für TTT während Spielen hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man bekommt nun weniger Schaden durch andere Spieler"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.11.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Bugfixes vom letzten Update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maps App wurde released"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele neue Dinge werden auf der Karte angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt in Handy Chats nun ein Emoji Menu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Map wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde kälter in United-Islands"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Immobilien und viele Maperweiterungen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tieren kann man keine Nametags mehr geben (Bug fix)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06.11.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seiten wie bspw. die Vote-Seite werden jetzt direkt Ingame geöffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fahrschule kann nun Rechnungen ausstellen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitslose und Rentner bekommen wieder ihr Arbeitslosengeld oder Rentengeld"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fahrschule kann nun Führerscheine ausstellen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Führerscheine beim NPC wurden teurer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leistellenstatus wird in den Kontakten angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Language Support wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Nachrichten überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn eine Rechnung bezahlt oder abgelehnt wird, beendet sich die Animation automatisch"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentralbank Garage hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Auto überschrieben wird, wird der neue Besitzer als Schlüssel entfernt, wenn er noch welche hatte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankautomaten können nicht mehr von zwei Personen gleichzeitig benutzt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge können nun beim Schrotthändler verkauft werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankäufer und Tauscher NPCs haben nun ein neues GUI"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mitarbeiterin bei der Bank hat nun ein neues schöneres GUI bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele Kleidungsstücke wurden neu texturiert und sehen nun qualitativer aus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erdbeeren und Kopfsalat können nun im Supermarkt verkauft werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endlich gibt es im Shop weisse Schuhe zu kaufen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sitze vom Shelby GT 500 wurden behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Firmenfahrzeuge haben alle Chefs Schlüssel und können Schlüssel vergeben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.09.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lobby Map wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeder Spieler wählt nun beim ersten Betreten seine Sprache aus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Jump wurde optimiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP Bereich ist nun besser geschützt vor Spieler"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schere, Stein, Papier entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats Verlinkung NPC hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoreboard entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverify Command funktioniert wieder einwandfrei"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-Language Support hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lobby supportet nun die Sprachen: Deutsch, Englisch, Französisch & Italienisch. Das System wird in der Zukunft auf GunGame, Roleplay usw. ausgeweitet."])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.09.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fahrzeug Performance verschlechtert sich nicht mehr nach einer Weile"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leute in Fahrzeugen hört man nun gedämpft"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Royal-Ocean-Casino wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sirenen und Hupen hört man nun um ein vielfaches weiter"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Fahrzeug Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse Waffen Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrschule wurde als Firma hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefängnis Item kann in die Haft mitgenommen werden - Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden viele To Go Getränke in sämtlichen Shops hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankschließfächer können nun gemietet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News-Fahrzeuge werden nun auf dem Mechanikerhof eingeparkt und Polizei sowie Medic-Fahrzeuge werden direkt bei ihnen geparkt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man teleportiert sich nicht mehr ein Stockwerk höher wenn man sich sitzend ausloggt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahltag Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client-Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.08.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Waffenmod ist nun mit Linux kompatibel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liegen Sync verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hungerbalken wird nun auch beim Sitzen geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05.08.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sprachreichweite funktioniert nun wieder einwandfrei"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tankstellenraub funktioniert nun auch mit den neuen Waffen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschwindigkeit im Liegen wurde angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friseurvorschau funktioniert wieder"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventuell Liegen Sync gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Auto kann man sich nicht mehr hinlegen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.08.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzugsladen Sortiment wurde erweitert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzugsladen Schreibfehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Skinsystem wurde optimiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffenmod wurde ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türen lassen sich mit Waffe in der Hand öffnen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu ist die Munitionsanzeige im HUD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazine stacken sich nun immer richtig"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nun möglich in Fahrzeuge reinzuschiessen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Waffenladen haben nun alle Artikel ein Bild"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Polizei-Waffen wurden ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liegen ist nun sync auch wenn jemand später dazu kommt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kofferraum Inventare gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelte Nachrichten links oben werden gestackt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupe-Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Bewusstlos werden ist nun viel flüssiger"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ausparken von Fahrzeugen im Abschlepphof funktioniert nur noch mit einem Polizisten daneben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sneakend kann man sein Handy nicht mehr öffnen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interatkionsmenus sind nun auch mit Handy in der Hand benutzbar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fragen im Quiz wurden angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Quiz werden nun bei jedem Spieler individuell 20 zufällige Fragen gestellt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man noch nicht durch die Einreise ist bekommt man nun die Willkommensnachricht bei jedem Join"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man startet in der News-App ganz unten bei der neuesten Nachricht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client-Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.07.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsselvergabe bei Immobilien funktioniert wieder"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleidung wird nun enganliegend gerendert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lange Haare werden nun über der Kleidung angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medics haben OP-Kleidung bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleidungsladen Sortiment erweitert (Hoodies / Stirnbänder / uvm.) [Danke an Virotex <3]"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brillenladen am Pier hat eröffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radarpistole Anzeige-Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linux Support für unser Modpack"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibfehler bei Rechnungsnachricht (Bonus) behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibfehler bei Charerstellung behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.06.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Logging-System"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn der Führer- oder Waffenschein entzogen wird, wird das Inventar aktualisiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI kann jetzt besser ohne Modpack Update aktualisiert werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance etwas verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06.06.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lebens & Hungersanzeige Anzeigefehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakterwechsel lädt nun schneller"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Stadt wird jetzt durch einige Tiere belebt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Polizei hat nun die Möglichkeit, Führerschein- und Waffenschein Sperren zu verteilen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizeicomputer hat nun eine Häftlings- und Lizenzsperren Übersicht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Anzugsladen hat sein Sortiment erweitert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupe-Bug behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Map wurde um mehrere neue Gebiete erweitert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Handy kann man nun Emojis benutzen. Eine Liste findet ihr"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Regel wurde angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.05.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilien-Abrechnung überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls man kein Geld für eine Immobilie hat, wird nur noch diese gekündigt und nicht jede Immobilie"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden Steuern für mehr als 2 Immobilien eingeführt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftzeit Anzeige-Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftzeit wird nach der Inhaftierung instant angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banktransporter für das Event hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.05.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Ware in den Tankstellen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse RP Items wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Sprengen von Geldautomaten ist nun möglich"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser UI läuft nun extern - Dadurch können wir flexibler Bugs fixen und Anpassungen machen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomaten Sound verringert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klingeltöne im Handy sind wieder richtig auswählbar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch App wurde auf die Startseite verschoben und Einstellungen auf die andere Seite"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unnötige Firmen in der Dispatch-App entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.05.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomat zahlt nicht genügend aus - Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shops geben nicht alle Items - Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SeaSide Navigationspunkt gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkkanal wird verlassen bei einem Charwechsel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leitstellen-Telefon kann nicht mehr als normales Telefon missbraucht werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restartzeit wurde verkürzt (Server ist nach 15:00 schneller wieder da)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.04.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound beim schliessen von Türen hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatchfunktion bei NPC's entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20.04.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde aktualisiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justiz, News & FBI haben eine Leitstelle bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justiz, News & FBI wurden als standard Kontakte hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Job Positionen hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leitstellen NPC GUIs wurden ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klingeltöne werden nun abgespielt wenn man sie auswählt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen können nun mit Shader verwendet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.04.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde aktualisiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Animation nach dem Ausweis zeigen wird automatisch beendet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Hintergründe für das Handy hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy Klingelton ist nun einstellbar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine App zum Suchen seines Fahrzeuges wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM Menu wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFK-Button im Charakter-Menu wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support-Benachrichtung überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage auf dem Kirchenparkplatz wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientperformance etwas verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.03.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Charakter sollte nun um einiges schneller laden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Restarts um 15:00 Uhr"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge werden jeden Tag um 15:00 Uhr eingeparkt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler mit unsichtbaren Bewusstlosen nach dem joinen behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientlaggs behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy Bugs gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitze in Autos hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCEF wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche im Handy wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backspace Fehler in dem UI behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch App wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewusstlosen-Screen wurde ersetzt und verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaktions-Menu wurde ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibfehler bei der Durchsuchung behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei kostenlosen Dingen in Shops wird der Warenkorb nicht mehr angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnung ausstellen überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haftantritt im Gefängnis überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die maximalen Hafteinheiten wurden auf 6 gesetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatches haben nun einen Cooldown von 60 Sekunden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation für das Vorzeigen von Lizenzen und Rechnungen ausstellen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht nach dem Einreisen würde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kakautschi löscht jetzt auch den Durst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Navigationspunkte wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance etwas verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spitzhacken / Axt NPC Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehälter wurden um 10% erhöht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienstfahrzeuge werden beim Tanken mit Kartenzahlung nun vom Geschäftskonto abgerechnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Command entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parkautomat bei der Kirche wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft-GUI im Arbeitsamt ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreibfehler im Quiz korrigiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Verschwinden von Autos wenn man sich währendem Fahren ausloggt ist gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kokainpreis wurde angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das FBI zählt nun auch zu den 'Sternen'"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach einem Server-Neustart muss sich nicht mehr neu auf Teamspeak verbunden werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görgi hat seinen Supermarkt in East-Cliff eröffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tankstelle in East-Cliff kann nun ausgeraubt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Supermärkte & der Kiosk können nun ausgeraubt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrufe werden nicht mehr einfach so beendet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Navigation wurde etwas verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handschellen-Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichenabfrage-Fehler beim Polizeicomputer behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.01.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kokain respawnt nicht Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Teamspeak-Bot Commands funktionieren nun viel schneller"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man wird nun viel schneller in den Ingame-Warteraum verschoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance wurde etwas verbessert"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16.01.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Cooldown beim Doktor im Krankenhaus ist nun Charakterabhängig und nicht mehr vom Spieler"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drogen können nun wieder bei mehr als 11 Cops gefarmt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeug HUD Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neben dem Fahrzeug stehen obwohl man eingestiegen ist Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichen für den News-Wagen gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hupen für fehlende Fahrzeuge hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kofferraum für div. Fahrzeuge gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anzahl an Nachrichten die im Handy angezeigt werden, wurden minimiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance wurde etwas verbessert"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.01.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation wird nicht mehr abgebrochen beim Zahltag"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Map wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt viele neue Immobilien zu vermieten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Garagensystem wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Autoladen wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle bestehenden Fahrzeuge wurden entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele neue Auto Modelle wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fahrzeuge haben nun eine viel angenehmere Physik"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge werden nun einfach mit WASD gelenkt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klick-Bug in den Menus gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy-Bugs wurden gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmencomputer wurden überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter können nun über den Computer gekündigt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment im Reporter Computer hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autos können beim Mechaniker lackiert werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Kennzeichen wird nun auf der Radarpistole angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der /fahrzeug Command wurde entfernt."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuge können nun bequem von innen und aussen mit 'L' auf- und abgeschlossen werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im HUD wird nun angezeigt ob eine Animation aktiv ist oder nicht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kennzeichenaufbau wurde angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im HUD ist ein neuer Balken für Fahrzeuginformationen (wie Abgeschlossen oder Tempomat aktiviert) dazu gekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkgerät und Handy können nun ohne Probleme im Fahrzeug verwendet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkgerät kleiner Cooldown hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Items fürs Roleplay wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fenstertitel und Icon wurden angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit den Händen oben kann das Item in der Hand nicht mehr gewechselt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Digitalwaage hat nun ein eigenes GUI"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Drogenkäufer wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Autobahn zur neuen Insel wurde zu Ende gebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es können nun Erdbeeren, Kopfsalat und Mais gepflückt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine neue Droge wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zollstelle wurde gebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pluginliste Ingame per Command wurde aktualisiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefängnisaufenthalt wurde spannender gestaltet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Gefängnis gibt es nun einen Gärtner Job"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Essen im Gefängnis muss man sich nun erarbeiten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Handy hat eine E-Banking App bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Handy hat eine Immobilien App bekommen in der man sieht, welche Wohnungen frei sind"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy Performance beim Öffnen verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animationen werden nicht mehr auf unzugewiesenen Tasten abgespielt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Funkstatus wird nun rechts unten im HUD angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Fahrzeug HUD wird nur noch als Fahrer angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedes Fahrzeug kann nun Schaden nehmen und muss nach einem Crash repariert werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man sieht als Häftling nun im HUD wie lange man noch sitzen muss"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls ein Fahrzeug abgeschlossen ist und es versucht wird aufzumachen hören das die Leute daneben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die News-Benachrichtigung bekommt nur noch wer auch ein Handy dabei hat"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazin im Polizeishop wurde ersetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Ein- und Auspark Punkte wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lizenzen wie Personalausweise, Joblizenzen oder Führerscheine werden jetzt schön im HUD angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Autor einer News wird nun in der Nachricht angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Anzugsladen mit neuen Anzügen wurde eröffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Friseursalon wurde eröffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die NPC's werden nicht mehr in der Tabliste angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC-System verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten für den Sprachradius angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angelsystem wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texturepack Inventar verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animationsproblem mit dem Liegen gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Liegen Animaton kann nun auch mit dem reset Hotkey abgebrochen werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kiosk wurde eröffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann sich nun Zigaretten kaufen und rauchen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun Punkte und andere Sonderzeichen problemlos im Handy benutzen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Einfügen im Handy wurde verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Aufwachen im Krankenhaus hat man nun einen Effekt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Konsumieren von Weed gibt einem ebenfalls einen Effekt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Video-Kamera hat nun eine Animation und einen Effekt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Sitzen fliegt das Item in der Hand nicht mehr in der Luft"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation für das Funken und das Telefonieren hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Handy hat ein neues Design bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann sich nun während man auf einem Stuhl sitzt nicht mehr hinlegen oder die Sitz Animation ausführen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man sich versucht auf ein Stuhl hinzusetzen während man ein Funk-Gerät in der Hand hat, wird dies blockiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun keine Knöpfe mehr betätigen, während man im Auto sitzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Hinsetzen auf einem Stuhl wird blockiert sollte man schon auf einem Stuhl sitzen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde ein Animations-Menu mit 'V' hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschiedene neue Animationen wurden in das Menu integriert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt nun mehrere Funkkanäle für bestimmte Fraktionen um sich besser organisieren zu können"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein gemeinsamer Funkkanal für Fraktionen ist dazu gekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bußgeldkatalog wurde angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Datenschutzerklärung-Menü wurde verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Gadgets Menü wurde die Währung von '€' zu 'Coins' geändert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Ausführen von Linkbezogene Commands wird die Website automatisch im Standard Browser geöffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schere Stein Papier ist nun einwandfrei spielbar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Map wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann sich nun nicht mehr hinlegen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle 20 Minuten wird die Map gewechselt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Map Größe passt sich nun an die Spieleranzahl an"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei einer hohen Killstreak bekommt man nun eine kleine Belohnung"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Lobby und GunGame kann man nun mit F12 den Server wechseln"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.09.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Wasser wurde die Ausdauer angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man ertrinkt nun wenn man zu wenig Ausdauer im Wasser hat"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wer über 65 Jahre alt ist bekommt nun statt Arbeitslosengeld seine verdiente Rente"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeug Dupebug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.09.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weedblüten können auch von unten geerntet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann sich nun über die Lobby mit /unlink entverifizieren"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverversion geupdatet (Evtl. kleine Performance-Verbesserungen)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.09.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank NPC Transaktionsbug behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busfahrer im Taxiunternehmen als Job hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die fehlenden Türen in der Neubaustr. wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Dupe-Bug behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respawnpunkt im Krankenhaus wurde gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handschellen und Kabelbinder Bug beim Respawnen behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt neue Immobilien zum mieten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus wurde neugebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Türsystem für Firmen wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martin Geiss und der Medic Empfang steht an einer neuen Stelle"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus Ausparkpunkt und Garage verschoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus Laptop umgesetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zu vollgeladene Autos können nun eingeparkt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankempfang NPC umgesetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy Nachrichten und Anrufe werden nicht angezeigt Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizeicomputer im Leitstellenraum funktional gemacht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefängnis Bug behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde ein Ausdauer Balken hinzugefügt (Verhindert Jump-Sprinten)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News App auf dem Handy ist nun verfügbar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Command entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weed Polizei Nachricht nach oben links verschoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weed Ernte ist nun nach Polizisten im Dienst lukrativer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspeak Plugin"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version 1.0.22 wurde veröffentlicht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Crash nach oder währendem Roleplay müsste behoben sein"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Voice-Effekte wie (Telefon, Funk, Wand) werden nach dem RP ordentlich entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt nun eine Suche im Handy für Kontakte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man sieht jetzt ob Kontakte erreichbar sind oder nicht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigene Nummer kann mit einem Klick kopiert werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannte Nummern können mit einem Klick eingespeichert werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy kann nicht mehr mit Linksklick geöffnet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Empfangen einer Nachricht erhält man nun einen Benachrichtigungston"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man hört nun durch ein Piepen, wenn man jemanden anruft"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei fehlgeschlagenen Anrufen, Anruf beendet, Nachricht konnte nicht gesendet werden uvm. wird ein error Sound abgespielt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakte können nun durch bearbeiten als Favorit markiert werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann eine Notiz zu einem Kontakt hinzugefügt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revive System überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewusstlos Fehler gefixt (Wiederbeleben nicht möglich, Blindness Effekt permanent)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewusstlose haben nun Kleidung und den richtigen Skin"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man Bewusstlos wird lässt man sein Item aus der Hand fallen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann sich nun überall mit einer Animation hinsetzen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man sieht nun die Sterne (Polizei im Dienst) auch unten links"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charübergreifende SMS gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man bekommt die SMS Benachrichtigung nur noch, wenn man ein Handy dabei hat"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch System überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ausrauben dauert nun je nach Ort länger oder kürzer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Bank kann nun ab 5 Sternen ausgeraubt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das grosse Handy-Update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Regeln bzgl. dem Handy"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC Bug fix"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC Bug fixes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientside performance Verbesserung"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC Bug fixes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namen von NPC`s werden nun einheitlich als 'ID: 0' angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tankstelle falsches Auto erkannt Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/log Command verbessert (Kürzerer Cooldown, performanter und übersichtlichere Gestaltung)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medic-Koffer Nachrichten nach oben verlagert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC-System komplett überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeder NPC hat nun einen generierten Skin sowie Kleidung"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun auch mit Schlagringen, Messer und dem neuen Baseballschläger die Shops ausrauben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz umgehen Fehler gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eisdiele am Strand hat eröffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taschen-Fehler Nachricht entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Verifizieren muss Teamspeak nicht mehr neuverbunden werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnaie Dupe-Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooldown beim Öffnen von Taschen entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taschen Dupe-Bugs das Handwerk gelegt (Hör auf Neue zu finden Jan!)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kleiner Cooldown beim öffnen von Taschen und Rucksäcken"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bier füllt nun den Durstbalken"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medic-Koffer und Reperatur-Kasten zu 3D Models geändert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagstock Durability fix"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limit an Items auf dem Boden eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooldown beim Fesseln und Entfesseln"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Platz für Handschellen Nachricht angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medickoffer Nachrichten angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele neue Kleidungsstücke im Kleidungsladen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Waffenladen verkauft nun Schlagringe"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kekse in der Bäckerei können nun gekauft werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Supermarkt kann man nun Bier kaufen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geldautomaten können nur noch von einer Person gleichzeitig benutzt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man muss nah genug an einem Geldautomaten stehen um ihn zu benutzen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bäume können nun wieder gefällt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechaniker Leitstelle kann wieder angerufen werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto abschleppen Cooldown hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distanz für das Sneakmenu erweitert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Burgerladen kann nun ausgeraubt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Leitstellen wird nun auch der Status angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Roller wurden schneller gemacht (Ein- und ausparken)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sneak-Menu Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewusstloser Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebannte Spieler werden nicht mehr gemovet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man sieht nun seinen Ban in der Lobby bereits"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Autos wurden eingeparkt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baumfällen vorübergehend deaktiviert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Performance Verbesserungen"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/playtime Command wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/support Command wurde hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen werden geladen in das Inventar gelegt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen werden im richtigen Feuermodus geladen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle 20 Minuten wird die Map gewechselt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Waffenauswahl wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Fehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spielzeit wird aufgezeichnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFA wurde aus dem Netzwerk entfernt"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzerklärungsabfrage eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby-System überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gadgets können mit Coins gekauft werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun täglich für unseren Server voten um den Server zu unterstützen und bekommt so eine Belohnung"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die geparkten Autos in der Garage laden um ein vielfaches schneller"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Performance Verbesserungen"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netzwerk"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf jedem Unterserver so wie Lobby können nun Animationen benutzt werden"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Holzverarbeiter wird jetzt der richtige Preis im Verkauf angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen Baum zu fällen dauert nun 20 statt 30 Sekunden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter in der Computer-Liste werden nur noch im Dienst angezeigt wenn sie online sind"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechaniker-Shirt im Beamter Computer entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter-Liste und Transaktionen im Computer laden deutlich schneller"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen im Bankautomaten werden schneller angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Performance Verbesserungen"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beamter können nun auch News-Nachrichten schicken"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Holz verarbeiten werden nun (4 -> 2) statt (5 -> 2)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation wird beim Char wechseln resettet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin wird nach dem Respawnen im Krankenhaus gesetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rucksack in Tasche legen Bug behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medic Error-Nachrichten nach oben verlagert"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehaltnachricht für Farmjobs entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beamter können nun auch News-Nachrichten schicken"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffen aus dem Waffenladen können nicht mehr kaputt gehen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Spectator sieht man nun die richtigen Skins"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Respawnen hat man nun den richtigen Skin"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behanldung Nachrichten nach Links oben platziert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin Migration als Frau Unterwäsche nicht sichtbar Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spacer Item ins Inventar buggen gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items aus dem Interaktionsmenu buggen gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funksystem überarbeitet & Funk für Flughafen eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kabelbinder nicht angezeigt Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungen funktionieren wieder"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News bekommen nun Staatshilfe"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justiz und News können nun Rechnungen ausstellen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justiz und News Transaktionen werden nun richtig angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justiz können nun Leute einstellen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkgeräte für Mechaniker & Taxifahrer eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsvertrag Kündigungsnachrichen hinzugefügt"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde aktualisiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Autos wurden eingeparkt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Map wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die RP-Tod Regel wurde geändert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt neue Immobilien, die gemietet werden können"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglicher Fix für den Bug, dass man eine Etage zu hoch spawnt eingebaut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doppelter Zahltag & Immobilien-Abrechnung behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound beim Durchsuchen von Mülleimern entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items in der Durchsuchung können nun nicht mehr versteckt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann nun nichts mehr ins Craftingfeld gelegt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nur noch Leute fesseln und entfesseln, wenn man die Handschellen oder Kabelbinder in der Hand hält"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden neue Waffen in den Waffenladen hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Polizei hat nun Schlagstöcke"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Sortiment des Kleidungsladens wurde vergrössert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Elektroladen bietet nun mehr Waren an"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Teamspeak stehen nun die Spieler IDs in der Nutzerbeschreibung"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Fehler bezüglich den Anzeigen oben links behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billy Cooper ist wieder eingereist (Drogendealer)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Holzfäller-Job ist nun besser bezahlt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Charaktersystem"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun mit dem Befehl '/character' oder über F12 seinen Charakter wechseln, ohne den Server zu verlassen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skinsystem überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charaktere sollten nun schneller laden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeder neue Charakter muss nun die Einreise am United Islands International Airport bestehen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Charaktere haben nun automatisch einen Personalausweis"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der letzte Spieltag eines Charakters wird nun gespeichert und in der Charakterauswahl angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurden einige neue RP-Items wie Krücken, Spritzen und viele weitere eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreibfehler korrigiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debug-Nachrichten beim Klicken auf Schränke entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Bug, der es ermöglicht hat, Items in dem gesperrten Bereich des Inventars zu platzieren, wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüsseldienst Konto Überprüfung behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.05.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Voiceplugin wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Während einem Telefonat hat der Gesprächspartner nun einen Effekt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leute die sich hinter ein Wand befinden, hört man nun abgedumpft"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Funk gibt es nun auch einen Audioeffekt, sodass die andere Person mehr so klingt, als würde sie wirklich durch ein Funkgerät sprechen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fehler, dass Leute nicht gemutet werden sollte behoben sein"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Map wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele neue Immobilien zum mieten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Autos wurden eingeparkt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizeiausparkpunkt nach Draussen verlagert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizeicomputer wurde versetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sounds und Actionbar bei den Farmrouten hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Mülleimer können nun mit Shift + Rechtsklick durchsucht werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pfandflaschen können nun im Supermarkt abgegeben werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holzfällerjob und -lizenz wurden eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzsystem überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasseninventar gesperrt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrufe und Funk wurden wieder lauter gestellt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Justiz kann nun Mitarbeiter einstellen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12.04.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateMC Texturepack hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorräder wurden ins Modpack hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic Surroundings Mod hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein paar kleine Bugfixes im Roleplay Mod"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preis für die Autopapiere weitergeben gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taschen und Rucksack System überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justiz und das Gerichtsgebäude ist nun offiziell angemeldet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News-Center und die Justiz kann nun News verschicken"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechaniker haben nun die Möglichkeit sich einen dienstlichen Pickup zu erwerben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lagerplätze in den Häusern sind nun gesperrt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat man Schlüssel für ein Haus oder ist eine Tür nicht abgeschlossen, kann man auf alle Truhen zugreifen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.04.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grosses Map Update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele neue Immobilien zu mieten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Polizei Hauptkommissar kann jetzt Leute einstellen und kündigen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Dupe-Bug bezügl. Rucksäcken wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Schlüsseldienst wurde in Betrieb genommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für Tankstellenraubüberfälle müssen nun wieder 2 Sterne im Tab sein"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tankstelle an der neuen Raststätte in Betrieb genommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ziele beim Schießstand sind wieder da"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.04.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command entfernt: /w"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rucksack Dupe-Bugs behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbuggte Rucksäcke und Taschen können nun entnommen werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man wird nun wieder durstig"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehalt Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugmod: Ein Fehler, bei welchem Fahrer von Fahrzeugen unsichtbar waren, wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugmod: Ein Fehler, dass Insassen auf einem falschen Sitz angezeigt wurden, wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugmod: Insassen von Fahrzeugen bleiben nun nicht mehr mitten auf der Straße hängen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeugmod: Nach dem Aussteigen aus einem Fahrzeug sollte man nun nicht mehr unsichtbar sein"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die staatlichen Gehälter wurden angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler beim Autoladen wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kennzeichen von staatlichen Fahrzeugen wurden auf 3 Stellen erhöht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Logo in der Serverliste wurde wieder hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Türen am Mechanikerhof wurden hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türen auf dem Militärgelände eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Autos wurden eingeparkt und sind nun in der Stadtgarage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige neue Immobilien wurden eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizeilager hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eisenkäufer (Baustelle) wurde verschoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man den roten Bildschirmrahmen hat, kann man nun trotzdem die Fortschrittsleisten oben sehen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt nun einige Spieleranimationen, die man ausführen kann"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man gefesselt wird, hat man nun auch visuell die Hände auf dem Rücken"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichten bezügl. dem Fesseln werden nun auch oben links in der Ecke angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibfehler im Tab berichtigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließfächer/Spinde am Mechanikerbetrieb eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Aufwachen im Krankenhaus wird man nun nochmal an die New-Life Regel erinnert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es können nun Pilze gesammelt und im Supermarkt verkauft werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsamt Lizenz-Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt nun einen Fragebogen, welchen jeder nochmal absolvieren muss, um auf dem Server zu spielen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun Joints drehen und konsumieren"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Krankenhaus ist man nun auch im Adventure-Modus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach dem Reanimieren oder aufwachen im Krankenhaus hat man nun nicht mehr volles Leben und muss sich noch behandeln lassen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Gefängnis sind nun alle Türen eingespeichert. Dies bedeutet, dass die Polizei jetzt auch Leute direkt in die Zelle sperren kann"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Tabliste wird nun angezeigt, ob man Leute ausrauben oder Geiseln nehmen darf"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man wird im Tab nun mit der Spieler-ID aufgelistet, um Failer schneller ausfindig zu machen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Bugfixes bezügl. Little Tiles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der rote Bildschirmrand, wenn man viel Schaden genommen hat sollte nun keine Laggs mehr verursachen und weniger CPU-Leistung beanspruchen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Transaktionen der Geschäfter werden nun richtig gelistet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschiedenste Ladebalken gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eisen Verarbeitung dauert nun 3 Sekunden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cannabisblüte abschneiden dauert jetzt 2 Sekunden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reifen können nicht mehr aufgeschlitzt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterne in der Tabliste haben neue Anforderungen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man ist nun immer im Adventure Gamemode um ein paar Fehler zu beheben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.02.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Modpack wurde aktualisiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Team wird nun auf TeamSpeak benachrichtigt, wenn jemand im Support wartet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Navigationssystem wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Testnachricht beim Überweisen von Geld wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaktionen in Geschäftscomputer werden nun richtig dargestellt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann seinen Charakter nun nicht mehr jünger als 18 machen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Immobilienmenü"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist nun Möglich, Schlüssel für Immobilien weiterzugeben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeder Immobilie ist nun einer Strasse und Hausnummer zugewiesen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Menüs können nur noch mit ESC und dem X oben rechts geschlossen werden, nicht mehr mit E."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann Handykontakte nun nur noch 1x weitergeben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man hört nun einen Herzschlag wenn man wenig Leben hat"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzlich hat man einen roten Bildschirmrahmen, wenn man wenig Leben hat"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Progressbar hat ein neues Design bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Hauptinsel wurde auf den Namen <<Central Bay>> getauft"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUIs können nur nicht mehr mit E geschlossen werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord Rich Presence wurde aktualisiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUD Design überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilien werden jetzt wieder abgerechnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Würfelberechnung wurde optimiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun Becher und Flaschen an Waschbecken mit Wasser füllen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler mit den Portemonnaies behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord RPC Update (Roleplay, Bauserver, etc..)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leute sind nicht mehr Unsichtbar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim betreten des Servers ist man nun immer auf normaler Sprachweite"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankautomat Duplizierungs-Bug wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Lagersystem wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug fix beim Eisen und Weed farmen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientseitige Lags minimiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehlende Häuser im Ghetto können nun gemietet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News von Fraktionen werden nun länger angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shop GUIs wurden überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Shops haben nun eine viel größere Auswahl zu bieten"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wird nun im HUD angezeigt, ob man auf TeamSpeak gemutet ist"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Anzeigefehler des Voiceradius beim zweiten mal joinen wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fahrzeugnachrichten werden nun auch oben links angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man ins Gefängnis kommt werden nun alle Items entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leute können nun in einem grösseren Radius ins Gefängnis gesteckt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man im Gefängnis bewusstlos wird, wacht man auch da wieder auf"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man gefesselt getasert wird, bleibt nun der Slowness Effekt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gefesselt kann man nun nicht mehr: Items droppen, Items aufheben, den Slot wechseln und Items verschieben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt im Kleidungsladen nun Portemonnaies in denen Geld verstaut werden kann"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann sich nun funktionierende Würfel kaufen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt nun ein Leitstellentelefon für Gefangene"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegenstände können nur noch sneakend aufgehoben werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Funklautstärke wurde verringert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TeamSpeak-Plugin"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice Plugin wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leute werden auf TeamSpeak automatisch entmutet, wenn man aus dem Ingame Channel kommt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lautstärke der Leute wird auf TeamSpeak nun automatisch zurückgesetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komplikationen im Voice Plugin wurden gefixt"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Discord Bot hat neue Like/Dislike-Emojis"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt jetzt einen Status Channel für Ideen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann sehen wie viele Likes und Dislikes die Idee bekommen hat"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog Command hat ein Update bekommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BauServer"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es gibt ein neues Bauserver-System"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschäftsraub ist als Polizist nicht mehr möglich"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizeitür hinten hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreibfehler korrigiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server Performance verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man ist nun nicht mehr durstig wenn man neu einreist"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nun Schlüssel für Autos wieder Problemlos weitergeben und wegnehmen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Serverinformationen werden nun auch links oben angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fraktions-News werden nun auch links oben angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde geupdatet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Spieler HUD wurde eingeführt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tankstellen GUI wurde eingefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die GUI's öffnen nun viel schneller"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radarpistole hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Geschwindigkeiten wurden angepasst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nicht mehr aus fahrenden Autos aussteigen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann nicht mehr in fahrende Autos einsteigen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reifen können nicht mehr mit der Hand zerschlagen werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizeiautos können nun abgesperrt werden damit man nicht mehr aussteigen kann"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autos werden nun automatisch langsamer wenn kein Fahrer mehr Gas gibt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempomat wurde eingeführt mit der Taste [*] auf dem Numpad"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann nun nicht mehr unendlich ausgezoomt werden während man im Auto sitzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sirenen werden nun bei allen gehört (Bug fix)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisches Blinken wurde entfernt geht nun mit den Numpad Pfeilen Links und Rechts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billy Cooper hat sich aus dem Staub gemacht"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rashid hat einen alten Bus gekauft"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Nachrichten werden nun links oben angezeigt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Durst-System wurde komplett überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Herzen, Hungerbalken und Levelbar wurden entfernt (Ihr seid nicht im Kreativmodus!)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Fahrzeug HUD wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Autos wurden in die Stadtgarage geparkt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafikmodus wurde Standard wieder auf schön gesetzt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf der Autobahn darf nun 100 KM/H gefahren werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn man den Voicemodus das erste mal wechselt ist man nun direkt auf schreien"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapfehler wurden behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fahrzeugmenu Fehler wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone Fehler gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkzeugbank kann nicht mehr benutzt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Strasse hat nun einen eigenen Namen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Häuser haben nun alle Hausnummern"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strassenschilder können nicht geändert werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideen können direkt im Ideen-Channel geschrieben werden. Das gleiche gilt auch für Fehler."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnee wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeuginteratkionsmenü wurde überarbeitet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Autos wurden eingeparkt in die Stadtgarage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Discord wird nun angezeigt wenn du Roleplay spielst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack hatte ein Update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapfehler wurden behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Eisenverkaufs-Fehler wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der 63er Stack-Bug wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone Verfügbar 2. Char Fehler behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Anruf wird auch abgebrochen wenn das Handy nicht in der Hand ist und man anruft"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchsuchungs-Dupe Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalausweis aus dem Inventar ziehen wurde behoben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy Kontakte sind jetzt nach Status sortiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dönerladen in United-Islands ist eröffnet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trinken löscht nun viel mehr Durst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man hört nun ob ein Auto auf- oder zugeschlossen wird"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann nur noch die hälfte des Lebens abgezogen werden durch Durst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Stahlpreis ist drastisch gestiegen (150 Euro für 64 Barren)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Arbeitsamt steht nun 'Bearbeitungs-Gebühr' weil es zu Missverständnissen kam"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überfälle auf Tankstellen und Donutladen geben nun mehr Geld"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Tankstelle außerhalb der Stadt kann nun auch überfallen werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türen können nun nicht mehr durch einen Bug geöffnet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Klingeln haben einen neuen Sound"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibfehler gefixt (Schneeball aufheben)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weihnachts-Pullover ist nun im Shop erhältlich"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glasscheibe im Kleidungsladen kann nicht mehr entfernt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Asservaten-Kammer der Polizei kann nur noch von den Polizeipräsidenten geöffnet werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack wurde aktualisiert um neue Items und Sounds ins Spiel zu bringen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map-Fehler gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handschellen Multi-Char Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibfehler gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polizisten können kein Weed mehr ernten oder verkaufen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map-Fehler gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele neue Discord-Bot Commands"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Discord-Bot schreibt dir nun Privat wenn dein Fehler behoben wurde"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Crash Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map-Fehler gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugfixes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Tankstellen können nun Räder und Reperaturkits gekauft werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normale Spieler können Räder und Reperaturkits benutzen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone GUI bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle rumstehenden Autos wurden in die Stadtgarage geparkt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es hat wohl geschneit in United-Islands"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man kann Schnee vom Boden aufsammeln"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kann jetzt schneien zwischen durch"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Gefängnis wurde nun in Betrieb genommen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haus im Ghetto wurde zur Vermietung freigegeben"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchsuchungs-Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man bekommt beim Weed verkaufen die 250 Euro"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhörraum Türen hinzugefügt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Türe im Reichenvierel wurde gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das fliegende Gebäude auf dem Meer wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance wurde verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Zahlungsdeklarierung bei gekauften Waffen wurde entfernt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausweis kann nicht mehr aus dem Inventar genommen werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krankenhaus-Türen wurden gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance wurde verbessert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man bekommt jetzt 1-3 Blüten aus einer Cannabispflanze"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkstop Sound-Fix"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apfelpreis wurde von runterkorrigiert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy und Funkgerät  können gefesselt nicht mehr benutzt werden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Man hört jetzt wenn andere das Funken starten / stoppen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das Funkgerät weggelegt wird, ist man automatisch nicht mehr im Funkchannel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisches betreten eines Funkchannels"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice-Bug gefixt 2.0"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für einen Stack Äpfel bekommt man nun 20 Euro statt 10 Euro"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für einen Stack Stahl bekommt man nun 60 Euro statt 20 Euro"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnungstür Preis Deklarierung"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice-Bug gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibfehler im Shop gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Systemfehler behoben"])}
          ]
        }
      ]
    }
  ]
}