export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])},
  "list": [
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.04.2024"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour du mod StateMC"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour du pack DynamX"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système stellaire a été révisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouveaux véhicules ont été introduits"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec un peu de chance, vous pouvez maintenant obtenir le Ford Transit chez CargoLink"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système de numérotation a été révisé, incluant de nouveaux numéros de centre de contrôle"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'école d'armement est une entreprise"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des hélicoptères ont été introduits pour les entreprises"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code des lois a reçu des modifications législatives depuis le dernier journal de modifications"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'autres petits bugs signalés sur Discord ont été corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablet"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tablette a reçu de nombreux changements différents depuis le dernier journal de modifications"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le menu de navigation se recharge correctement après une connexion"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les journaux chargent plus rapidement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tablette comprend maintenant des demandes de rendez-vous, qui peuvent être faites par des entreprises ainsi que par des entreprises créées par les joueurs à l'hôtel de ville"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bug avec les droits pour le PD de Sandhill utilisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le coin inférieur gauche se trouve un nouveau menu, avec de nombreuses fonctions utiles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chemin de la page actuelle peut désormais être copié via le Menu Info"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les chemins copiés peuvent maintenant être insérés en tant que liens dans ses propres pages via un bouton correspondant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page d'accueil est désormais accessible en double-cliquant sur le Menu Info"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les pages peuvent également être épinglées à la page d'accueil via le Menu Info"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le carnet de vol suggère des pilotes et les sites de décollage et d'atterrissage peuvent être modifiés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelques fautes d'orthographe ont été corrigées"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27.01.2024"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules peuvent à nouveau être endommagés et doivent être réparés après un accident."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le menu d'interaction avec les véhicules affiche désormais la raison des champs bloqués."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les véhicules ont désormais un odomètre fonctionnel."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sons du conducteur dans les karts ou les scooters de mobilité ne sont plus étouffés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines entreprises semblent avoir acquis des hélicoptères."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moritz Suhre est arrivé à Central-Bay et prévoit une activité lucrative."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'hôpital et la caserne de pompiers n'ont plus de réception commune pour les appels d'urgence."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La culture de plantes à plusieurs personnes dans une usine de médicaments n'est plus possible."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les objets ne sont désormais retirés du distributeur automatique qu'au moment de l'explosion."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules suivants : Mini, Shelby500 et Amvulcan n'ont plus les clignotants échangés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regina Piccio a trouvé un meilleur emplacement dans la cuisine des employés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les portes du bus peuvent désormais être ouvertes pour des tests."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les gilets de police ont maintenant un design plus attrayant."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les professionnels de la santé ont désormais un choix plus large de médicaments."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des tenues de travail ont été ajoutées pour le secteur judiciaire."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un pull de taxi est désormais disponible dans la boutique de taxis."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La texture de la radio a été remplacée par une nouvelle, plus séduisante."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les articles suivants ont été ajoutés : Techfleeces, gilets, polos et vêtements pour les sans-abri."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouvelles barbes, lunettes et coiffures ont été ajoutées."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les articles vestimentaires suivants ont été révisés : Taxi, blouse de médecin et MD."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouvelles options vestimentaires pour l'ordinateur du designer ont été publiées."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les agents d'immigration ont reçu de nouveaux hauts."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paramètres de brouillard ont été ajustés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la poubelle, on peut découvrir de nouvelles trouvailles."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'autres erreurs mineures ont été corrigées, comme signalé sur Discord."])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La neige a de nouveau fondu et les décorations de Noël ont été enlevées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'autres erreurs mineures ont été corrigées, telles que signalées sur Discord."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le camion de pompiers s'adapte désormais à la caserne de pompiers à Sandhill"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La signalisation au sol non continue à l'ancien parking a été corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers erreurs de construction ont été corrigées"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.10.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverses erreurs de construction ont été corrigées"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les poings américains ont désormais une image d'affichage dans le magasin d'armes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'image d'affichage du masque d'horreur (yeux boutons) est maintenant affichée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'emplacement de stockage dispose à nouveau d'un ordinateur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspirine ajoutée à la boutique médicale"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le point de navigation pour la boutique de chasse a été correctement placé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le point de navigation pour l'emplacement de stockage à Central-Bay est désormais correctement placé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le distributeur automatique de billets sur la piste de go-kart dispose désormais d'un point de navigation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La veste du FBI sans gilet a été ajoutée à la boutique du FBI"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La signalisation lors du vol au kebab dispose désormais d'un nom d'affichage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CrispyHero propose désormais du poulet dans l'assortiment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La classification des rangs dans l'ordinateur est revenue à l'ancienne"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le parcmètre chez le concessionnaire automobile a désormais un nom"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le garage de peinture dans l'atelier de mécanique peut à nouveau être utilisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres erreurs corrigées"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles cartes : Among Us, Casino"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.10.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'agence pour l'emploi a été reconstruite"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une station de métro a été construite"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin de kebabs a été reconstruit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le concessionnaire automobile du centre-ville a été reconstruit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin de burgers a été revu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ItalBao a fait l'objet d'une nouvelle mise en bouche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau port a été construit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dépôt a été compressé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mécanicien a été réaménagé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le ring de boxe a été remanié"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La porte roulante de la police de Central Bay s'est déplacée d'un mur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service de clés a été reconstruit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'aéroport a été modernisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le concessionnaire automobile du mécanicien a maintenant un nouvel emplacement, y compris une transformation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'autres petits changements dans les bâtiments du centre-ville"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La douane de Sandhill a été agrandie"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un ravin a été creusé derrière la douane"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandhill et l'île de la Chasse sont désormais reliées par voie terrestre"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le pont entre l'île de la Chasse et l'île de la Banque centrale s'est effondré"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une nouvelle piste de karting a été construite sur Sandhill"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La boutique de chasse a déménagé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouveaux bâtiments ont été construits sur l'île de la Chasse"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouveaux arbres fruitiers ont été plantés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouvelles boutiques ont ouvert dans le métro"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau kiosque a ouvert à la piste de GoKart"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CB-PD, FBI, SH-PD, MD, FD, les mécaniciens ont de nouveaux objets dans leurs boutiques"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin de vêtements a élargi son assortiment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La police a maintenant des gilets pare-balles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les vêtements de la police ont été révisés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains vêtements MD ont été revus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le MD a un nouvel assortiment de médicaments"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le MD & FD ont reçu de nouveaux véhicules"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les vêtements des mécaniciens ont été révisés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'école de conduite a un nouveau véhicule"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a de nouveaux fruits à récolter (pêches, etc.)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certains objets ont été remplacés par des objets modèles plus beaux"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug autour des chariots PD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de l'itinéraire du courrier"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug dans l'envoi de dispatch"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'autres bugs"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.07.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle carte : Métro"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustements mineurs de la carte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les joueurs ont été repositionnés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les armes ont été replacées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bug des menottes a été corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de HUD corrigée après la résurrection"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres bugs corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.07.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement plus rapide lors de la sélection d'un personnage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau système de remorquage pour les agents de police avec des informations de base et une visibilité en jeu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordinateur portable dans la fourrière indique les voitures qui ont été remorquées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de bugs supplémentaires"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les interfaces utilisateur ont été reprogrammées et peuvent être mieux étendues et personnalisées à l'avenir"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le menu du garage dispose maintenant d'une recherche par plaque d'immatriculation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La création de personnage ainsi que le salon de coiffure disposent désormais d'une prévisualisation en 3D de la peau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La flèche indiquant la position du joueur dans l'application de la carte est désormais mise à jour même lorsque l'application est ouverte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le jeu dispose d'un tout nouveau menu principal qui affiche le journal des modifications et permet le mode solo"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La concession automobile dispose d'une nouvelle interface graphique pour les véhicules"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les véhicules de la concession automobile ont de nouvelles images de prévisualisation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nombreux nouveaux tatouages ont été ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un véhicule déambulateur a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un véhicule-citerne Scania a été ajouté au modpack"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les performances de génération de peau ont été corrigées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de serveur plus rapide"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les curseurs dans les interfaces graphiques fonctionnent maintenant parfaitement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CustomMainMenu a été supprimé."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de bugs supplémentaires"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.07.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le revolver OneShot n'est disponible que 40 secondes après le début du jeu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le pass de détective a été ramené à 200 pièces"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les pièces ne sont plus déduites pour les laissez-passer qui n'ont pas été échangés avec succès"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres bugs corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.06.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte coupe-feu fixe"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poubelle ajoutée à East Cliff PD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trou d'hôpital dans le mur fixé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bugs corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinateur manquant ajouté à l'hôpital"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dépôt ne peut plus être ouvert à partir du mauvais PD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleure gestion des performances pour le serveur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les verrous de licence peuvent être distribués à nouveau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bugs corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un son pour la fermeture des portes"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 nouvelles cartes ajoutées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les anciennes cartes ont été supprimées pour l'instant"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug sur la carte"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clôture remplacée à la douane"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonne enlevée à l'ItalBao"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panneaux enlevés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de la colonne manquante à la maison verte de Central-Bay"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un mur manquant à la Kreuzgasse 4"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'entrée du bureau de la pharmacie a été réparée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bugs corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétés manquantes sauvegardées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les images immobilières se chargent beaucoup plus rapidement maintenant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez à nouveau montrer le permis de port d'arme"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordinateur médical manquant ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une machine de stationnement manquante"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casiers médicaux corrigés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seules les places de parking publiques sont désormais affichées sur la carte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction des points de navigation dans l'application cartographique"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un point de navigation pour le magasin de vêtements de Sandhill"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application Maps a de nouveau toutes les catégories pour subdiviser les points"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La police dispose d'ordinateurs au poste de douane"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les permis de conduire peuvent à nouveau être délivrés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction : Les portes émettent à nouveau un son lorsqu'elles s'ouvrent et se ferment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bugs corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mêmes changements que dans le roleplay"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mod Securitycraft a été supprimé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les effets après la consommation de drogues ne se cassent plus prématurément"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modèles de Taser ont été remplacés et vous obtenez maintenant des ralentissements et des chutes à la suite d'un tir de Taser"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plage de rendu par défaut des TileEntity a été réduite de moitié"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portée du rendu de TileEntity peut maintenant être ajustée dans les options du mod"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portée de la voix peut maintenant être ajustée en millisecondes pour rendre des temps plus courts que 1 seconde"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug de texture avec les chaussures bleues"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de divers nouveaux objets pour le RP"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug des yeux du masque de tempête gris"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avertissement lors de l'entrée dans le jeu si Optifine n'est pas installé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bugs corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'hôpital a été entièrement reconstruit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La compagnie de taxis a été entièrement reconstruite"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le complexe de kiosques et de salons de coiffure a été supprimé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'animalerie a été supprimée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau kiosque, un growshop, une animalerie et un salon de tatouage ont été construits"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une pharmacie a été ajoutée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les buissons et les nénuphars sont maintenant perméables sur toute la carte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les images de la carte ont été remplacées par des images moins gourmandes en ressources informatiques"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une nouvelle île (Sandhill) a été ajoutée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverses parties de la carte ont été rendues plus performantes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nombreuses constructions LittleTiles ont été remplacées par des blocs DynamX car ils sont plus performants"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'atelier de menuiserie a été reconstruit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers autres bâtiments ont été adaptés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bugs corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les pompes à essence peuvent désormais être utilisées dans le bloc supérieur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'entrée, vous pouvez maintenant choisir d'atterrir à Sandhill ou à Central-Ba"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les portes s'ouvrent et se ferment lentement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau magasin : Pharmacie"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau médicament a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les anciens tasers ont été remplacés automatiquement par les nouveaux"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après avoir perdu connaissance, vous frayez dans l'hôpital le plus proche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le centre de contrôle a été séparé de la police de Sandhill et selon la zone dans laquelle vous vous trouvez, le centre de contrôle le plus proche est appelé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si l'un des centres de contrôle de la police n'a pas de personnel, l'autre est automatiquement appelé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les polices de Sandhill et de CentralBay ont désormais des numéros distincts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouvelles cultures ont été ajoutées : Seigle, Rhubarbe, Chili, Tomate"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous demandez une hache et que vous n'avez pas de place, vous n'aurez plus de temps de recharge"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne vous montrerez plus dans la gestion des clés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction des adresses avec la déclaration United-Islands"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug empêchant de passer les clés lorsque quelqu'un se trouve à proximité en tant que spectateur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attaché, vous ne pouvez plus ouvrir ou utiliser votre téléphone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant voir un message avant de faire le plein si vous êtes à court d'argent"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bogue selon lequel n'importe qui peut se connecter au service a été corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les répartitions ne s'affichent plus sur le mauvais personnage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps de refroidissement des envois est désormais lié au personnage et non plus au joueur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les verrous de licence dans l'ordinateur de la police peuvent à nouveau être supprimés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a maintenant toujours un message et un son d'erreur si un numéro n'existe pas lors d'un appel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous appelez des personnes décédées, vous obtenez également l'information que ce numéro n'existe pas"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paiements de pension ne sont plus affichés comme un relevé de propriété"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug : le bus ne peut pas être ravitaillé en carburant (moins les montants)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les tatoueurs peuvent maintenant être piqués par plus de caractères"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsqu'une voiture est garée à 15 heures, le niveau de carburant et l'état de la serrure sont toujours enregistrés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les distributeurs automatiques de billets ne peuvent plus être utilisés que de près"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les distributeurs automatiques de billets ont été dotés d'un système de détection de gaz"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour faire exploser un distributeur de billets, il faut désormais que deux étoiles soient actives"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le gaz propane est éliminé directement lors de l'explosion d'un distributeur de billets"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le gaz propane peut maintenant être obtenu par d'autres moyens dans le RP"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de nombreux nouveaux vêtements"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les traductions des objets ont été corrigées : Rose, Ange"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte de l'application mobile a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bureau de l'emploi montre maintenant tous les emplois et comment les obtenir"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le menu immobilier a reçu une image pour chaque appartement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant vous noyer à nouveau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle animation de noyade"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bugs corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mêmes changements que dans le jeu de rôle"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DynamX a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les feux de freinage et de recul sont corrigés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les plaques d'immatriculation sont corrigées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules ne devraient plus s'enfoncer dans le sol"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers bugs ont été corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les peaux féminines ont des défauts de peau fixes sur les épaules"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a plus de skins aléatoires lors de la création d'un personnage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La couleur de cheveux sélectionnée est maintenant conservée dans la position du personnage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouvelles coiffures ont été ajoutées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nombreuses nouvelles barbes ont été ajoutées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 100 tatouages ont été ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant choisir le maquillage dans la création du personnage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les extras faciaux tels que par exemple. Les cernes étaient séparés des yeux"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de nouveaux extras faciaux tels que. Cicatrice"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chez le coiffeur, tu portes toujours tes vêtements en avant-première maintenant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de divers bugs"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snack automat a reçu un nouvel assortiment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les militaires peuvent maintenant ouvrir des portes en fer sans carte-clé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers bugs ont été corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mêmes changements que dans le jeu de rôle"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une note sur la connexion avec Mojang"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.04.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 Nouveaux Défis"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de 10 nouvelles armes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les magazines sont maintenant toujours à la 2e place dans le magasin d'armes à feu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de divers bugs"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.04.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration de l'apparence des voitures de police"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles voitures ajoutées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traductions d'éléments corrigées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction de la pompe à essence corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers bugs corrigés"])}
          ]
        },
        {
          "serveur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un bus a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers bugs ont été corrigés"])}
          ]
        },
        {
          "serveur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers bugs ont été corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.04.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuite de mémoire corrige les PNJ"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peaux de voitures de police améliorées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de nouveaux cactus pour Sandhill"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de divers bugs"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'erreur selon laquelle le téléphone n'est pas mis à jour lors de la création d'un personnage a été corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un point de navigation pour le centre logistique"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le travail du fournisseur a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test de drogue dans un magasin de police"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasin de police de compteurs d'alcool"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation révisée (points visuels)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poste de facteur ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les pompiers ont été ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a maintenant plus d'étapes dans l'eau potable que juste pleine ou vide"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les véhicules ont été réduits de 30% d'accélération"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les entreprises de magasin d'informatique sont maintenant classées en catégories"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin de vêtements a élargi son assortiment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De délicieuses nouvelles collations ont été ajoutées au distributeur automatique de collations"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mêmes changements que pour le jeu de rôle"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.03.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siège passager Shelby500 réparé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cornes pour Mini et Shelby corrigées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probablement corrigé le bug avec la Mini volante"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les vêtements des forces spéciales étaient équipés d'une petite protection"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les militaires ont à nouveau un vêtement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La texture des cigares a été corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacti pour Sandhill a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'auto-école a reçu une chemise"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances de la physique"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers bugs corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le vin aide aussi à lutter contre la soif"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne perdez plus d'endurance en conduisant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bug du paquet de cigarettes a été corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs magazines et armes peuvent maintenant être achetés en même temps dans le magasin d'armes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le billet de transport de passagers a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous êtes réanimé, vous ne vous levez plus mais vous vous asseyez"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le centre de contrôle de l'hôpital apparaît à nouveau comme disponible dans les contacts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le t-shirt de l'auto-école est disponible dans la boutique de l'auto-école"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers bugs corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.02.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pack de mods"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'icône muet au-dessus de la tête a maintenant une meilleure résolution"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'icône de mise en sourdine n'est plus affichée lors de la dissimulation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DynamX a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les modèles de véhicules suivants ont été renouvelés: Mini, Aston Martin, Shelby 500 et la 6x6"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des nénuphars et divers buissons à travers lesquels vous pouvez marcher ont été ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de divers bugs"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeu de rôle"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La police a un nouvel uniforme pour chaque grade"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a des cigares à acheter au kiosque maintenant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand tu es inconscient, tu ne reçois plus d'appels"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux objets RP ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des cardigans ont été ajoutés au magasin de vêtements"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de divers bugs"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le support multilingue a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas plus de joueurs ne peuvent rejoindre que la plus grande carte ne le permet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des tables est maintenant désactivée dans TTT"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant voir le nom au-dessus de la tête de chaque joueur dans un rayon de 5 blocs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le traître tué sera affiché avec le bon skin après la recherche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gel Pistolet fixe"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le spectateur voit désormais correctement tous les skins"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performances améliorées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de divers bugs"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.02.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque Kill est affiché dans un message pour tout le monde"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'endurance a été complètement supprimée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La soif et la faim ont été supprimées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps de réapparition a été réduit à 3 secondes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On peut maintenant régénérer la vie en mangeant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque nouveau joueur apparaît en mode passif"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les armes des voitures ouvertes ne peuvent plus se casser"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous perdez connaissance, la perquisition est interrompue"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est maintenant possible chez le mécanicien de colorer son véhicule"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de divers bugs"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.01.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous respawn dans un endroit proche du lieu de la mort"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'objet dans votre main ne tombe plus au sol lorsque vous mourrez"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mode passif n'est plus activé après le respawning."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système de niveaux a été révisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur toute la carte peuvent maintenant apparaître des véhicules aléatoires, qui avec de la chance ne sont pas verrouillés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans les véhicules déverrouillés, vous pouvez trouver des objets utiles."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le défi horaire commence maintenant déjà à partir de 8 joueurs."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les étoiles dépendent désormais du nombre de joueurs en ligne en mode actif."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les stations-service peuvent maintenant être cambriolées et les rivaux peuvent empêcher le cambriolage."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plusieurs bogues"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.12.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vente de poissons est désormais possible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bug concernant la collecte d'objets lorsque les inventaires sont pleins a été corrigé."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le HUD a été entièrement révisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les stations-service indiquent désormais si elles ont déjà été cambriolées."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le permis d'armes de gros calibre a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'arme de chasse est maintenant disponible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les animaux apparaissent désormais sur l'île de chasse"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les déchets des animaux de l'île de la chasse peuvent être vendus."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des vestes d'hiver sont disponibles dans la boutique de vêtements"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La gamme de voix est maintenant affichée visuellement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsqu'une attaque a été interrompue, elle peut être recommencée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le cambriolage du magasin ne peut désormais être lancé que par un clic droit et ne peut donc pas être déclenché par erreur."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les aliments peuvent désormais être consommés, que vous ayez faim ou non."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un manteau pour médecin a été ajouté au magasin"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mouvements plus réalistes pour les PNJ"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les PNJ regardent maintenant les joueurs dans les yeux."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les PNJ peuvent maintenant se déplacer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendant un vol, les PNJ lèvent maintenant les mains en l'air."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du Skinbug avec les PNJ"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les PNJ ne disparaissent plus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant cibler les PNJ avec des armes sans ouvrir les magasins."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les dépêches ont reçu un nouveau son plus approprié."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["D'autres bogues ont été corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annonce des défis est affichée 5 minutes avant le défi."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau HUD de niveau a été introduit pour donner une vue d'ensemble plus agréable visuellement."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau menu de niveau où vous pouvez voir quand vous débloquez tel ou tel élément."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau HUD pour les défis qui visualise le défi actuel avec une liste de classement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mode passif est maintenant aussi affiché dans le HUD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant jouer à Freeroam sans voix"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les fonctionnalités du RP-Changelog ont également été implémentées dans Freeroam."])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall d'entrée"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le niveau global est affiché"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigateur"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'écran inconscient est affiché avec un fondu enchaîné."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le navigateur F10 fonctionne mieux, notamment la barre de recherche."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de la possibilité de copier du texte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez naviguer entre les personnages avec la souris"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pointeur de souris différent, par exemple lors du survol d'éléments cliquables."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plusieurs bogues"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack est à nouveau compatible avec Linux"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une barre de niveau pour GunGame pendant les jeux"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une barre de niveau pour le TTT pendant les jeux"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous subissez désormais moins de dégâts de la part des autres joueurs"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.11.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverses corrections de bogues de la dernière mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application Maps a été publiée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beaucoup de nouvelles choses sont affichées sur la carte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a maintenant un menu emoji dans les chats mobiles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il a fait plus froid aux Etats-Unis"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouveaux biens immobiliers et de nombreuses extensions de souris"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les animaux ne peuvent plus recevoir de badges nominatifs (correction de bug)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06.11.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les pages telles que la page de vote sont maintenant ouvertes directement dans le jeu."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'auto-école peut désormais émettre des factures"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les chômeurs et les pensionnés reçoivent à nouveau leurs allocations de chômage ou de pension."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'auto-école peut désormais délivrer des permis de conduire"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les permis de conduire du CNP sont devenus plus chers."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut de la station-service est affiché dans les contacts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le support multi-langues a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles diverses révisées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsqu'une facture est payée ou rejetée, l'animation se termine automatiquement."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un garage pour la banque centrale"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsqu'une voiture est cédée, le nouveau propriétaire se voit retirer les clés s'il en avait encore."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les guichets automatiques ne peuvent plus être utilisés par deux personnes en même temps."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules peuvent désormais être vendus chez le ferrailleur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les PNJs des acheteurs et des échangeurs ont maintenant une nouvelle interface graphique."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'employé de la banque a maintenant une nouvelle interface graphique plus agréable."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nombreux vêtements ont été retexturés et ont désormais un aspect plus qualitatif."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les fraises et les laitues peuvent désormais être vendues au supermarché"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enfin, vous pouvez acheter des chaussures blanches dans le magasin"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sièges de la Shelby GT 500 ont été fixés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur les véhicules de l'entreprise, tous les patrons ont les clés et peuvent les donner."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.09.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall d'entrée"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le plan du hall a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque joueur sélectionne désormais sa langue lorsqu'il entre pour la première fois dans le jeu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Jump a été optimisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'espace VIP est désormais mieux protégé des joueurs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciseaux, pierre, papier enlevé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un lien entre les statistiques et le PNJ"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau d'affichage retiré"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande Unverify fonctionne à nouveau correctement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un support multi-langue"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lobby supporte maintenant les langues : Anglais, Allemand, Français et Italien. Le système sera étendu à GunGame, Roleplay etc. dans le futur."])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.09.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les performances du véhicule ne se détériorent plus après un certain temps."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les personnes dans les véhicules peuvent maintenant être entendues en sourdine."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Royal Ocean Casino a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sirènes et les cornes peuvent être entendues bien plus souvent maintenant."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de divers bugs liés aux véhicules"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de divers bugs liés aux armes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'auto-école a été ajoutée en tant qu'entreprise"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'objet de la prison peut être mis en détention - bug corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nombreuses boissons To Go ont été ajoutées dans tous les magasins."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les coffres-forts peuvent désormais être loués"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules de presse sont maintenant garés dans la cour du mécanicien et les véhicules de la police et des services médicaux sont garés juste à côté."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne vous téléportez plus un étage plus haut lorsque vous vous déconnectez assis."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration de la performance du jour de paie"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du client"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.08.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mod d'arme est maintenant compatible avec Linux"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration de Lie Sync"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La barre de faim est désormais également mise à jour lorsque l'on s'assied."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05.08.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La portée vocale fonctionne à nouveau correctement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le vol dans les stations-service fonctionne désormais aussi avec les nouvelles armes."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vitesse en position couchée a été ajustée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'avant-première du salon de coiffure fonctionne à nouveau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchronisation du mensonge éventuellement corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la voiture, vous ne pouvez plus vous allonger"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.08.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'assortiment des magasins de vêtements a été élargi"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une faute d'orthographe dans le magasin de costumes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système de peau a été optimisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mod d'arme a été remplacé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les portes peuvent être ouvertes avec une arme à la main"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'affichage des munitions dans le HUD est nouveau."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les magazines s'empilent désormais toujours correctement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est désormais possible de tirer dans les véhicules"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans l'armurerie, tous les articles ont maintenant une photo."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les armes de la police ont été remplacées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mensonge est maintenant synchrone, même si quelqu'un le rejoint plus tard."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventaires des coffres fixés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les messages doubles dans le coin supérieur gauche sont empilés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un double bug"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenir inconscient est maintenant beaucoup plus fluide"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le stationnement des véhicules dans la cour de remorquage ne fonctionne qu'avec un officier de police à côté."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cachette, vous ne pouvez plus ouvrir votre téléphone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les interfaces sont désormais également utilisables avec un téléphone portable à la main."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les questions du quiz ont été ajustées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le quiz, 20 questions aléatoires sont maintenant posées à chaque joueur individuellement."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous n'avez pas encore passé l'entrée, vous recevez maintenant le message de bienvenue à chaque entrée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous commencez au bas de l'application Nouvelles à la dernière nouvelle."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du client"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.07.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'allocation clé pour l'immobilier fonctionne à nouveau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les vêtements sont maintenant rendus plus ajustés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cheveux longs sont désormais exposés au-dessus des vêtements"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les médecins ont reçu des blouses chirurgicales"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'assortiment du magasin de vêtements a été élargi (capuches / bandeaux / uvm.) [Merci à Virotex <3]."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin de lunettes de la jetée a ouvert ses portes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de l'erreur d'affichage du canon radar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support Linux pour notre modpack"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une erreur d'orthographe dans le message de la facture (bonus)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une erreur d'orthographe lors de la création d'un personnage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.06.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau système d'enregistrement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque le permis de conduire ou le permis de port d'arme est révoqué, l'inventaire est mis à jour."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'interface utilisateur peut maintenant être mieux mise à jour sans mise à jour du modpack"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performances légèrement améliorées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06.06.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de l'erreur d'affichage de l'indicateur de vie et de faim"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le changement de personnage se charge désormais plus rapidement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La ville est maintenant animée par quelques animaux"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La police a désormais la possibilité de distribuer les suspensions de permis de conduire et de permis d'armes à feu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordinateur de la police a maintenant une vue d'ensemble du blocage des détenus et des permis."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin de costumes a élargi sa gamme"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug des doublons"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte a été enrichie de plusieurs nouvelles zones"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais utiliser les emojis dans votre téléphone portable. Vous pouvez trouver une liste"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une règle a été ajustée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.05.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptabilité immobilière révisée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous n'avez pas d'argent pour un bien, seul ce bien sera annulé et non pas tous les biens."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les taxes ont été introduites pour plus de 2 propriétés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de l'erreur d'affichage du temps d'adhésion"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps de détention est affiché instantanément après la détention"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un transporteur bancaire pour l'événement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.05.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles marchandises dans les stations-service"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divers éléments de RP ont été ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faire sauter des distributeurs automatiques de billets est désormais possible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notre interface utilisateur fonctionne désormais en externe - Cela nous donne plus de flexibilité pour corriger les bugs et faire des ajustements."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduction du son ATM"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sonneries du téléphone portable peuvent à nouveau être correctement sélectionnées."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application Dispatch a été déplacée sur la page d'accueil et les paramètres sur l'autre page."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression des entreprises inutiles dans l'application Dispatch"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.05.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ATM ne paie pas assez - erreur corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les magasins ne donnent pas tous les articles - bug corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du point de navigation SeaSide"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le canal radio est quitté lors d'un changement de carte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléphone du centre de contrôle ne peut plus être utilisé abusivement comme un téléphone normal"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps de redémarrage a été raccourci (le serveur est de retour plus rapidement après 15:00)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.04.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un son lors de la fermeture des portes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonction d'envoi pour les PNJ supprimée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20.04.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice, Actualités & Le FBI s'est doté d'un centre de contrôle"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice, Nouvelles et FBI ont été ajoutés comme contacts standards."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles offres d'emploi ajoutées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les interfaces graphiques des PNJ du centre de contrôle ont été remplacées."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sonneries sont maintenant jouées quand elles sont sélectionnées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les armes peuvent désormais être utilisées avec des shaders"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.04.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'animation se termine automatiquement après l'affichage du badge."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de nouveaux arrière-plans pour les mobiles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sonnerie du téléphone portable est désormais réglable"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une application permettant de rechercher son véhicule a été ajoutée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le menu ATM a été révisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bouton AFK a été ajouté dans le menu des personnages"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision de la notification de soutien"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un garage dans le parking de l'église"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performances du client légèrement améliorées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.03.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du client"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le personnage devrait maintenant se charger beaucoup plus rapidement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redémarrage automatique à 15:00"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules sont garés tous les jours à partir de 15 heures."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug avec des personnes inconscientes invisibles après l'adhésion"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction des problèmes de clients"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy Bugs gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sièges supplémentaires dans les voitures"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de la répartition"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCEF a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recherche dans le téléphone portable a été fixée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug de retour en arrière dans l'interface utilisateur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application Dispatch a été ajoutée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'écran inconscient a été remplacé et amélioré"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le menu d'interaction a été remplacé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une faute d'orthographe dans la recherche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les articles gratuits en magasin, le panier n'est plus affiché"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émettre une facture révisée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision de l'entrée en prison"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre maximum d'unités de détention a été fixé à 6"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps de recharge des Dispatches est désormais de 60 secondes."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation pour la présentation des licences et l'émission des factures"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message après l'entrée serait ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le kaki étanche aussi la soif."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouveaux points de navigation ont été ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performances légèrement améliorées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de l'erreur du PNJ de la pioche / de la hache"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les salaires ont été augmentés de 10%."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules d'entreprise sont désormais facturés à partir du compte professionnel lors d'un ravitaillement en carburant avec paiement par carte."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande de nouvelles supprimée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une machine de stationnement près de l'église"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacement de l'interface graphique de Minecraft dans le bureau de l'emploi"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une faute d'orthographe dans le quiz"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La disparition des voitures lorsque vous vous déconnectez en conduisant est corrigée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prix de la cocaïne a été ajusté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le FBI compte maintenant aussi parmi les \"stars"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après un redémarrage du serveur, il n'est plus nécessaire de se reconnecter à Teamspeak"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görgi a ouvert son supermarché à East-Cliff."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La station-service d'East-Cliff peut désormais être cambriolée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les supermarchés et le kiosque peuvent maintenant être volés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les appels ne sont plus simplement terminés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La navigation a été un peu améliorée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug des menottes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une erreur de recherche de plaque d'immatriculation avec l'ordinateur de la police"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.01.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de l'erreur de respawns de la cocaïne"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les commandes du robot Teamspeak fonctionnent désormais beaucoup plus rapidement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes désormais transféré beaucoup plus rapidement dans la salle d'attente du jeu."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les performances ont été quelque peu améliorées"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16.01.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps de recharge du médecin à l'hôpital dépend désormais du personnage et non plus du joueur."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On peut à nouveau cultiver la drogue avec plus de 11 flics"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug du HUD du véhicule"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se tenir à côté du véhicule alors qu'on y est monté bug corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de l'indicateur pour le panier de nouvelles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de klaxons pour les véhicules manquants"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixation du coffre pour les véhicules div."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre de messages affichés dans le téléphone portable a été réduit au minimum"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les performances ont été quelque peu améliorées"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.01.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'animation n'est plus annulée le jour de la paie"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a beaucoup de nouvelles propriétés à louer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système de garage a été revu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin de voitures a été révisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les véhicules existants ont été retirés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nombreux nouveaux modèles de voitures ont été ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules ont maintenant une physique beaucoup plus agréable"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules sont maintenant simplement dirigés avec WASD."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug du clic dans les menus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des bogues mobiles ont été corrigés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les ordinateurs de l'entreprise ont été révisés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les employés peuvent désormais être licenciés via l'ordinateur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipement ajouté dans l'ordinateur du journaliste"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les voitures peuvent être peintes chez le mécanicien"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de la plaque d'immatriculation est maintenant affiché sur le pistolet radar."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande /véhicule a été supprimée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les véhicules peuvent désormais être déverrouillés et verrouillés de l'intérieur et de l'extérieur de manière pratique grâce au système 'L"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan a été supprimé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le HUD indique désormais si une animation est active ou non."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La structure de la plaque d'immatriculation a été adaptée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le HUD, une nouvelle barre pour les informations sur le véhicule (comme verrouillé ou régulateur de vitesse activé) a été ajoutée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La radio et le téléphone portable peuvent désormais être utilisés dans le véhicule sans problème."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio petit cool down ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouveaux éléments pour le jeu de rôle ont été ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le titre et l'icône de la fenêtre ont été ajustés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec les mains sur le dessus, l'objet dans la main ne peut plus être changé."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La balance numérique a maintenant sa propre interface graphique"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'acheteur de drogue a été révisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La route menant à la nouvelle île est terminée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les fraises, la laitue et le maïs peuvent maintenant être cueillis."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouveau médicament a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bureau de douane a été construit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des plugins en jeu par commande a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un séjour en prison rendu plus excitant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En prison, il y a maintenant un emploi de jardinier"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez maintenant travailler pour la nourriture en prison."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléphone portable est équipé d'une application de banque en ligne."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléphone portable a une application immobilière dans laquelle vous pouvez voir quels appartements sont libres."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du téléphone portable à l'ouverture"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les animations ne sont plus jouées sur les touches non attribuées."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le statut de la radio est maintenant affiché dans le coin inférieur droit du HUD."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le HUD du véhicule ne s'affiche plus qu'en tant que conducteur."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout véhicule peut désormais subir des dommages et doit être réparé après un accident."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant que prisonnier, vous pouvez maintenant voir dans le HUD combien de temps vous devez encore rester assis."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si un véhicule est verrouillé et que vous essayez de l'ouvrir, les personnes à côté l'entendront."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La notification des nouvelles ne parvient qu'à ceux qui ont aussi un téléphone portable sur eux."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magazine du magasin de la police a été remplacé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouveaux points de stationnement et de déverrouillage ont été ajoutés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les licences telles que les cartes d'identité, les permis de travail ou les permis de conduire sont maintenant joliment affichées dans le HUD."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'auteur d'une nouvelle est désormais affiché dans le message"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un magasin de costumes neufs a été ouvert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le salon de coiffure a été ouvert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les PNJ ne sont plus affichés dans la liste des tableaux."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système de PNJ amélioré"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages adaptés au rayon de la parole"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système de pêche a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration du pack de textures Inventar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un problème d'animation avec le mensonge"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'Animaton Liegen peut maintenant être annulé avec la touche de raccourci \"reset\"."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le kiosque a été ouvert"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant acheter et fumer des cigarettes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais utiliser les points et autres caractères spéciaux dans le téléphone portable sans aucun problème."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'insertion dans le téléphone portable a été améliorée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après vous être réveillé à l'hôpital, vous avez maintenant un effet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommer de l'herbe vous donne aussi un effet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La caméra vidéo a maintenant une animation et un effet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En position assise, l'objet dans la main ne vole plus dans les airs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une animation pour l'étincelle et l'appel téléphonique"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléphone a un nouveau design"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'est plus possible de s'allonger ou d'effectuer l'animation \"s'asseoir\" en étant assis sur une chaise."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous essayez de vous asseoir sur une chaise tout en tenant une radio dans votre main, cela sera bloqué."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez plus appuyer sur aucun bouton lorsque vous êtes assis dans la voiture."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'asseoir sur une chaise est bloqué si vous êtes déjà assis sur une chaise."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un menu d'animation avec 'V' a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plusieurs nouvelles animations ont été intégrées dans le menu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe désormais plusieurs canaux radio permettant à certaines factions de mieux s'organiser."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un canal radio commun pour les fractions a été ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le catalogue des amendes a été ajusté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall d'entrée"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le menu de la politique de confidentialité a été amélioré"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le menu \"Gadgets\", la devise a été modifiée de \"€\" à \"Pièces\"."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de l'exécution des commandes liées aux liens, le site web est automatiquement ouvert dans le navigateur par défaut."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scissors Rock Paper est maintenant parfaitement jouable."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez plus vous allonger maintenant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les 20 minutes, la carte est modifiée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La taille de la carte s'adapte désormais au nombre de joueurs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec un killstreak élevé, vous obtenez maintenant une petite récompense."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le Lobby et le GunGame, vous pouvez maintenant changer le serveur avec F12."])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.09.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans l'eau, l'endurance a été ajustée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous vous noyez maintenant si vous avez trop peu d'endurance dans l'eau."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les personnes de plus de 65 ans reçoivent désormais leur pension acquise au lieu des allocations de chômage."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de duplication des véhicules"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.09.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les fleurs des mauvaises herbes peuvent également être récoltées en dessous."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant vous dé-vérifier via le lobby avec /unlink"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour de la version du serveur (possibilité de petites améliorations des performances)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.09.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug dans les transactions bancaires des PNJ"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un poste de chauffeur de bus dans une compagnie de taxis"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les portes manquantes de Neubaustr. ont été ajoutées."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de la duplication automatique"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le point de respawn dans l'hôpital a été corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug des menottes et des attaches zip lors du respawning."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a de nouvelles propriétés à louer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'hôpital a été reconstruit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système de portes pour les entreprises a été révisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martin Geiss et la réception de Medic se trouvent dans un nouvel endroit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacement du point de sortie du parking de l'hôpital et du garage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise en place d'un ordinateur portable à l'hôpital"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les voitures trop chargées peuvent désormais être garées."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise en œuvre de la réception bancaire NPC"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les messages et appels du téléphone portable ne s'affichent pas bug corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordinateur de la police a été rendu fonctionnel dans la salle du centre de contrôle"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de la prison"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une barre d'endurance (évite les sauts de puce)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'application News sur téléphone portable est désormais disponible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande de nouvelles supprimée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le message de la police des mauvaises herbes a été déplacé en haut à gauche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La récolte des mauvaises herbes est désormais plus lucrative après la présence de policiers en service"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugin Teamspeak"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La version 1.0.22 a été publiée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le crash après ou pendant le roleplay devrait être corrigé."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les effets de voix tels que (téléphone, radio, mur) sont soigneusement retirés après le RP."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a maintenant une recherche dans le téléphone pour les contacts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant voir si les contacts sont disponibles ou non."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le propre numéro peut être copié en un seul clic"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les numéros inconnus peuvent être enregistrés en un seul clic"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléphone portable ne peut plus être ouvert par un clic gauche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La réception d'un message est désormais signalée par une tonalité de notification."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant entendre un bip lorsque vous appelez quelqu'un."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cas d'échec d'un appel, de fin d'appel, d'impossibilité d'envoyer un message, etc., un son d'erreur est émis."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les contacts peuvent désormais être marqués comme favoris en les modifiant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une note peut être ajoutée à un contact"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision du système Revive"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de l'inconscience (réanimation impossible, effet de cécité permanent)."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les personnes inconscientes ont maintenant des vêtements et la bonne peau."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous êtes inconscient, vous laissez tomber votre objet de votre main."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais vous asseoir n'importe où avec une animation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant voir les étoiles (police en service) également dans le coin inférieur gauche."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction des SMS à caractères croisés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevez la notification par SMS uniquement si vous avez un téléphone portable sur vous."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une erreur de navigation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision du système de dispatching"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le vol dure maintenant plus ou moins longtemps selon l'endroit."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La banque peut maintenant être volée à partir de 5 étoiles."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La grande mise à jour du téléphone portable"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles règles concernant le téléphone portable"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug de PNJ"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction des bugs des PNJ"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances côté client"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction des bugs des PNJ"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les noms des PNJ sont désormais systématiquement affichés sous la forme \"ID : 0\"."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Station-service : erreur de détection de voiture corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande /log améliorée (temps de recharge plus court, plus performante et conception plus claire)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nouvelles de l'affaire Medic ont progressé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système de PNJ entièrement révisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque PNJ a maintenant une peau générée ainsi que des vêtements."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais dévaliser les magasins avec des poings américains, des couteaux et la nouvelle batte de baseball."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de l'erreur de contournement du quiz"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le glacier de la plage a ouvert ses portes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du message d'erreur de poche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après vérification, Teamspeak n'a pas besoin d'être reconnecté."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug du portefeuille"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du refroidissement lors de l'ouverture des sacs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des sacs de bugs de dupe mis à l'arrêt (Arrêtez d'en trouver de nouveaux Jan !)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Petit temps de refroidissement lors de l'ouverture des sacs et des sacs à dos."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bière remplit désormais le bar de la soif"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La mallette médicale et la boîte de réparation sont devenues des modèles 3D"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["réparation de la durabilité du bâton de chevalier"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une limite d'articles sur le sol"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refroidissement lors de la pose et de la dépose des menottes."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message personnalisé \"Pas de place pour les menottes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles de l'affaire Medic personnalisées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beaucoup de nouveaux vêtements dans le magasin de vêtements"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin d'armes vend maintenant des poings américains."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cookies de la boulangerie peuvent maintenant être achetés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais acheter de la bière au supermarché"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les guichets automatiques ne peuvent plus être utilisés que par une seule personne à la fois."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vous tenir assez près d'un distributeur automatique de billets pour l'utiliser."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les arbres peuvent à nouveau être abattus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le centre de contrôle des mécaniciens peut être rappelé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refroidissement du remorquage de la voiture ajouté"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extension de la distance pour le menu furtif"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin de hamburgers peut maintenant être cambriolé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les stations de contrôle, l'état est maintenant également affiché"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les scooters ont été rendus plus rapides (stationnement et désengagement)."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug dans le menu \"sneak\"."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur inconsciente corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les joueurs bannis ne sont plus déplacés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant voir son interdiction dans le lobby déjà"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les voitures étaient garées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'abattage des arbres est temporairement désactivé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles améliorations des performances"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande /playtime a été ajoutée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande /support a été ajoutée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les armes sont chargées dans l'inventaire"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les armes sont chargées dans le bon mode de tir."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les 20 minutes, la carte est modifiée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La sélection des armes a été révisée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de bogues corrigés"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La durée de lecture est enregistrée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFA a été retiré du réseau"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hall d'entrée"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insertion d'une requête sur la déclaration de confidentialité"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision du système d'accueil"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les gadgets peuvent être achetés avec des pièces"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant voter pour notre serveur tous les jours pour soutenir le serveur et obtenir une récompense."])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les voitures garées dans le garage se rechargent beaucoup plus vite."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles améliorations des performances"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les animations peuvent maintenant être utilisées sur chaque sous-serveur ainsi que sur le lobby."])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chez le transformateur de bois, le prix correct est maintenant affiché dans la vente."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'abattage d'un arbre prend désormais 20 secondes au lieu de 30."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les employés figurant dans la liste des ordinateurs ne sont désormais affichés en service que lorsqu'ils sont en ligne."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La chemise de mécanicien dans l'ordinateur officiel a été retirée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des employés et les transactions dans l'ordinateur se chargent beaucoup plus rapidement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions aux guichets automatiques s'affichent plus rapidement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelles améliorations des performances"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les fonctionnaires peuvent désormais envoyer des messages d'information"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lors de la transformation du bois, (4 -> 2) est maintenant utilisé au lieu de (5 -> 2)."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'animation est réinitialisée lors du changement de caractères"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La peau est fixée après le respawning à l'hôpital."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug du sac à dos dans le sac"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medic Messages d'erreur déplacés vers le haut"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du message de salaire pour les emplois agricoles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les fonctionnaires peuvent désormais envoyer des messages d'information"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les armes de l'armurerie ne peuvent plus être brisées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En tant que spectateur, vous pouvez maintenant voir les bonnes peaux."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après le respawning, vous avez maintenant le bon skin."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement des messages placés en haut à gauche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de la migration de la peau en tant que sous-vêtements de femme non visibles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de l'espacement des objets dans l'inventaire"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug des éléments du menu d'interaction"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision du système radio & radio pour l'aéroport inséré"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de l'attache de câble non affichée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les factures fonctionnent à nouveau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nouvelles bénéficient désormais d'une aide de l'État"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice et actualités peuvent désormais émettre des factures"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions de justice et de nouvelles sont désormais affichées correctement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le pouvoir judiciaire peut désormais embaucher des personnes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radios pour mécaniciens et chauffeurs de taxi insérés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de messages de fin de contrat de travail"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les voitures étaient garées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La règle de la mort par RP a été modifiée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a de nouvelles propriétés qui peuvent être louées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction possible du bug de l'apparition d'un étage trop haut."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double payday & règlement immobilier fixé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression du son lors de la recherche de poubelles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les éléments de la recherche ne peuvent plus être cachés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rien ne peut être placé dans le champ d'artisanat maintenant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On ne peut attacher les gens et les relâcher que si on a les menottes ou les serre-câbles en main."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nouvelles armes ont été ajoutées à la boutique d'armes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La police a maintenant des matraques"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'assortiment du magasin de vêtements a été élargi."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le magasin d'électroménager propose désormais plus de produits"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur Teamspeak, les identifiants des joueurs figurent désormais dans la description de l'utilisateur."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de certains bogues concernant les affichages en haut à gauche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billy Cooper est rentré dans le pays (trafiquant de drogue)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le travail de bûcheron est maintenant mieux payé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau système de personnages"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant changer de personnage avec la commande '/character' ou via F12 sans quitter le serveur."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision du système cutané"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les personnages devraient maintenant se charger plus rapidement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque nouveau personnage doit maintenant passer l'immigration à l'aéroport international des îles unies."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nouveaux personnages ont désormais automatiquement une carte d'identité"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dernier jour de jeu d'un personnage est désormais sauvegardé et affiché dans la sélection des personnages."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de nouveaux objets RP comme des béquilles, des seringues et bien d'autres encore."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une faute d'orthographe"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression des messages de débogage lors du clic sur les armoires"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug qui permettait de placer des objets dans la zone verrouillée de l'inventaire"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La révision du compte du serrurier a été corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du serveur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.05.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le plugin vocal a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pendant un appel téléphonique, l'interlocuteur a maintenant un effet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les personnes qui se trouvent derrière un mur peuvent désormais être entendues de manière étouffée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le cas de la radio, il existe désormais un effet audio qui donne l'impression que l'interlocuteur parle réellement dans une radio."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bug qui fait que les gens ne sont pas mis en sourdine devrait être corrigé."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La carte a été mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nombreuses nouvelles propriétés à louer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les voitures étaient garées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacement du parking de la police à l'extérieur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'ordinateur de la police a été déplacé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout de sons et d'une barre d'action dans les itinéraires agricoles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les poubelles peuvent maintenant être recherchées avec Shift + clic droit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les bouteilles consignées peuvent désormais être remises au supermarché"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le poste de bûcheron et la licence ont été insérés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision du système d'octroi de licences"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventaire de trésorerie bloqué"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les appels et la radio sont encore plus forts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le pouvoir judiciaire peut désormais embaucher des employés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du serveur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12.04.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout du pack de textures StateMC"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des motos ont été ajoutées au modpack"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout du module Dynamic Surroundings"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelques petites corrections de bugs dans le Roleplay Mod"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["répercuter le prix des papiers de la voiture réparée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision du système de sacs et de sacs à dos"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La justice et le palais de justice sont maintenant officiellement enregistrés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le centre de presse et le pouvoir judiciaire peuvent désormais envoyer des nouvelles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mécaniciens ont maintenant la possibilité d'acquérir une camionnette de service"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les lieux de stockage dans les maisons sont maintenant fermés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez les clés d'une maison ou si une porte est déverrouillée, vous pouvez accéder à tous les coffres."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.04.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour de la grande carte"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De nombreuses nouvelles propriétés à louer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le chef de la police peut désormais embaucher et licencier des gens"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un bug de dupe concernant les sacs à dos a été corrigé."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le service clé a été mis en service"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les vols dans les stations-service, il faut à nouveau 2 étoiles dans l'onglet."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise en service de la station-service de la nouvelle aire de service"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cibles du stand de tir sont de retour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.04.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande retirée : /w"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction des bugs de duplication des sacs à dos"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sacs à dos et les sacs endommagés peuvent maintenant être retirés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On a maintenant soif à nouveau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une erreur de salaire"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de véhicules : correction d'un bug où les conducteurs de véhicules étaient invisibles."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de véhicules : correction d'un problème d'affichage des occupants sur un mauvais siège."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modélisation des véhicules : les occupants des véhicules ne restent plus coincés au milieu de la route."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mod véhicule : Après être sorti d'un véhicule, vous ne devriez plus être invisible."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les salaires de l'Etat ont été ajustés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug avec le chargement de la voiture"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les plaques d'immatriculation des véhicules d'État sont passées à trois chiffres."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le logo dans la liste des serveurs a été ajouté à nouveau"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certaines portes de la cour des mécaniciens ont été ajoutées."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portes insérées sur le site militaire"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les voitures ont été garées et sont maintenant dans le garage de la ville."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelques nouvelles propriétés ont été ajoutées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un camp de police"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'acheteur de fer (site de construction) a été déplacé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous avez le cadre d'écran rouge, vous pouvez maintenant toujours voir les barres de progression en haut."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a maintenant quelques animations de joueur que vous pouvez effectuer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quand vous êtes attaché, vous avez aussi visuellement les mains derrière le dos."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les messages relatifs au tethering sont désormais également affichés dans le coin supérieur gauche."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une faute d'orthographe dans l'onglet"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casiers/serrures insérés dans un magasin de mécanique"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque l'on se réveille à l'hôpital, la règle de la nouvelle vie nous est à nouveau rappelée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les champignons peuvent désormais être collectés et vendus au supermarché."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de l'erreur de licence du bureau du travail"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a maintenant un questionnaire que tout le monde doit remplir à nouveau pour pouvoir jouer sur le serveur."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant rouler et consommer des joints"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après l'hôpital, vous êtes maintenant dans le mode \"aventure\"."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Après une réanimation ou un réveil à l'hôpital, vous n'avez plus toute votre vie et vous devez encore être soigné."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans la prison, toutes les portes sont maintenant stockées. Cela signifie que la police peut désormais enfermer les gens directement dans les cellules."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La liste des tables indique maintenant si vous êtes autorisé à voler des gens ou à prendre des otages."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes maintenant listé dans l'onglet avec l'ID du joueur pour trouver plus rapidement les ratés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quelques corrections de bogues concernant les Petites Tuiles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bordure d'écran rouge lorsque vous avez subi de nombreux dégâts ne devrait plus provoquer de décalages et utiliser moins de puissance CPU."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions des magasins sont maintenant listées correctement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverses barres de chargement fixées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le traitement du fer prend maintenant 3 secondes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couper une fleur de cannabis prend maintenant 2 secondes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les pneus ne peuvent plus être crevés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les étoiles de la liste du tableau ont de nouvelles exigences"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes maintenant toujours en mode aventure pour corriger quelques bugs."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.02.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'équipe est désormais avertie sur TeamSpeak lorsque quelqu'un attend dans l'assistance."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système de navigation a été revu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un message de test lors d'un transfert d'argent a été supprimé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions dans les ordinateurs professionnels s'affichent désormais correctement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu peux maintenant faire en sorte que ton personnage n'ait pas moins de 18 ans."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau menu immobilier"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est maintenant possible de transmettre des clés pour les propriétés"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un numéro de rue et de maison est désormais attribué à chaque propriété."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les menus ne peuvent être fermés qu'avec ESC et le X dans le coin supérieur droit, et plus avec E."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais partager des contacts de téléphone portable uniquement 1x"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous entendez maintenant un battement de cœur alors que vous avez peu de vie."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De plus, vous avez un cadre d'écran rouge lorsque vous avez peu de vie."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La barre de progression a un nouveau design"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'île principale a été baptisée <<Central Bay>>."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les interfaces graphiques ne peuvent plus être fermées avec E"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord Rich Presence a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Révision de la conception du HUD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du serveur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'immobilier est à nouveau réglé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le calcul du cube a été optimisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez désormais remplir des tasses et des bouteilles d'eau aux éviers"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug avec les portefeuilles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord RPC Update (Roleplay, build server, etc.)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les gens ne sont plus invisibles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du serveur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous entrez dans le serveur, vous êtes maintenant toujours en langue normale."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bug de duplication des ATM a été corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système de stockage a été revu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug lors de la culture du fer et de l'herbe"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réduction des décalages côté client"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les maisons disparues dans le ghetto peuvent désormais être louées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du serveur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nouvelles des factions sont désormais affichées plus longtemps"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les interfaces graphiques des magasins ont été révisées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les magasins ont maintenant un choix beaucoup plus large à offrir."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est maintenant affiché dans le HUD si vous êtes en sourdine sur TeamSpeak."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'erreur d'affichage du rayon vocal lors de la deuxième jonction a été corrigée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les messages du véhicule sont désormais également affichés dans le coin supérieur gauche."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous allez en prison, tous les objets sont maintenant supprimés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les gens peuvent maintenant être mis en prison dans un plus grand rayon."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si tu t'évanouis en prison, tu te réveilles là-bas aussi."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsqu'on le tase, l'effet de lenteur demeure maintenant."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous êtes attaché, vous ne pouvez plus : déposer des objets, ramasser des objets, changer d'emplacement et déplacer des objets."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a maintenant des portefeuilles dans le magasin de vêtements dans lesquels on peut stocker de l'argent."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant acheter des cubes de travail"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il y a maintenant un téléphone de centre de contrôle pour les prisonniers"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les objets ne peuvent plus être ramassés que furtivement."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le volume sonore de la radio a été réduit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du serveur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TeamSpeak-Plugin"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le plugin Voice a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les gens sont automatiquement découragés sur TeamSpeak lorsqu'ils viennent d'un canal en jeu."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le volume des personnes est maintenant automatiquement réinitialisé sur TeamSpeak"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des complications dans le plugin vocal ont été corrigées"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le robot Discord a de nouveaux émojis \"J'aime\" et \"J'aime pas\"."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe désormais un canal d'état pour les idées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez voir combien de personnes ont aimé ou détesté l'idée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog Command a reçu une mise à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BauServer"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il existe un nouveau système de serveur de construction"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le vol d'entreprise n'est plus possible en tant que policier"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porte de police ajoutée à l'arrière"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une faute d'orthographe"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amélioration des performances du serveur"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez désormais plus soif lorsque vous entrez dans un nouveau pays"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez à nouveau transmettre et retirer les clés des voitures sans aucun problème."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations sur le serveur sont désormais affichées dans le coin supérieur gauche."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les nouvelles des factions sont désormais affichées dans le coin supérieur gauche."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une erreur de navigation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nouveau HUD du joueur a été introduit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'interface graphique des stations-service a été insérée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'interface graphique s'ouvre maintenant beaucoup plus rapidement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un pistolet radar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les vitesses des voitures ont été ajustées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez plus sortir des voitures en mouvement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez plus monter dans les voitures en mouvement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les pneus ne peuvent plus être écrasés à la main."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les voitures de police peuvent désormais être verrouillées de manière à ce que vous ne puissiez plus en sortir."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les voitures ralentissent désormais automatiquement lorsque plus aucun conducteur n'accélère"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le régulateur de vitesse a été introduit avec la touche [*] du pavé numérique."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'est plus possible de faire un zoom arrière infini en étant assis dans la voiture."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les sirènes sont désormais entendues par tous (correction d'un bug)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le clignotement automatique a été supprimé ; il fonctionne désormais avec les flèches gauche et droite du Numpad."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billy Cooper s'est enfui."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rashid a acheté un vieux bus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La plupart des messages sont désormais affichés dans le coin supérieur gauche."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le système de soif a été entièrement revu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cœurs, la barre de faim et la barre de niveau ont été supprimés (vous n'êtes pas en mode créatif !)."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le HUD du véhicule par défaut a été supprimé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les voitures étaient garées dans le garage de la ville"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mode graphique a été remis par défaut à nice"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sur l'autoroute, il est désormais permis de rouler à 100 KM/H."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque vous changez le mode vocal pour la première fois, vous vous trouvez directement sur le cri."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les erreurs de carte ont été corrigées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bug du menu véhicule a été corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug du Smartphone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le banc d'outils ne peut plus être utilisé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaque rue a désormais son propre nom"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les maisons ont maintenant toutes un numéro de maison"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les plaques de rue ne peuvent pas être modifiées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les idées peuvent être écrites directement dans le canal Idées. Il en va de même pour les erreurs."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La neige a été enlevée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le menu de l'interface véhicule a été revu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les voitures étaient garées dans le garage de la ville"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le discord s'affiche désormais lorsque vous jouez un rôle."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack a été mis à jour"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les erreurs de carte ont été corrigées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bug de la vente de fer a été corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bug de la pile 63 a été corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone disponible 2. erreur de char corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'appel est également annulé quand le téléphone n'est pas en main et que vous appelez"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug des doublons de recherche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le retrait de la carte d'identité de l'inventaire a été corrigé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les contacts de téléphone portable sont désormais triés par statut"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverture d'un magasin de kebab aux États-Unis."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boire maintenant étanche beaucoup plus la soif"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant entendre si une voiture est déverrouillée ou verrouillée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seule la moitié de la vie peut être soustraite par la soif."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prix de l'acier a augmenté de façon spectaculaire (150 euros pour 64 barres)."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans le bureau de l'emploi, il y a maintenant des \"frais de traitement\" parce qu'il y a eu un malentendu."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les vols dans les stations-service et les magasins de beignets rapportent désormais plus d'argent."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La station d'essence à l'extérieur de la ville peut maintenant aussi être volée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les portes ne peuvent plus être ouvertes à travers un bug"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cloches ont un nouveau son"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une faute d'orthographe (ramasser une boule de neige)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le pull de Noël est maintenant disponible dans le magasin"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le panneau de verre d'un magasin de vêtements ne peut plus être retiré"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La salle des preuves de la police ne peut désormais être ouverte que par les chefs de police"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le modpack a été mis à jour pour apporter de nouveaux objets et sons dans le jeu."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de carte corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug du multi-char des menottes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une faute d'orthographe"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les policiers ne peuvent plus récolter ou vendre de l'herbe"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de carte corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombreuses nouvelles commandes de robots Discord"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le robot Discord vous écrit désormais en privé lorsque votre bug est corrigé."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de l'Auto-Crash"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de carte corrigée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corrections de bogues"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dans les stations-service, on peut désormais acheter des roues et des kits de réparation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les joueurs normaux peuvent utiliser les roues et les kits de réparation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bogue dans l'interface utilisateur du Smartphone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toutes les voitures à l'arrêt étaient garées dans le garage de la ville."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il a dû neiger aux Îles-Unies."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez ramasser la neige sur le sol"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il peut maintenant neiger entre"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La prison a été mise en service"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une maison dans le ghetto a été libérée pour être louée."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'un bug de recherche"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous obtenez en vendant de l'herbe les 250 euros"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portes de la salle d'interrogatoire ajoutées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La porte du quartier riche a été réparée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le bâtiment volant sur la mer a été supprimé"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les performances ont été améliorées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La déclaration de paiement pour les armes achetées a été supprimée"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'insigne ne peut plus être retiré de l'inventaire"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les portes de l'hôpital ont été réparées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les performances ont été améliorées"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous obtenez 1 à 3 fleurs à partir d'un seul plant de cannabis maintenant."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkstop Sound-Fix"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prix d'Apple a été corrigé de la baisse"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le téléphone portable et la radio ne peuvent plus être utilisés lorsqu'ils sont attachés."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant entendre quand les autres commencent ou arrêtent de faire des étincelles."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorsque la radio est rangée, vous n'êtes automatiquement plus dans le canal radio."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer automatiquement un canal radio"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du Voice-Bug 2.0"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour un tas de pommes, vous recevez maintenant 20 euros au lieu de 10 euros."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour une pile d'acier, vous obtenez maintenant 60 euros au lieu de 20 euros."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration de prix de porte d'appartement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction du bug de la voix"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction d'une erreur d'orthographe dans le magasin"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correction de plus d'erreurs système"])}
          ]
        }
      ]
    }
  ]
}