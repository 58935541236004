<template>
  <div>
    <div v-if="!drawer" class="activator" @click="drawer = true">
      TeamPanel
    </div>
    <v-navigation-drawer
        v-model="drawer"
        temporary
    >
      <v-divider></v-divider>
      <v-list density="compact" nav>
        <v-list-item>TeamPanel</v-list-item>
        <div v-if="permissions">
          <v-list-item
              v-if="permissions.includes('acp.players.view')"
              to="/admin/players"
              prepend-icon="mdi-account-group"
              title="Spieler"
              value="players"
          ></v-list-item>
          <v-list-item
              v-if="hasOneLanguagePermission('acp.character.rp.XX.list')"
              to="/admin/characters"
              prepend-icon="mdi-account-group"
              title="Charakter"
              value="characters"
          ></v-list-item>
          <v-list-item
              v-if="hasOneLanguagePermission('acp.XX.chats')"
              to="/admin/chats"
              prepend-icon="mdi-forum"
              title="Anfragen"
              value="chats"
          ></v-list-item>
          <v-list-item
              v-if="hasOneLanguagePermission('acp.tools.rp.XX.charrename')"
              to="/admin/tools/charrename"
              prepend-icon="mdi-account-edit"
              title="RenameChar"
              value="renamechar"
          ></v-list-item>
          <v-list-item
              v-if="hasOneLanguagePermission('acp.tools.rp.XX.chardelete')"
              to="/admin/tools/chardelete"
              prepend-icon="mdi-delete"
              title="DeleteChar"
              value="deletechar"
          ></v-list-item>
          <v-list-item
              v-if="hasOneLanguagePermission('acp.tools.rp.XX.transfermoney')"
              to="/admin/tools/transfermoney"
              prepend-icon="mdi-cash-multiple"
              title="TransferMoney"
              value="transfermoney"
          ></v-list-item>
          <v-list-item
              v-if="hasOneLanguagePermission('acp.messages.rp.XX.view')"
              to="/admin/messages"
              prepend-icon="mdi-forum"
              title="Messages"
              value="messages"
          ></v-list-item>
          <v-list-item
              v-if="hasOneLanguagePermission('acp.jobs.rp.XX.view')"
              to="/admin/characterjobs"
              prepend-icon="mdi-arm-flex"
              title="JobQuery"
              value="jobquery"
          ></v-list-item>
          <v-list-item
              v-if="permissions.includes('acp.player.migrate')"
              to="/admin/tools/migrate"
              prepend-icon="mdi-arrow-left-right"
              title="Migrate"
              value="migrate"
          ></v-list-item>
          <v-list-item
              v-if="permissions.includes('network.permissions.edit')"
              to="/admin/permissions"
              prepend-icon="mdi-security"
              title="Permissions"
              value="permissions"
          ></v-list-item>
          <v-list-item
              v-if="permissions.includes('acp.downloads.edit')"
              to="/admin/downloads"
              prepend-icon="mdi-download"
              title="Downloads"
              value="downloads"
          ></v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { Utils } from '@/modules/utils';

const drawer = ref(false);

const requestPermissions = [
  'acp.players.view',
  'acp.character.rp.de.list',
  'acp.downloads.edit',
  'acp.messages.rp.de.view',
  'acp.jobs.rp.de.view',
  'acp.tools.rp.de.charrename',
  'acp.tools.rp.de.chardelete',
  'acp.tools.rp.de.transfermoney',
  'acp.de.chats',
  'network.permissions.edit',
  'acp.player.migrate',
];

const { result } = useQuery(gql`
query permissions ($permissions: [String]) {
  permissions(permissions: $permissions)
}
`, { permissions: Utils.getMultiLanguagePermissions(requestPermissions) });

const permissions = computed(() => result.value?.permissions ?? []);

const hasOneLanguagePermission = (permission) => {
  const inPermissions = Utils.getMultiLanguagePermissions([permission]);
  return inPermissions.some((p) => permissions.value.includes(p));
};

</script>

<style scoped>
.activator {
  position: fixed;
  z-index: 100;
  top: 40%;
  left: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 100px;
  transform: rotate(-90deg);
  transform-origin: 0 100%;
  background: rgb(var(--v-theme-dark-background));
  box-shadow: -1px 1px 5px black;
  color: white;
  cursor: pointer;
}
</style>
