export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiki"])},
  "pages": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key assignment"])},
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keyboard"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-keyboard"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commands"])},
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commands"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-slash-forward"])}
    }
  ],
  "keys": {
    "mouse-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Right click"])}
  },
  "keyboard": {
    "shortcuts": [
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mouse-right"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaction with players, vehicles and doors"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F12"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change character"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Starting the engine"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remotely open and close the vehicle"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload weapon"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weapon Remove magazine"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch weapon fire mode"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lie down"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMPAD 1-9"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animations like waving, hands out and hands up"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open animation wheel"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjust voice volume"])}
      }
    ]
  },
  "commands": {
    "/l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming back to the lobby"])},
    "/unverify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De-verify your Teamspeak identity"])},
    "/log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a log in the RP to report other players"])},
    "/account-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link your website account to your Minecraft account"])},
    "/discord-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link your Discord account to your Minecraft account"])},
    "/discord-unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect from your Discord account"])},
    "/id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shows you your own network ID"])},
    "/playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To check your playing time"])},
    "/vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With this you can vote for our server to get a reward"])}
  }
}