const Keyboard = () => import('@/views/wiki/Keyboard');
const Commands = () => import('@/views/wiki/Commands');

const routes = [
  {
    path: 'keyboard',
    name: 'Keyboard',
    component: Keyboard,
  },
  {
    path: 'commands',
    name: 'Commands',
    component: Commands,
  },
];

export default routes;
