import jwtDecode from 'jwt-decode';
import router from '@/router';

const state = {
  token: undefined,
  tokenClientIat: undefined,
  mailConfirmation: {
    email: undefined,
    new: false,
  },
};

const getters = {
  token: ({ token }) => token,
  mailConfirmation: ({ mailConfirmation }) => mailConfirmation,
  isLoggedIn: (state) => {
    return state.token !== undefined;
  },
  tokenPayLoad: (state) => {
    if (state.token) {
      return jwtDecode(state.token);
    }
  },
  isTokenValid: (state, getters) => () => {
    const payload = getters.tokenPayLoad;
    if (payload) {
      const maxAge = payload.exp - payload.iat;
      const currentTime = new Date().getTime() / 1000;
      const age = currentTime - state.tokenClientIat;
      if (age >= maxAge) {
        return false;
      } else {
        return true;
      }
    }
  },
  userID: (state, getters) => {
    const payload = getters.tokenPayLoad;
    if (payload) {
      return Number.parseInt(payload.upn);
    }
  },
  userGroup: (state, getters) => {
    const payload = getters.tokenPayLoad;
    if (payload) {
      return payload.groups[0];
    }
  },
  playerID: (state, getters) => {
    const payload = getters.tokenPayLoad;
    if (payload) {
      return payload.playerId;
    }
  },
  playerName: (state, getters) => {
    const payload = getters.tokenPayLoad;
    if (payload) {
      return payload.playerName;
    }
  },
  playerUUID: (state, getters) => {
    const payload = getters.tokenPayLoad;
    if (payload) {
      return payload.playerUUID;
    }
  },
  isTeamMember: (state, getters) => {
    const payload = getters.tokenPayLoad;
    if (payload) {
      return payload.isTeamMember;
    }
  },
};

const actions = {

};

const mutations = {
  setToken (state, token) {
    state.token = token;
    if (token) {
      state.tokenClientIat = new Date().getTime() / 1000;
    }
  },
  logout (state, reload = true) {
    if (reload) {
      router.go();
    }
    state.token = undefined;
  },
  setMailConfirmation (state, mailConfirmation) {
    state.mailConfirmation = mailConfirmation;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
