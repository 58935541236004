export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiki"])},
  "pages": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tastenbelegung"])},
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keyboard"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-keyboard"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Befehle"])},
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commands"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-slash-forward"])}
    }
  ],
  "keys": {
    "mouse-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtsklick"])}
  },
  "keyboard": {
    "shortcuts": [
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mouse-right"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaktion mit Spielern, Fahrzeugen und Türen"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F12"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charakter wechseln"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motor starten"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fahrzeug aus der Ferne auf- und zuschliessen"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffe nachladen"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffe Magazin entnehmen"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waffe Feuermodus wechseln"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinlegen"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMPAD 1-9"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animationen wie Winken, Hände ausstrecken und Hände hoch"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animationsrad öffnen"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprachlautstärke anpassen"])}
      }
    ]
  },
  "commands": {
    "/l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um zurück zur Lobby zu kommen"])},
    "/unverify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entverifiziere deine Teamspeak Identität"])},
    "/log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelle im RP einen Log um andere Spieler zu melden"])},
    "/account-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlinke deinen Website Account mit deinem Minecraft Account"])},
    "/discord-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlinke deinen Discord Account mit deinem Minecraft Account"])},
    "/discord-unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löse die Verbindung von deinem Discord Account auf"])},
    "/id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeigt dir deine eigene Netzwerk ID"])},
    "/playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um deine Spielzeit zu prüfen"])},
    "/vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Damit kannst du für unseren Server voten um eine Belohnung zu bekommen"])}
  }
}