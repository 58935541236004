import i18n from '@/i18n';
import statsEvents from '@/static/icons/statsEvents.json';
import moment from 'moment';

export class Utils {
  static getImgUrl (img) {
    try {
      return require('../assets/img/' + img);
    } catch (e) {
      return undefined;
    }
  }

  static getBannerImages () {
    const imageFiles = require.context('../assets/img/banners', false, /\.(webp)$/);
    const images = [];
    imageFiles.keys().forEach(key => {
      images.push(imageFiles(key));
    });
    return images;
  }

  static getRoleplayImages () {
    const imageFiles = require.context('../assets/img/games/rp', false, /\.(png|jpg|jpeg|gif|svg)$/);
    const images = [];
    imageFiles.keys().forEach(key => {
      images.push(imageFiles(key));
    });
    return images;
  }

  static getTTTImages () {
    const imageFiles = require.context('../assets/img/games/ttt', false, /\.(png|jpg|jpeg|gif|svg)$/);
    const images = [];
    imageFiles.keys().forEach(key => {
      images.push(imageFiles(key));
    });
    return images;
  }

  static getGungameImages () {
    const imageFiles = require.context('../assets/img/games/gungame', false, /\.(png|jpg|jpeg|gif|svg)$/);
    const images = [];
    imageFiles.keys().forEach(key => {
      images.push(imageFiles(key));
    });
    return images;
  }

  static getMultiLanguagePermissions (perms, singleLanguage = false, sgLang = 'de') {
    const newPerms = [];
    if (singleLanguage) {
      for (const perm of perms) {
        newPerms.push(perm.replace(/\.(de|fr|XX|freeroam)/g, '.' + sgLang));
      }
    } else {
      for (const perm of perms) {
        newPerms.push(perm.replace(/\.(de|fr|XX|freeroam)/g, '.de'));
        newPerms.push(perm.replace(/\.(de|fr|XX|freeroam)/g, '.fr'));
        newPerms.push(perm.replace(/\.(de|fr|XX|freeroam)/g, '.freeroam'));
      }
    }
    return newPerms;
  }

  static getMultiLanguagePerm (perm, sgLang) {
    return perm.replace(/\.(de|fr|XX|freeroam)/g, '.' + sgLang);
  }

  static removeLanguageFromRoute (route) {
    return route.replace(/\/(de|freeroam|fr)/, '');
  }

  static paginate (array, pageSize, pageNumber) {
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }

  static roundN (value, digits) {
    const tenToN = 10 ** digits;
    return (Math.round(value * tenToN)) / tenToN;
  }

  static formatDate (date) {
    moment.locale(i18n.global.locale);
    return moment(date).format('L');
  }

  static formatDateTime (dateTime) {
    moment.locale(i18n.global.locale);
    return moment(dateTime).format('l LT');
  }

  static getLocaleMoment (dateTime) {
    moment.locale(i18n.global.locale);
    return moment(dateTime);
  }

  static formatRelativeTime (dateTime) {
    moment.locale(i18n.global.locale);
    return moment(dateTime).fromNow();
  }

  static formatMinutes (minutes) {
    const out = {};
    if (minutes > 60 * 24) {
      out.value = minutes / (60 * 24);
      out.unit = 'DAYS';
    } else if (minutes > 60) {
      out.value = minutes / 60;
      out.unit = 'HOURS';
    } else {
      out.value = minutes;
      out.unit = 'MINUTES';
    }

    out.value = this.roundN(out.value, 2);
    return out;
  }

  static getStatsEventIcon (event) {
    return statsEvents[event];
  }

  static getBackendUrl () {
    return process.env.VUE_APP_BACKEND_URL;
  }

  static getDownloadFileUrl (fileName) {
    return '/download/' + fileName;
  }

  static getGameModeImage (gameMode) {
    let game = gameMode.toLowerCase();
    if (game === 'freeroam') game = 'roleplay';
    return this.getImgUrl(`symbols/${game}.png`);
  }

  static getSmallGameModeImage (gameMode) {
    let game = gameMode.toLowerCase();
    if (game === 'freeroam') game = 'roleplay';
    return this.getImgUrl(`symbols/x50/${game}.png`);
  }

  static getMCAvatar (uuid, size = 64) {
    if (!uuid) {
      return this.getImgUrl('skin/steve.png');
    }
    // return `https://crafatar.com/avatars/${uuid}?size=${size}`;
    return `https://mc-heads.net/avatar/${uuid}/${size}`;
  }

  static getMCSkin (uuid) {
    // return `https://crafatar.com/skins/${uuid}`;
    return `https://mc-heads.net/download/${uuid}`;
  }

  static validationRequired (v) {
    return !!v || i18n.global.t('validation.required');
  }

  static validationLength8000 (v) {
    return v.length <= 8000 || i18n.global.t('validation.toLong');
  }

  static validationAccepted (v) {
    return !!v || i18n.global.t('validation.accepted');
  }

  static validationEmail (email) {
    const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email) || i18n.global.t('validation.invalid-email');
  }

  static validationPasswordMin (password) {
    return password.length >= 8 || i18n.global.t('validation.password-min');
  }

  static validationPasswordLetters (password) {
    const regex = /(?=.*[A-Z])(?=.*[a-z])/;
    return regex.test(password) || i18n.global.t('validation.password-upper-and-lower');
  }

  static validationPassword (password) {
    const passwordRegex = /(?=^.{8,}$)(?=.*\d)(?=.*[@$!%*#?&123456789]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    return passwordRegex.test(password) || i18n.global.t('validation.invalid-password');
  }

  static validationConfirmCode (code) {
    return code.length === 5 || i18n.global.t('validation.code-length');
  }

  static validationPasswordResetCode (code) {
    return code.length === 16 || i18n.global.t('validation.code-length');
  }

  static validationNumber (number) {
    return !isNaN(number) || i18n.global.t('validation.number');
  }

  static validationDurationBan (number) {
    return (number > -2 && number <= 5000) || i18n.global.t('validation.duration-ban');
  }

  static validationMoneyAmount (number) {
    return (number > -1 && number <= 50000) || i18n.global.t('validation.number');
  }

  static validationUUID (uuid) {
    return uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/) || i18n.global.t('validation.uuid');
  }

  static checkOnlyWhitespace (str) {
    return str.trim().length === 0;
  }

  static backendTranslation (translation) {
    const { fallbackLanguage, translations } = translation;
    const locale = i18n.global.locale;
    let value = fallbackLanguage.name;

    for (const translation of translations) {
      if (translation.key === locale) {
        value = translation.value;
        break;
      }
    }

    return value;
  }
}

export default {
  install: (Vue) => {
    Vue.mixin({
      data () {
        return {
          utils: Utils,
        };
      },
    });
  },
};
