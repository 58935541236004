<template>
  <v-dialog
      v-if="!cookiesAccepted"
      v-model="cookiesDialog"
      class="dialog"
      vertical
      persistent
  >
    <v-card
      prepend-icon="mdi-cookie"
    >
      <template v-slot:title>
        {{ $t('cookies.title') }}
      </template>

      <v-card-text>
        {{ $t('cookies.text') }}
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn
            color="primary"
            variant="elevated"
            @click="cookiesAccepted = true"
        >
          {{ $t('cookies.accept') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';

const store = useStore();
const cookiesAccepted = computed({
  get () {
    return store.getters.cookiesAccepted;
  },
  set (val) {
    store.commit('setCookiesAccepted', val);
  },
});

const cookiesDialog = computed(() => !cookiesAccepted.value);

</script>

<style scoped>
.dialog {
  width: 100%;
  max-width: 600px;
}
</style>
