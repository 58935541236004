<template>
  <v-app id="v-app">
    <v-app-bar v-if="shouldDefaultComponents"
        color="dark-background"
    >
      <img
          :src="utils.getImgUrl('logo-small.png')"
          class="ml-3 clickable small-image"
          @click="$router.push('/')"
          alt="logo"
      >
      <v-toolbar-title
          class="brand clickable"
          @click="$router.push('/')"
      >
        <span class="color-primary">State</span>MC
      </v-toolbar-title>
      <locale-switcher></locale-switcher>
      <v-app-bar-nav-icon aria-label="Menu" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <team-panel-navigator v-if="isTeamMember" />
    <v-navigation-drawer
        v-model="drawer"
        temporary
        location="right"
    >
      <template v-slot:prepend v-if="isLoggedIn">
        <v-list-item
            lines="two"
            :title="playerName || $t('auth.guest')"
        >
          <template v-slot:prepend>
            <v-avatar
                rounded
                size="56"
                :image="utils.getMCAvatar(playerUUID)"
                @click="$router.push('/account')"
                class="clickable"
            ></v-avatar>
          </template>
          <v-list-item-subtitle style="opacity: 1;">
            <v-btn color="error" @click="logout">Logout</v-btn>
          </v-list-item-subtitle>
        </v-list-item>
      </template>
      <template v-slot:prepend v-else>
        <v-list-item
            lines="one"
        >
          <template v-slot:title>
            <v-btn @click="login" color="primary">Login</v-btn>
          </template>
          <template v-slot:prepend>
            <v-avatar
                rounded
                size="56"
            >
              <v-icon icon="mdi-account" size="40"></v-icon>
            </v-avatar>
          </template>
        </v-list-item>
      </template>

      <v-divider></v-divider>

      <v-list density="compact" nav aria-label="page">
        <v-list-item v-if="isLoggedIn" to="/account" role="option" aria-label="account" prepend-icon="mdi-account" :title="$t('account.title')" value="account"></v-list-item>
<!--        <v-list-item v-if="isLoggedIn" to="/chats" role="option" aria-label="account" prepend-icon="mdi-forum" :title="$tc('chat.title', 1)" value="Chats">-->
<!--          <div class="notification-blob" :style="hasUnreadChats ? 'box-shadow: inset 0 0 10px darkred; background-color: red;' : 'box-shadow: inset 0 0 10px transparent; background-color: transparent;'" />-->
<!--        </v-list-item>-->
        <v-divider class="my-2 mx-4" v-if="isLoggedIn" />
        <v-list-item to="/" role="option" aria-label="home" prepend-icon="mdi-home-city" :title="$t('home.title')" value="home"></v-list-item>
        <v-list-item to="/join" role="option" aria-label="join" prepend-icon="mdi-controller" :title="$t('join.button')" value="join"></v-list-item>
        <v-list-item to="/rules" role="option" aria-label="rules" prepend-icon="mdi-book-open-page-variant-outline" :title="$t('rules.title')" value="rules"></v-list-item>
        <v-list-item to="/shop" role="option" aria-label="shop" prepend-icon="mdi-cart-outline" value="shop"><template v-slot:title>{{$t('shop.title')}} <v-chip size="small" color="yellow">👑</v-chip></template></v-list-item>
        <v-list-item to="/stats" role="option" aria-label="stats" prepend-icon="mdi-podium" :title="$t('stats.title')" value="stats"></v-list-item>
        <v-list-item to="/livestream" role="option" aria-label="livestream" prepend-icon="mdi-video" title="Livestreams" value="livestream"></v-list-item>
        <v-list-item to="/downloads" role="option" aria-label="downloads" prepend-icon="mdi-download" :title="$t('downloads.title')" value="downloads"></v-list-item>
        <v-list-item to="/wiki" role="option" aria-label="wiki" prepend-icon="mdi-wikipedia" :title="$t('wiki.title')" value="wiki"></v-list-item>
        <v-list-item to="/changelog" role="option" aria-label="changelog" prepend-icon="mdi-note-text-outline" :title="$t('changelog.title')" value="changelog"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-card elevation="0" >
      <v-layout>
        <v-main :style="shouldDefaultComponents ? 'margin-top: 64px;' : ''">
          <banner-carousel class="mb-8" v-if="shouldDefaultComponents" ></banner-carousel>
          <router-view></router-view>

          <v-divider class="mt-5 mb-5"></v-divider>

          <v-footer v-if="shouldDefaultComponents" class="mb-5">
            <v-row justify="center" no-gutters>
              <v-btn
                  v-for="(link, i) in links"
                  :key="i"
                  color="primary"
                  variant="text"
                  class="mx-2"
                  rounded="xl"
                  :to="link.url"
              >
                {{ $t(link.text) }}
              </v-btn>
              <v-col class="text-center mt-4" cols="12">
              {{ $t('mojangwarn.title') }}
              </v-col>
              <v-col class="text-center mt-4" cols="12">
                COPYRIGHT © {{ new Date().getFullYear() }} — <strong><span class="color-primary">State</span>MC</strong>
              </v-col>
            </v-row>
          </v-footer>
          <error-dialog v-model="errorShowing" v-if="error" :title="error.title" :text="error.text" />
          <success-dialog v-model="successShowing" v-if="success" :title="success.title" :text="success.text" />
          <cookie-dialog v-if="shouldDefaultComponents" />
        </v-main>
      </v-layout>
    </v-card>
  </v-app>
</template>

<script setup>
import LocaleSwitcher from '@/components/LocaleSwitcher';
import { useStore } from 'vuex';
import BannerCarousel from '@/components/BannerCarousel';
import TeamPanelNavigator from '@/components/TeamPanelNavigator';
import ErrorDialog from '@/components/form/ErrorDialog';
import SuccessDialog from '@/components/form/SuccessDialog';
import { computed, onMounted, ref, watch } from 'vue';
import i18n from '@/i18n';
import { useRouter, useRoute } from 'vue-router';
import CookieDialog from '@/components/form/CookieDialog';
import { useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';

const store = useStore();
const router = useRouter();
const route = useRoute();

const isLoggedIn = computed(() => store.getters.isLoggedIn);
const playerName = computed(() => store.getters.playerName);
const playerUUID = computed(() => store.getters.playerUUID);
const isTeamMember = computed(() => store.getters.isTeamMember);
const error = computed(() => store.getters.error);
const success = computed(() => store.getters.success);
const locale = computed(() => store.getters.locale);

const errorShowing = computed({
  get () {
    return store.getters.errorShowing;
  },
  set (val) {
    store.commit('setErrorShowing', val);
  },
});

const successShowing = computed({
  get () {
    return store.getters.successShowing;
  },
  set (val) {
    store.commit('setSuccessShowing', val);
  },
});

const shouldDefaultComponents = computed(() => {
  return route.name !== 'CustomMainMenu';
});

const links = [
  {
    text: 'imprint.title',
    url: '/imprint',
  },
  {
    text: 'policy.title',
    url: '/policy',
  },
];

const drawer = ref(false);

const setTempPath = (tempPath) => store.commit('setTempPath', tempPath);
const logout = () => store.commit('logout');

const login = () => {
  if (route.name !== 'Auth') {
    setTempPath(route.path);
  }
  router.push('/auth/login');
};

const setLang = (locale) => {
  document.documentElement.setAttribute('lang', locale);
};

watch(locale, (newVal) => {
  setLang(newVal);
});

onMounted(() => {
  if (locale.value) {
    i18n.global.locale = locale.value;
  } else {
    setLang(i18n.global.locale);
  }
});

const hasUnreadChats = ref(false);
if (isLoggedIn.value) {
  const { onResult } = useQuery(gql`
        query hasUnreadChats {
          hasUnreadChats
        }
    `, {}, { pollInterval: 20000 });
  onResult((res) => {
    hasUnreadChats.value = res.data.hasUnreadChats;
  });
}

</script>

<style>
h1, h2, h3, h4, h5 {
  color: rgb(var(--v-theme-primary)) !important;
}

.color-primary {
  color: rgb(var(--v-theme-primary)) !important;
}

.color-secondary {
  color: rgb(var(--v-theme-secondary)) !important;
}

.sm-view {
  max-width: 800px !important;
  margin: auto;
}

.m-view {
  max-width: 1200px !important;
  margin: auto;
}

.xm-view {
  max-width: 1400px !important;
  margin: auto;
}

.xxm-view {
  max-width: 1700px !important;
  margin: auto;
}

.v-dialog .v-overlay__content {
  margin: 0 !important;
}

.lg-padding {
  padding-right: 15px;
  padding-left: 15px;
}

.subtitle {
  opacity: 1;
  -webkit-line-clamp: unset !important;
}

@media screen and (max-width: 800px) {
  .lg-padding {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>

<style scoped>
.brand {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}
.clickable {
  cursor: pointer;
}
.notification-blob {
  height: 15px;
  width: 15px;

  border-radius: 20px;

  position: absolute;
  top: 2px;
  left: 31px;
  transform: translateX(-50%);
}
</style>
