const Downloads = () => import('@/views/admin/Downloads');
const PlayerList = () => import('@/views/admin/PlayerList');
const Player = () => import('@/views/admin/Player');
const Permissions = () => import('@/views/admin/Permissions');
const CharacterList = () => import('@/views/admin/CharacterList');
const CharacterInfo = () => import('@/views/admin/CharacterInfo');
const CharacterRename = () => import('@/views/admin/CharacterRename');
const CharacterDelete = () => import('@/views/admin/CharacterDelete');
const TransferMoney = () => import('@/views/admin/TransferMoney');
const Migrate = () => import('@/views/admin/Migrate');
const Messages = () => import('@/views/admin/Messages');
const Unban = () => import('@/views/admin/Unban');

const CharacterJobList = () => import('@/views/admin/CharacterJobList');
const AdminChatsWork = () => import('@/views/admin/AdminChatsWork');
const LangSelector = () => import('@/views/admin/LangSelector');

const routes = [
  {
    path: 'downloads',
    name: 'AdminDownloads',
    component: Downloads,
  },
  {
    path: 'players',
    name: 'AdminPlayerList',
    component: PlayerList,
  },
  {
    path: 'player/:id',
    name: 'AdminPlayer',
    component: Player,
  },
  {
    path: 'unban/:lang/:id',
    name: 'UnbanPlayer',
    component: Unban,
  },
  {
    path: 'permissions',
    name: 'AdminPermissions',
    component: Permissions,
  },
  {
    path: 'characters',
    name: 'AdminCharacters',
    component: LangSelector,
    meta: {
      permission: 'acp.character.rp.XX.list',
    },
    children: [
      {
        path: ':lang',
        name: 'AdminCharactersLang',
        component: CharacterList,
      },
    ],
  },
  {
    path: 'messages/',
    name: 'AdminMessages',
    component: LangSelector,
    meta: {
      permission: 'acp.messages.rp.XX.view',
    },
    children: [
      {
        path: ':lang',
        component: Messages,
      },
    ],
  },
  {
    path: 'characters/:lang/:id',
    name: 'AdminCharacterInfo',
    component: CharacterInfo,
  },
  {
    path: 'tools/charrename',
    name: 'AdminCharRename',
    component: LangSelector,
    meta: {
      permission: 'acp.tools.rp.XX.charrename',
    },
    children: [
      {
        path: ':lang',
        component: CharacterRename,
      },
    ],
  },
  {
    path: 'tools/transfermoney',
    name: 'AdminTransferMoney',
    component: LangSelector,
    meta: {
      permission: 'acp.tools.rp.XX.transfermoney',
    },
    children: [
      {
        path: ':lang',
        component: TransferMoney,
      },
    ],
  },
  {
    path: 'tools/chardelete',
    name: 'AdminCharDelete',
    component: LangSelector,
    meta: {
      permission: 'acp.tools.rp.XX.chardelete',
    },
    children: [
      {
        path: ':lang',
        component: CharacterDelete,
      },
    ],
  },
  {
    path: 'tools/migrate',
    name: 'AdminMigrate',
    component: Migrate,
    meta: {
      permission: 'acp.player.migrate',
    },
  },
  {
    path: 'characterJobs',
    name: 'AdminCharacterJobList',
    component: LangSelector,
    meta: {
      permission: 'acp.jobs.rp.XX.view',
    },
    children: [
      {
        path: ':lang',
        component: CharacterJobList,
      },
    ],
  },
  {
    path: 'chats',
    name: 'AdminChats',
    component: AdminChatsWork,
  },
];

export default routes;
