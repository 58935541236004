const StatsNotFound = () => import('@/views/stats/StatsNotFound');
const StatsCommunity = () => import('@/views/stats/StatsCommunity');
const StatsGlobal = () => import('@/views/stats/StatsGlobal');
const StatsGungame = () => import('@/views/stats/StatsGungame');
const StatsTTT = () => import('@/views/stats/StatsTTT');

const routes = [
  {
    path: 'community',
    name: 'StatsCommunity',
    component: StatsCommunity,
  },
  {
    path: 'global/:time?',
    name: 'StatsGlobal',
    component: StatsGlobal,
  },
  {
    path: 'gungame/:time?',
    name: 'StatsGungame',
    component: StatsGungame,
  },
  {
    path: 'ttt/:time?',
    name: 'StatsTTT',
    component: StatsTTT,
  },
  {
    path: ':catchAll(.*)',
    name: 'StatsNotFound',
    component: StatsNotFound,
  },
];

export default routes;
