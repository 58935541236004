export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog"])},
  "list": [
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.04.2024"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateMC Mod updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DynamX Pack updated"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The star system has been revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New vehicles have been introduced"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a bit of luck, you can now get the Ford Transit at CargoLink"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The numbering system has been revised including new control center numbers"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The arms school is a company"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helicopters have been introduced for companies"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The law book has received legal changes since the last changelog"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further minor bugs fixed, which were reported on Discord"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tablet"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tablet has received many different changes since the last changelog"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The navigation menu now reloads correctly after a login"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logs load faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The tablet now includes appointment requests, which can be made by companies as well as player-created businesses at the town hall"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bug with the rights for the Sandhill PD used"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the bottom left corner there is a new menu, with many useful features"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The path of the current page can now be copied via the InfoMenu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied paths can now be inserted as links into one's own pages via a corresponding button"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The home page is now accessible by double-clicking the InfoMenu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pages can also be pinned to the home page via the InfoMenu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The flight book suggests pilots and take-off and landing sites can be edited"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some spelling errors have been corrected"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27.01.2024"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles can now take damage again and need to be repaired after an accident"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vehicle interaction menu now displays the reason for blocked fields"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All vehicles now have a functioning odometer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver sounds in go-karts or mobility scooters are no longer muffled"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected companies seem to have acquired helicopters"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moritz Suhre has arrived in Central-Bay and is planning a profitable business"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hospital and the fire station no longer share a dispatch reception"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farming with multiple people at a drug plant is no longer possible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items are now only taken from the ATM when the explosion is carried out"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following vehicles: Mini, Shelby500, and Amvulcan no longer have swapped turn signals"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regina Piccio has found a better spot in the employee kitchen"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The doors on the bus can now be opened for testing"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police vests now have a more attractive design."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medical professionals now have a wider selection of medications."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added work attire for the judiciary."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A taxi sweater is now available in the taxi shop."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The radio texture has been replaced with a new, more appealing one."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following clothing items have been added: Techfleeces, vests, polo shirts, and homeless clothing."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New beards, glasses, and hairstyles have been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following clothing items have been revised: Taxi, doctor's coat, and MD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New clothing options for the designer computer have been released"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immigration officers have received new tops"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fog settings have been adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the trash can, one can discover new finds"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional minor errors fixed, as reported on Discord"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The snow has melted again and the Christmas decorations have been removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed construction error with toilets in the shooting range"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire truck now fits in the fire station in Sandhill"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non-continuous road markings at the former parking lot have been fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various construction errors have been corrected"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.10.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various building errors have been corrected"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brass knuckles now have a display image in the weapon shop"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display image of the horror mask (button eyes) is now shown"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storage location now has a computer again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aspirin added to the medic shop"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation point for the hunting shop has been correctly placed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation point for the storage location in Central-Bay is now correctly placed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ATM at the go-kart track now has a navigation point"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The normal FBI jacket without a vest has been added to the FBI shop"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The dispatch during the kebab shop robbery now has a display name"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CrispyHero now offers chicken in the assortment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The rank sorting in the computer is now back to the old one"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The parking meter at the car dealership now has a name"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The paint garage in the mechanic shop can now be used again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further errors fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New maps: Among Us, Casino"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.10.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The employment office was rebuilt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A subway station was built"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The kebab store was remodeled"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The downtown car dealership was rebuilt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The burger joint has been revamped"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The ItalBao has received a new tap"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new port was built"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The custodian's yard was compressed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mechanic has been rebuilt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The boxing ring has been revamped"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Central Bay police roll-up door has moved one wall"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The locksmith has been rebuilt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The airport has been modernized"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The car dealership at the Mechanic now has a new space along with remodeling"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other minor changes to downtown buildings"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customs to Sandhill has been extended"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A ravine has been dug behind the customs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandhill and Hunting Island are now connected by land"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bridge between Hunting Island and Central Bank Island has collapsed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new go-kart track has been built on Sandhill"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hunting store has moved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New buildings have been erected on Hunting Island"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New fruit trees have been planted"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New stores have opened in the subway"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new kiosk has opened at the GoKart track"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CB-PD, FBI, SH-PD, MD, FD, Mechanics have new items in their stores"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The clothing store has expanded its product line"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The police department now has shooting vests"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police clothing has been revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some MD clothing has been revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The MD has a new assortment of medications"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The MD & FD have received new vehicles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mechanic's clothing has been revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driving school has a new vehicle"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are new fruits to harvest (peaches, etc)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some items have been replaced with nicer model objects"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed bugs around PD carts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post route fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed bug in dispatch sending"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.07.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Map: Subway"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minor Map Adjustments"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All player spawns have been repositioned"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All weapon spawns have been re-placed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handcuff bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUD error fixed after resurrection"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.07.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faster loading when selecting a character"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New towing system for police officers with basic information and in-game insight"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The laptop in the impound yard shows which cars have been towed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional bug fixes"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All UIs have been reprogrammed and can be better extended and customized in the future"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The garage menu now has a license plate search"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character creation and the hairdresser now have a 3D preview of the skin"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The arrow indicating the player's position in the map app now updates even when the app is open"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The game has a completely new main menu that displays the changelog and allows single-player mode"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The car dealership has a new GUI for vehicles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All vehicles in the car dealership have new preview images"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numerous new tattoos have been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rollator vehicle has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Scania oil tanker vehicle has been added to the modpack"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin generation performance has been fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faster server switching"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sliders in the GUIs now work perfectly"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CustomMainMenu was removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional bug fixes"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OneShot revolver is not available until 40 seconds after starting the game"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detective Pass has been lowered to 200 Coins"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coins are no longer deducted for passes that were not successfully redeemed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.06.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire Gate fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trash can added to East Cliff PD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital hole in wall fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing computer added to hospital"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Depository can no longer be opened from the wrong PD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Better performance handling for the server"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License locks can be distributed again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["31.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sound added for closing doors"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 new maps added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The old maps have been removed for now"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map bug fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fence replaced at customs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Column removed at ItalBao"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signs removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green house Central-Bay missing column added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing wall Kreuzgasse 4 added again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrance pharmacy office fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing properties saved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate images load much faster now"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can show the gun license again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing medical computer added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing parking machine added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medic lockers fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only public parking machines are now shown on the map"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation points fixed in the map app"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added navigation point for clothing store in Sandhill"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maps app has all categories to subdivide points again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PD's got police computers in customs station"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driver's licenses can be issued again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fix: Doors have sound again when opening and closing"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same changes as in roleplay"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Securitycraft mod has been removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effects after consuming drugs no longer break prematurely"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taser models have been replaced and you now get slowness & drops from a taser shot"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The default TileEntity render range has been cut in half"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TileEntity render range can now be adjusted under mod options"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice range can now be adjusted in milliseconds to render shorter times than 1 second"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texture bug with blue shoes fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added various new items for the RP"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grey storm mask bug with eyes fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warning when entering the game if Optifine is not installed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The map has been updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hospital was completely rebuilt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxi company was completely rebuilt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kiosk & barber shop complex has been removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet shop has been removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New kiosk, growshop, pet store and tattoo parlor were built"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pharmacy has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bushes and water lilies are now permeable on the whole map"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All images on the map have been replaced with less computationally intensive ones"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New island (Sandhill) has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various map parts were built more performant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many single LittleTiles constructs were replaced by DynamX blocks as they are more performant"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carpentry workshop was rebuilt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various other buildings adapted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas pumps can now be used at the upper block"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When entering, you can now choose whether you want to land at Sandhill or Central-Bay"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gates now open and close slowly"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New store: Pharmacy"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new drug has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All the old tasers have been replaced with the new ones automatically"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After losing consciousness, you spawn in the nearest hospital"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The control center has been separated from the Sandhill PD and depending on what area you are in, the closer control center is called"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If one PD control center is not staffed, the other is automatically called"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandhill & CentralBay police now have separate numbers"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New farmable crops have been added: Rye, Rhubarb, Chili, Tomato"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you request an axe and don't have room you will no longer get into cooldown"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will no longer show yourself in the key management"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Addresses with United-Islands declaration fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed bug that you can't pass keys when someone is nearby in Spectator"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tied up you can't open or use your phone anymore"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now see a message before filling up your tank if you are low on money"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug that anyone can sign into service has been fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatches are no longer displayed on the wrong character"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooldown for dispatches is now character and not player bound"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License locks in the police computer can be deleted again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is now always a message & error sound if a number does not exist when calling"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When calling dead people you now also get the information that this number does not exist"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension payments are no longer displayed as a property statement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug fixed: Bus cannot be refueled (minus amounts)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tattoo's can now be stung by more characters"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a car is parked at 3pm the correct fuel level and lock state is now always stored"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM's can now only be used up close"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM's have been given gas detection"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To blow up an ATM now 2 stars must be active"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propane gas is removed directly when blasting an ATM"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propane gas can now be obtained in other ways in the RP"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added many new clothes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Item translations fixed: Rose, Angel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map in mobile app has been updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment office now shows all jobs and how to get them"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate menu got a picture for each of the apartments"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now drown again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New drowning animation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same changes as in roleplay"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack has been updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DynamX has been updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brake and reverse lights are fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License plates are fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles should no longer bug through the ground"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various bugs are fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.05.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack has been updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Female skins have fixed skin on the shoulders flaws"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no more random skins when creating a character"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The selected hair color is now retained in the character position"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New hairstyles have been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many new beards have been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 100 tattoos have been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now choose make-up in the character creation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The facial extras such as eg. Dark circles were separated from the eyes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added new facial extras such as. Scar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the hairdresser you always wear your clothes in the preview now"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed various bugs"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snackautomat has received a new assortment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Military can now open iron doors without a key card"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various bugs have been fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same changes as in Roleplay"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Website"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added a note on the connection with Mojang"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.04.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 New Challenges"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Over 10 new weapons"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazines are now always in 2nd place in the gun store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed various bugs"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.04.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police car skins improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New cars added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["item translations fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas pump translation fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various bugs have been fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various bugs have been fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.04.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack has been updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Memory Leak fix NPCs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police car Skins improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added new cacti for Sandhill"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed various bugs"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The error that the phone is not updated when creating a character has been fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added navigation point for logistics center"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier Job has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drug test in police shop"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alcohol meter police shop"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation revised (visual points)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postman job added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire Brigade has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are now more stages in drinking water than just full or empty"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All vehicles have been minimized by 30% acceleration"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Companies Computer Shop are now sorted into categories"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The clothing store has expanded its assortment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delicious new snacks have been added to the snack vending machine"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Same changes as for Roleplay"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.03.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack has been updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shelby500 passenger seat fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horn for Mini & Shelby fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possibly fixed the bug with the flying Mini"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specialforce clothing was equipped with a little protection"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Military got a clothing again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cigar texture has been fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cacti for Sandhill has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving school got a shirt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physics performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wine now also helps against thirst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You no longer lose stamina while driving"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cigarette pack bug has been fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple magazines and weapons can now be bought at once in the weapon store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passenger transport ticket has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you are resuscitated you no longer stand up but sit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital control center is shown as available in contacts again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving school shirt available in driving school store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.02.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack has been updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mute icon above the head now has a better resolution"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The mute icon is no longer displayed when sneaking"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DynamX has been updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The following vehicle models have been renewed: Mini, Aston Martin, Shelby 500 and the 6x6"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Water lilies and various bushes through which you can walk have been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed various bugs"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The police got a new uniform for every rank"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are cigars to buy at the kiosk now"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you're unconscious, you don't get calls anymore"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New RP Items added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cardigans have been added to the clothing store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed various bugs"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi Language Support has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No more players can join than the largest map allows"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The table list is now disabled in TTT"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now see the name over the head of each player within a radius of 5 blocks"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Killed Traitor will be displayed with the correct skin after the search"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze Gun fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spectator now sees all skins correctly"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed various bugs"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.02.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each kill is displayed in a message for everyone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stamina has been completely removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thirst and hunger has been removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The respawn time has been reduced to 3 seconds"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now regenerate life by eating"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every new player spawns in passive mode"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns from open cars can no longer break"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you become unconscious, the search will be aborted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is now possible for the mechanic to color his vehicle"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed various bugs"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.01.2023"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You respawn in a place near the place of death"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The item in your hand no longer falls to the ground when you die"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive mode is no longer activated after respawning"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The level system was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the entire map can now spawn random vehicles, which with luck are not locked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the unlocked vehicles you can find useful items"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hourly challenge starts now already from 8 players"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The stars are now dependent on how many players are online in active mode"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas stations can now be robbed and rivals can prevent the robbery"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed several bugs"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.12.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sale of fish is now possible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bug with collecting items when inventories are full has been fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HUD was completely revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas stations now display whether they have already been robbed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Large Weapons License was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hunting weapon is now available"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animals now spawn on the hunting island"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The drops from animals of the hunting island can be sold"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Winter jackets are available in the clothing store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The voice range is now displayed visually"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a heist has been aborted, it can be started again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The store robbery is now only started with a right click and can therefore not be triggered by mistake"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All foods can now be consumed regardless of whether you are hungry or not"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coat for doctor was added to the store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More realistic movements for NPC's"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC's now look players in the eye"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC's can now move"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During a robbery NPC's now put their hands up"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skinbug with NPC's fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC's no longer disappear"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now target NPC's with weapons without opening the stores"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatches have got a new more suitable sound"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other bugs were fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeroam"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Announcement of challenges are displayed 5 minutes before the challenge"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New level HUD was introduced to give a visually nicer overview"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New level menu where you can see when you unlock which things"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New HUD for challenges that visualizes the current challenge with a ranking list"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passive mode is now also displayed in the HUD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now play Freeroam without voice"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Features from the RP-Changelog were also implemented in Freeroam"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Global level is displayed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Browser"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconscious screen is displayed with crossfade"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F10 browser works better e.g. the search bar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added possibility to copy text"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can navigate between characters with the mouse"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different mouse pointer e.g. when hovering clickable elements"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed several bugs"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack is Linux compatible again"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added level bar for GunGame during games"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTT"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added level bar for TTT during games"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You now get less damage from other players"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.11.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various bug fixes from the last update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maps App has been released"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many new things are displayed on the map"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is now an emoji menu in mobile chats"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The map was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It got colder in United-Islands"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New real estate and many mouse extensions"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animals can no longer be given nametags (bug fix)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06.11.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The pages such as the vote page are now opened directly ingame"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driving school can now issue invoices"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unemployed and pensioners receive their unemployment or pension benefits again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driving school can now issue driving licenses"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The driver's licenses at the NPC became more expensive"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service station status is displayed in the contacts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multi-language support was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miscellaneous news revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When an invoice is paid or rejected, the animation ends automatically"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central bank garage added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a car is signed over, the new owner is removed as keys if he still had any"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATMs can no longer be used by two people at the same time"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles can now be sold at the scrap dealer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buyers and exchangers NPCs now have a new GUI"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The employee at the bank has now got a new nicer GUI"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many garments have been retextured and now look more qualitative"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strawberries and lettuce can now be sold in the supermarket"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finally you can buy white shoes in the store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The seats from the Shelby GT 500 were fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On company vehicles all bosses have keys and can give out keys"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.09.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lobby map was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each player now selects his language when entering for the first time"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double Jump was optimized"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VIP area is now better protected from players"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scissors, stone, paper removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stats linking NPC added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoreboard removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unverify Command works properly again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added multi-language support"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lobby now supports the languages: English, German, French & Italian. The system will be extended to GunGame, Roleplay etc. in the future."])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.09.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vehicle performance no longer deteriorates after a while"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People in vehicles can now be heard muffled"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Royal Ocean Casino was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sirens and horns can be heard many times more now"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed various vehicle bugs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed various weapons bugs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Driving school was added as a company"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prison item can be taken into custody - fixed bug"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many To Go drinks have been added in all stores"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safe deposit boxes can now be rented"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News vehicles are now parked in the mechanic yard and Police and Medic vehicles are parked right by them"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You no longer teleport one floor higher when you log out sitting down"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payday performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.08.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weapon mod is now compatible with Linux"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lie Sync improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hunger bar is now also updated when sitting down"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05.08.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The voice range now works properly again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas station robbery now also works with the new weapons"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speed while lying down was adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hairdresser preview works again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possibly fixed lie sync"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the car you can no longer lie down"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.08.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suit store assortment was expanded"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suit store spelling mistake fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The skin system was optimized"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weapon mod was replaced"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doors can be opened with weapon in hand"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New is the ammunition display in the HUD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazines now always stack properly"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is now possible to shoot into vehicles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the gun store now all items have a picture"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The police weapons were replaced"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lying is now sync even if someone joins later"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trunk inventories fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double messages in the upper left corner are stacked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupe-Bug Fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Becoming unconscious is now much more fluid"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking out vehicles in the towing yard only works with a police officer next to it"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sneaking you can no longer open your phone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interatkionsmenus are now also usable with cell phone in hand"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The questions in the quiz have been adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the quiz, 20 random questions are now asked at each player individually"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are not yet through the entry you now get the welcome message at every join"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You start at the bottom of the News app at the latest news item"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.07.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Key allocation for real estate works again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clothes are now rendered close fitting"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Long hair is now displayed over clothing"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medics have received surgical gowns"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clothing store assortment expanded (hoodies / headbands / uvm.) [Thanks to Virotex <3]"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eyeglass store at the pier has opened"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radar gun display error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linux support for our modpack"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed spelling error in invoice message (bonus)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling error during character creation fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.06.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New logging system"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the driver's license or firearms license is revoked, the inventory is updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UI can now be updated better without modpack update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance somewhat improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["06.06.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Life & hunger indicator display error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Character change now loads faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The city is now animated by some animals"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police now have the ability to distribute driver's license and firearm license suspensions"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police computer now has an inmate and license blocking overview"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The suit store has expanded its range"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupe bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The map was expanded with several new areas"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now use emojis in your cell phone. You can find a list"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One rule was adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.05.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate accounting revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you do not have money for a property, only this property will be canceled and not every property"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes were introduced for more than 2 properties"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adhesion time display error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detention time is displayed instant after detention"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added bank transporter for the event"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.05.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New goods in the gas stations"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various RP items were added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blowing up ATMs is now possible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our UI now runs externally - This gives us more flexibility to fix bugs and make adjustments"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM sound reduced"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtones in the cell phone are again correctly selectable"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch app has been moved to the home page and settings to the other page"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed unnecessary companies in the Dispatch app"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.05.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM does not pay out enough - error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stores do not give all items - bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SeaSide navigation point fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio channel is left during a char change"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center telephone can no longer be misused as a normal telephone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restart time was shortened (server is back faster after 15:00)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.04.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added sound when closing doors"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch function for NPC's removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20.04.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice, News & FBI got a control center"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice, News & FBI were added as standard contacts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Job Positions added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control center NPC GUIs have been replaced"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ringtones are now played when selected"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weapons can now be used with shaders"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.04.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The animation after showing the badge will end automatically"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added new backgrounds for mobile"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone ringtone is now adjustable"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An app to search for his vehicle was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM Menu was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AFK button in the character menu was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support notification revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garage in the church parking lot was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client performance somewhat improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.03.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The character should now load much faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic restarts at 15:00"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles are parked at 15:00 every day"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed bug with invisible unconscious people after joining"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientlaggs fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handy Bugs gefixt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added seats in cars"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCEF was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in cell phone was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed backspace bug in the UI"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch App was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconscious screen was replaced and improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaction menu was replaced"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed spelling mistake in the search"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For free things in stores, the shopping cart is no longer displayed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Issue invoice revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prison entry revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The maximum detention units were set to 6"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatches now have a cooldown of 60 seconds"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation for showing licenses and issuing invoices"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message after entering would be added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Persimmon now also quenches thirst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New navigation points was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance somewhat improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pickaxe / axe NPC error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salaries were increased by 10%"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company vehicles are now billed from the business account when refueling with card payment"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Command removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parking machine by the church was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minecraft GUI replaced in the employment office"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling mistake in quiz corrected"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.02.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The disappearance of cars when you log out while driving is fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price of cocaine was adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The FBI now also counts among the 'stars'"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After a server restart, it is no longer necessary to reconnect to Teamspeak"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görgi has opened his supermarket in East-Cliff"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas station in East-Cliff can now be robbed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All supermarkets & the kiosk can now be robbed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calls are no longer simply terminated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation has been improved a bit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handcuff bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed license plate query error with police computer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.01.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cocaine respawns not bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Teamspeak bot commands now work much faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now moved to the in-game waiting room much faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance was somewhat improved"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16.01.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cooldown for the doctor in the hospital is now character-dependent and no longer dependent on the player"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drugs can now be farmed again with more than 11 cops"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle HUD bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standing next to the vehicle although you got in bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indicator for the news cart fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added horns for missing vehicles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trunk for div. vehicles fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of messages displayed in the cell phone has been minimized"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance was somewhat improved"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.01.2022"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation is no longer cancelled on payday"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The map was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many new properties for rent"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The garage system was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The car store was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All existing vehicles were removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many new car models were added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vehicles now have much more pleasant physics"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles are now simply steered with WASD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click bug in the menus fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile bugs were fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company computers were revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees can now be terminated via the computer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment added in reporter computer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cars can be painted at the mechanic"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The license plate number is now displayed on the radar gun"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The /vehicle command has been removed."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicles can now be conveniently unlocked and locked from the inside and outside with 'L"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jan was removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The HUD now shows if an animation is active or not"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The license plate structure was adapted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the HUD a new bar for vehicle information (like locked or cruise control activated) has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio and cell phone can now be used in the vehicle without problems"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio small cool down added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New items for roleplay were added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Window title and icon were adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the hands on top, the item in the hand can no longer be changed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The digital scale now has its own GUI"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The drug buyer was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The highway to the new island was finished"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strawberries, lettuce and corn can now be picked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A new drug was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customs office was built"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plugin list ingame by command was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prison stay made more exciting"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In prison there is now a gardener job"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You now have to work for the food in prison"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cell phone has got an e-banking app"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cell phone has got a real estate app in which you can see which apartments are free"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improved cell phone performance when opening"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animations are no longer played on unassigned keys"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The radio status is now displayed in the lower right corner of the HUD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vehicle HUD is now only displayed as the driver"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any vehicle can now take damage and must be repaired after a crash"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a prisoner you can now see in the HUD how long you still have to sit"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If a vehicle is locked and you try to open it, the people next to it will hear it."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The news notification gets only those who also have a cell phone with them"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magazine in the police store was replaced"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New parking and unparking points were added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The licenses like ID cards, job licenses or driver's licenses are now nicely displayed in the HUD"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The author of a news item is now displayed in the message"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A suit store with new suits was opened"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hairdressing salon was opened"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The NPC's are no longer displayed in the table list"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC system improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages adapted for the speech radius"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fishing system was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Texturepack Inventar improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation problem with lying fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Liegen Animaton can now also be canceled with the reset hotkey"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The kiosk was opened"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now buy and smoke cigarettes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now use dots and other special characters in the cell phone without any problems"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The insertion in the cell phone was improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After waking up in the hospital you now have an effect"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consuming weed also gives you an effect"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The video camera now has an animation and an effect"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When sitting, the item in the hand no longer flies in the air"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added animation for the spark and the phone call"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The phone has got a new design"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now no longer lie down or perform the sit animation while sitting on a chair"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you try to sit on a chair while holding a radio in your hand, this is blocked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now no longer press any buttons while sitting in the car"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitting down on a chair is blocked should you already sit on a chair"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An animation menu with 'V' was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Several new animations have been integrated into the menu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are now several radio channels for certain factions to better organize themselves"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A common radio channel for fractions has been added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The catalog of fines was adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The privacy policy menu has been improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the Gadgets menu the currency has been changed from '€' to 'Coins"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When executing link-related commands, the website is automatically opened in the default browser"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scissors Rock Paper is now perfectly playable"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The map was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can no longer lie down now"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 20 minutes the map is changed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The map size now adapts to the number of players"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With a high killstreak you now get a small reward"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Lobby and GunGame you can now change the server with F12"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.09.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the water the endurance was adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You drown now if you have too little endurance in the water"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Those over 65 now receive their earned pension instead of unemployment benefits"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle dupe bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.09.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weed flowers can also be harvested from below"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now de-verify yourself via the lobby with /unlink"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server version updated (Possibly small performance improvements)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01.09.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank NPC transaction bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bus driver in cab company added as job"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The missing doors in Neubaustr. were added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto Dupe bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respawn point in the hospital was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed handcuffs and zip ties bug when respawning"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are new properties for rent"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital was rebuilt"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The door system for companies was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martin Geiss and the Medic reception stands in a new place"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital parking out point and garage moved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital laptop implemented"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cars that are too fully charged can now be parked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank reception NPC implemented"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone messages and calls are not displayed bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police computer made functional in control center room"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prison bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added a stamina bar (prevents jump-jumping)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News app on cell phone is now available"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News Command removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weed police message moved to the top left"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weed harvesting is now more lucrative after police officers on duty"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teamspeak Plugin"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version 1.0.22 was released"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The crash after or during roleplay should be fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The voice effects such as (telephone, radio, wall) are neatly removed after the RP"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.08.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is now a search in the phone for contacts"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now you can see if contacts are available or not"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Own number can be copied with one click"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unknown numbers can be stored with one click"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone can no longer be opened with left click"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When receiving a message you now get a notification tone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now hear by a beep when you call someone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In case of failed calls, call ended, message could not be sent etc. an error sound is played"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts can now be marked as favorites by editing them"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A note can be added to a contact"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revive system revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconsciousness bug fixed (revive not possible, blindness effect permanent)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconscious people now have clothes and the right skin"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you become unconscious you drop your item from your hand"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now sit down anywhere with an animation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now see the stars (police on duty) also in the bottom left corner"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cross-character SMS fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You get the SMS notification only if you have a cell phone with you"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dispatch system revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The robbing now takes longer or shorter depending on the location"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bank can now be robbed from 5 stars"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["25.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The big cell phone update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New rules regarding the cell phone"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC Bug fix"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC Bug fixes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientside performance improvement"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC Bug fixes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Names of NPC`s are now consistently displayed as 'ID: 0'."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas station wrong car detected error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/log Command improved (shorter cooldown, more performant and clearer design)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medic case news moved up"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NPC system completely revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each NPC now has a generated skin as well as clothing"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now rob stores with brass knuckles, knives and the new baseball bat"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quiz bypass error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ice cream parlor on the beach has opened"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pocket error message removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After verifying Teamspeak does not need to be reconnected"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wallet dupe-bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooldown removed when opening bags"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bags of dupe bugs put a stop to (Stop finding new ones Jan!)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Small cooldown when opening bags and backpacks"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beer now fills the thirst bar"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medic case and repair box changed to 3D Models"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["knight stick durability fix"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added limit of items on the floor"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cooldown when cuffing and uncuffing"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No place for handcuffs message customized"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medic case news customized"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many new clothes in the clothing store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The gun store now sells brass knuckles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The cookies in the bakery can now be purchased"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now buy beer in the supermarket"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATMs can now only be used by one person at a time"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to stand close enough to an ATM to use it"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trees can now be cut down again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechanic control center can be called again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["10.07.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car towing cool down added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance for the sneak menu extended"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The burger store can now be robbed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For control stations, the status is now also displayed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The scooters were made faster (parking and unparking)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed sneak menu bug"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unconscious error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banned players are no longer moved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now see his ban in the lobby already"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cars were parked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tree cutting temporarily disabled"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further performance improvements"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/playtime Command was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/support Command was added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GunGame"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weapons are loaded into the inventory"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weapons are loaded in the correct firing mode"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every 20 minutes the map is changed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The weapon selection was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More bugs fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Playing time is recorded"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FFA was removed from the network"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy statement query inserted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lobby system revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gadgets can be bought with coins"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now vote for our server every day to support the server and get a reward"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The parked cars in the garage charge many times faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further performance improvements"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animations can now be used on each subserver as well as lobby"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the wood processor, the correct price is now displayed in the sale"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cutting down a tree now takes 20 instead of 30 seconds"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employees in the computer list are now only shown on duty when they are online"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechanic shirt in official computer removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee list and transactions in the computer load significantly faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM transactions are displayed faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Further performance improvements"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official can now send news messages"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When processing wood, (4 -> 2) is now used instead of (5 -> 2)."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation is reset when changing chars"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin is set after respawning in the hospital"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Put backpack in bag bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medic Error messages moved to the top"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed salary message for farm jobs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Official can now send news messages"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guns from the gun store can no longer be broken"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As a Spectator you can now see the right skins"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After respawning you now have the right skin"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handling messages placed to the top left"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin migration as woman underwear not visible bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spacer item to inventory bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items from the interaction menu bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio system revised & radio for airport inserted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cable tie not displayed bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoices work again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News now get state aid"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice and news can now issue invoices"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice and news transactions are now displayed correctly"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Judiciary can now hire people"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radios for mechanics & cab drivers inserted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employment contract termination messages added"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.06.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cars were parked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The map was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The RP death rule was changed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are new properties that can be rented"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Possible fix for the bug that you spawn one floor too high built in"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double payday & real estate settlement fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Removed sound when searching trash cans"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items in the search can now no longer be hidden"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nothing can be placed in the crafting field now"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only tie people up and unleash them when you have the handcuffs or cable ties in your hand"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New weapons have been added to the weapon store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The police now have batons"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The assortment of the clothing store was enlarged"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The electrical store now offers more goods"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Teamspeak the player IDs are now in the user description"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed some bugs regarding the displays at the top left"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billy Cooper re-entered the country (drug dealer)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The lumberjack job is now better paid"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New character system"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now change your character with the command '/character' or via F12 without leaving the server"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skin system revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Characters should now load faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each new character must now pass immigration at United Islands International Airport"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New characters now automatically have an identity card"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The last game day of a character is now saved and displayed in the character selection"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added some new RP items like crutches, syringes and many more"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling mistake corrected"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debug messages removed when clicking on cabinets"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed a bug that allowed placing items in the locked area of the inventory"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locksmith account review fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.05.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The voice plugin was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["During a phone call the interlocutor now has an effect"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People who are behind a wall can now be heard muffled"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With radio, there is now also an audio effect so that the other person sounds more like they are really talking through a radio"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bug that people are not muted should be fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The map was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many new properties for rent"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cars were parked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police parking point moved outside"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police computer was moved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added sounds and action bar to farm routes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The trash cans can now be searched with Shift + right click"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deposit bottles can now be handed in at the supermarket"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lumberjack job and license were inserted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licensing system revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash inventory blocked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calls and radio turned up louder again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The judiciary can now hire employees"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12.04.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["StateMC texture pack added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorcycles were added to the modpack"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dynamic Surroundings Mod added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A few small bugfixes in the Roleplay Mod"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass on price for the car papers fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bags and backpack system revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice and the courthouse is now officially registered"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News center and the judiciary can now send news"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mechanics now have the opportunity to acquire a service pickup truck"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The storage places in the houses are now closed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have keys to a house or a door is unlocked, you can access all chests"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.04.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big Map Update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many new properties for rent"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The police chief can now hire and fire people"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A dupe bug regarding backpacks was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The key service was put into operation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For gas station robberies must now be 2 stars in the tab again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas station at the new service area put into operation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Targets at the shooting range are back"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02.04.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Command removed: /w"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backpack dupe bugs fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugged backpacks and bags can now be removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One now becomes thirsty again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salary error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle mod: fixed a bug where drivers of vehicles were invisible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle mod: fixed a bug that occupants were displayed on a wrong seat"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle mod: occupants of vehicles now no longer get stuck in the middle of the road"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle mod: After getting out of a vehicle you should now no longer be invisible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The state salaries were adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed a bug with the car loading"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The license plates of state vehicles were increased to 3 digits"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The logo in the server list was added again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["22.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some doors on the mechanic's yard were added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doors inserted on the military site"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cars have been parked and are now in the city garage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some new properties were added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police camp added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iron buyer (construction site) was moved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have the red screen frame, you can now still see the progress bars at the top"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are now some player animations that you can perform"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you are tied up, you now also visually have your hands behind your back"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messages related to tethering are now also displayed in the top left corner"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling error in tab corrected"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lockers/lockers inserted at mechanic's store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When waking up in the hospital, one is now reminded again of the New Life Rule"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mushrooms can now be collected and sold in the supermarket"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labor office license error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is now a questionnaire that everyone must complete again in order to play on the server"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now roll and consume joints"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After the hospital you are now also in the adventure mode"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After resuscitation or waking up in the hospital, you now no longer have full life and still have to be treated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the prison, all doors are now stored. This means that the police can now also lock people directly into the cell"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The table list now shows whether you are allowed to rob people or take hostages"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now listed in the tab with the player ID to find failers faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some bugfixes concerning Little Tiles"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The red screen border when you have taken a lot of damage should now no longer cause laggs and use less CPU power"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The transactions of the stores are now listed correctly"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["05.03.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Various loading bars fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iron processing now takes 3 seconds"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cut cannabis flower now takes 2 seconds"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tires can no longer be slashed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stars in the table list have new requirements"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now always in adventure gamemode to fix a few bugs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.02.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The team is now notified on TeamSpeak when someone is waiting in support"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The navigation system was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A test message when transferring money was removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions in business computers are now displayed correctly"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now make your character no younger than 18"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New real estate menu"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is now possible to pass on keys for properties"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each property is now assigned a street and house number"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The menus can only be closed with ESC and the X in the upper right corner, not with E anymore."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now share cell phone contacts only 1x"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You now hear a heartbeat when you have little life"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In addition, you have a red screen frame when you have little life"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Progressbar has got a new design"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The main island was christened <<Central Bay>>."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GUIs can only no longer be closed with E"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord Rich Presence was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUD design revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Real estate is now settled again"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cube calculation was optimized"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now fill cups and bottles with water at sinks"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed the bug with the wallets"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord RPC Update (Roleplay, build server, etc..)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People are no longer invisible"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When entering the server you are now always on normal language range"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATM duplication bug was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The storage system was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bug fix when farming iron and weed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client side lags minimized"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Missing houses in the ghetto can now be rented"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News from factions are now displayed longer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Store GUIs were revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The stores now have a much larger selection to offer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is now shown in the HUD if you are muted on TeamSpeak"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The display error of the voice radius at the second time join was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vehicle messages are now also displayed in the upper left corner"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you go to jail all items are now removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People can now be put in jail in a larger radius"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you pass out in prison, you wake up there too"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When tased, the slowness effect remains now"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tied up you can now no longer: drop items, pick up items, change slot and move items"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are now wallets in the clothing store in which money can be stored"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now buy working cubes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is now a control center telephone for prisoners"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Items can now only be picked up sneakily"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The radio loudness has been reduced"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TeamSpeak-Plugin"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice plugin was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People are automatically discouraged on TeamSpeak when coming from the ingame channel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People's volume is now automatically reset on TeamSpeak"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complications in the voice plugin were fixed"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Discord Bot has new Like/Dislike emojis"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is now a status channel for ideas"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can see how many likes and dislikes the idea got"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changelog Command has received an update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BauServer"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is a new build server system"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business robbery is no longer possible as a police officer"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police door added in the back"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling mistake corrected"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server performance improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["04.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are now no longer thirsty when you enter a new country"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now pass on and take away keys for cars again without any problems"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server information is now also displayed in the upper left corner"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faction news is now also displayed in the upper left corner"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03.01.2021"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New player HUD was introduced"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas stations GUI was inserted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GUI's now open much faster"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added radar gun"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Car speeds were adjusted"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can no longer get out of moving cars"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can no longer get into moving cars"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tires can no longer be smashed by hand"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police cars can now be locked so that you can no longer get out of them"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cars now automatically slow down when no driver accelerates anymore"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cruise control was introduced with the [*] key on the numpad"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is now no longer possible to zoom out infinitely while sitting in the car"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sirens are now heard by all (bug fix)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic flashing has been removed now works with the Numpad left and right arrows"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billy Cooper has made a run for it"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rashid bought an old bus"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most messages are now displayed in the upper left corner"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The thirst system was completely revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The hearts, hunger bar and level bar have been removed (you are not in creative mode!)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default vehicle HUD was removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cars were parked in the city garage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphics mode was set default back to nice"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On the highway is now allowed to drive 100 KM/H"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When you change the voice mode for the first time you are now directly on scream"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map errors were fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["28.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vehicle menu bug was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["27.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tool bench can no longer be used"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Each street now has its own name"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The houses now all have house numbers"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street signs can not be changed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ideas can be written directly in the Ideas channel. The same applies to errors."])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snow was removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle interatkion menu was revised"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cars were parked in the city garage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord now displays when you are roleplaying"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack had an update"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map errors were fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The iron sale bug was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 63 stack bug was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone Available 2. char error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The call is also canceled when the phone is not in hand and you call"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search dupe bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pull ID card from the inventory was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone contacts are now sorted by status"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kebab store in United-Islands is opened"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drinking now quenches thirst much more"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now hear whether a car is unlocked or locked"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only half of the life can be subtracted by thirst"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The price of steel has risen dramatically (150 euros for 64 bars)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the employment office is now 'processing fee' because there was misunderstanding"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gas station and donut store robberies now give more money"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The gas station outside the city can now also be robbed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doors can now no longer be opened through a bug"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The bells have a new sound"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling error fixed (pick up snowball)"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Christmas sweater is now available in the store"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glass panel in clothing store can no longer be removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police evidence room can now only be opened by police chiefs"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modpack was updated to bring new items and sounds into the game"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.12.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handcuffs multi-char bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Police officers can no longer harvest or sell weed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        },
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discord"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many new Discord bot commands"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Discord bot will now write you privately when your bug is fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Crash bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map error fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["26.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bugfixes"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In gas stations can now buy wheels and repair kits"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal players can use wheels and repair kits"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smartphone GUI bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All cars standing around were parked in the city garage"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It must have snowed in United-Islands"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can pick up snow from the ground"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It may now snow between"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The prison was now put into operation"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["House in the ghetto was released for rent"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search bug fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You get when selling weed the 250 euros"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interrogation room doors added"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door in the rich quarter was fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The flying building on the sea was removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance was improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["13.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment declaration for purchased weapons has been removed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Badge can no longer be removed from the inventory"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hospital doors were fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance was improved"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You get 1-3 flowers from one cannabis plant now"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkstop Sound-Fix"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apple price was corrected from down"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["09.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cell phone and radio can no longer be used when tied up"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now hear when others start / stop sparking"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When the radio is put away, you are automatically no longer in the radio channel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatically enter a radio channel"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["08.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice-Bug Fixed 2.0"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a stack of apples you now get 20 euros instead of 10 euros"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a stack of steel you now get 60 euros instead of 20 euros"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apartment door price declaration"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    },
    {
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["07.11.2020"])},
      "serverChangelogs": [
        {
          "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roleplay"])},
          "changelogs": [
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice-Bug Fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spelling error in the store fixed"])},
            (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed more system errors"])}
          ]
        }
      ]
    }
  ]
}