import { createApp } from 'vue';
import App from './App.vue';
import i18n from '@/i18n';
import router from '@/router';
import vuetify from '@/vuetify';
import store from '@/store';
import Utils from '@/modules/utils';
import { ApolloClient, createHttpLink, InMemoryCache, split } from '@apollo/client/core';
import { provideApolloClient } from '@vue/apollo-composable';
import VueApolloComponents from '@vue/apollo-components';
import Vue3Autocounter from 'vue3-autocounter';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';

function getHeaders () {
  const headers = {};
  const token = store.getters.token;
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  headers['Content-Type'] = 'application/json';
  return headers;
}

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_BACKEND_PROTOCOL + '://' + process.env.VUE_APP_BACKEND_HOST + '/graphql',
  fetch: (uri, options) => {
    options.headers = getHeaders();
    return fetch(uri, options);
  },
});

const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_BACKEND_WS_PROTOCOL + '://' + process.env.VUE_APP_BACKEND_HOST + '/graphql',
  fetch: (uri, options) => {
    options.headers = getHeaders();
    return fetch(uri, options);
  },
  options: {
    reconnect: true,
  },
});

const errorLink = onError(error => {
  if (error.graphQLErrors) {
    error.graphQLErrors.forEach(err => {
      if (err.message === 'UNAUTHORIZED') {
        if (store.getters.isLoggedIn) {
          store.commit('logout');
        }
      } else if (process.env.NODE_ENV !== 'production') {
        console.error(err);
      }
    });
  } else if (process.env.NODE_ENV !== 'production') {
    console.log(error);
  }
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: errorLink.concat(link),
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
  },
});

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(vuetify)
  .use(store)
  .use(Utils)
  .use(VueApolloComponents)
  .component('vue3-autocounter', Vue3Autocounter);

provideApolloClient(apolloClient);

app.config.performance = true;

app.mount('#app');
