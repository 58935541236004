export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiki"])},
  "pages": [
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affectation des touches"])},
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keyboard"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-keyboard"])}
    },
    {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
      "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commands"])},
      "icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mdi-slash-forward"])}
    }
  ],
  "keys": {
    "mouse-right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clic droit"])}
  },
  "keyboard": {
    "shortcuts": [
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shift"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mouse-right"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interaction avec les joueurs, les véhicules et les portes"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F12"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de personnage"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrage du moteur"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouverture et fermeture à distance du véhicule"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recharger l'arme"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Y"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer le chargeur de l'arme"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mode de tir des armes"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'allonger"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NUMPAD 1-9"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animations telles que l'agitation, les mains tendues et les mains levées"])}
      },
      {
        "keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roue d'animation ouverte"])}
      },
      {
        "Keys": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X"])}
        ],
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régler le volume de la voix"])}
      }
    ]
  },
  "commands": {
    "/l": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour au lobby"])},
    "/unverify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déverifier votre identité Teamspeak"])},
    "/log": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un journal dans le RP pour signaler les autres joueurs"])},
    "/account-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reliez votre compte de site web à votre compte Minecraft"])},
    "/discord-verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liez votre compte Discord à votre compte Minecraft."])},
    "/discord-unlink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion de votre compte Discord"])},
    "/id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous montre l'ID de votre propre joueur"])},
    "/playtime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vérifier votre temps de jeu"])},
    "/vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec cela, vous pouvez voter pour notre serveur et obtenir une récompense."])}
  }
}