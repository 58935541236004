const Roleplay = () => import('@/views/games/Roleplay');
const TTT = () => import('@/views/games/TTT');
const Gungame = () => import('@/views/games/Gungame');

const routes = [
  {
    path: 'roleplay',
    name: 'Roleplay',
    component: Roleplay,
  },
  {
    path: 'ttt',
    name: 'TTT',
    component: TTT,
  },
  {
    path: 'gungame',
    name: 'Gungame',
    component: Gungame,
  },
];

export default routes;
