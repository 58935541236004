const Login = () => import('@/views/auth/Login');
const SignUp = () => import('@/views/auth/SignUp');
const Confirm = () => import('@/views/auth/Confirm');
const ResetPassword = () => import('@/views/auth/ResetPassword');

const routes = [
  {
    path: 'login',
    name: 'AuthLogin',
    component: Login,
  },
  {
    path: 'register',
    name: 'AuthSignUp',
    component: SignUp,
  },
  {
    path: 'confirm',
    name: 'AuthConfirm',
    component: Confirm,
  },
  {
    path: 'reset-password',
    name: 'AuthResetPassword',
    component: ResetPassword,
  },
];

export default routes;
